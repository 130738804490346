import React, { useState, useEffect, Key } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Icon from "../../../Icon";
import DateAndTime from "../atoms/DateWithTime";
import LabelField from "../atoms/LabelField";
import Link from "../atoms/Link";
import IconWithTitle from "../molecules/IconWithTitle";
import Image from "../atoms/Image";
import { Nav } from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import {
  t,
  setLanguage,
  getLanguage,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../../services/ApiServices";
import {
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
  ABSOLUTE_CORE_APP,
} from "../../../Constants";
import {
  GET_APP_LANG,
  GET_COUNTRIES,
  GET_USER_COMPANIES,
  LOGOUT,
  PC_LIST,
  REGISTER_COUNTRY_LIST,
  REGISTER_SETTINGS_LIST,
  SETTINGS_LIST,
} from "../../../routes/ApiEndpoints";
import AddCompanyModal from "../../Dashboard/AddCompany";
import * as ROUTE from "../../../routes/RouteConstants";
import { useDispatch } from "react-redux";
import {
  addUserDetails,
  removeUserDetails,
  selectAuth,
} from "../../../features/auth/AuthSlice";
import { useSelector } from "react-redux";
import { setEmployeeData } from "../../../features/planning/employeeSlice";
import defaultCompanyIcon from "../../../static/images/emptyCompany.png";

type OptionType = { label: string; value: string };

interface HeaderProps {
  headerName?: string;
  headerClassName?: string;
}

interface Company {
  id: number;
  name: string;
  search_name: string;
  profile_picture_url: string;
}

const Header: React.FC<HeaderProps> = ({ headerName, headerClassName }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [formShow, setFormShow] = useState(false);
  const [pcValue, setPcValue] = useState<OptionType[] | null>(null);
  const navigator = useNavigate();
  const user = useSelector((state: any) => state?.auth);
  const [companyData, setCompanyData] = useState<{
    companies: any[];
    currentCompany: { id: number; name: string; profile_picture_url: string };
    countries: string[];
    iban: string[];
    pc: string[];
  }>({
    companies: [],
    currentCompany: { id: 0, name: "", profile_picture_url: "" },
    countries: [],
    iban: [],
    pc: [],
  });

  const handlePcChange = (selectedOption: OptionType[] | null) => {
    setPcValue(selectedOption);
  };
  const CompanyId = useSelector((state: any) => state.auth.currentCompany.id);
  // const employees = useSelector(selectCompanyEmployees);

  const fetchInfo = async () => {
    try {
      // if (employees.length === 0) {
      const res = await ApiCall.getService(
        `companyEmployees?id=${CompanyId}`,
        "GET"
      );
      if (res.status !== 200) {
        throw new Error("Network response was not ok");
      }
      if (res.status === 200) {
        dispatch(setEmployeeData(res.data)); // Dispatch the data to Redux
      } else {
        throw new Error(res.message);
      }
      // }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, [CompanyId]);

  const handleToggle = (isOpen: boolean) => {
    setCompanyData((prevState) => ({
      ...prevState,
      companies: user?.companies,
    }));
    setIsDropdownOpen(isOpen);
  };

  useEffect(() => {
    setCompanyData((prevState) => ({
      ...prevState,
      companies: user?.companies,
      currentCompany: user?.companies?.[0],
    }));
  }, [user?.companies]);

  const handleSelect = (company: any | null) => {
    if (company) {
      dispatch(
        addUserDetails({
          currentCompany: {
            id: company.id,
            name: company.name,
            profile_picture_url: company.profile_picture_url,
            locations: company.locations,
          },
        })
      );
      navigator(ROUTE.LOCATION_DASHBOARD);
    }
  };

  const fetchCountryOptions = async () => {
    let url;
    if (user.token) {
      url = GET_COUNTRIES;
    } else {
      url = REGISTER_COUNTRY_LIST;
    }
    try {
      if (companyData?.countries?.length === 0) {
        const result = await ApiCall?.getService(url, "GET");
        setCompanyData((prev) => ({
          ...prev,
          countries: result?.data || [],
        }));
      }
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  const fetchPcOptions = async () => {
    try {
      if (companyData?.pc?.length === 0) {
        const result = await ApiCall?.getService(PC_LIST, "GET");
        const pcData = result?.data?.map((pc: any) => ({
          value: pc?.value,
          label: pc?.label,
        }));
        setCompanyData((prev) => ({
          ...prev,
          pc: pcData,
        }));
      }
    } catch (error) {
      console.error("Error fetching PC data:", error);
    }
  };

  const fetchIbanCountries = async () => {
    try {
      let url;
      if (user.token) {
        url = SETTINGS_LIST;
      } else {
        url = REGISTER_SETTINGS_LIST;
      }
      if (companyData.iban.length === 0) {
        const result = await ApiCall?.getService(url, "GET");
        const ibanData = result?.data?.iban_countries?.map((iban: any) => ({
          value: iban?.value,
          label: iban?.label,
        }));
        setCompanyData((prev) => ({
          ...prev,
          iban: ibanData,
        }));
      }
    } catch (error) {
      console.error("Error fetching IBAN countries:", error);
    }
  };

  const selectedCompanyIndex = companyData?.companies?.findIndex(
    (company) => company?.name === user?.currentCompany?.name
  );

  // Check how many companies are present
  const totalCompanies = companyData?.companies?.length;
  let displayedCompanies;

  if (totalCompanies === 0) {
    displayedCompanies = [];
  } else if (totalCompanies === 1) {
    displayedCompanies = [companyData?.companies[0]];
  } else if (totalCompanies === 2) {
    displayedCompanies = [
      companyData?.companies[selectedCompanyIndex],
      companyData?.companies[(selectedCompanyIndex + 1) % 2],
    ];
  } else {
    displayedCompanies = companyData?.companies?.slice(
      selectedCompanyIndex,
      selectedCompanyIndex + 3
    );
    if (displayedCompanies.length < 3) {
      displayedCompanies = [
        ...displayedCompanies,
        ...companyData?.companies?.slice(0, 3 - displayedCompanies?.length),
      ];
    }
  }

  const handleItemClick = (itemName: string) => {
    if (itemName === "addCompany") {
      fetchCountryOptions();
      fetchIbanCountries();
      fetchPcOptions();
      setFormShow(true);
    } else {
      navigator("/location-dashboard");
    }
  };
  const handleCloseModal = () => setFormShow(false);
  const profileDropdownIconProps = {
    isIcon: true,
    isTitle: true,
    borderRadius: "50%",
    width: "2vw",
    height: "2vw",
    iconImageWrapperClassName: "text-center d-flex align-items-center",
    iconBackgroundColor: "#EEECF3",
    className: "profileDropdownMenuIcon",
    fillColor: "#8571b9",
    labelClassName: "colorPrimary companyName cursor-pointer",
    titleImageWrapperClassName: "text-center ms-2",
  };

  const [languages, setLanguages] = useState<
    Array<{ id: number; key: string; name: string }>
  >([]);
  const [activeLanguage, setActiveLanguage] = useState(getLanguage());
  // console.log("Dropdown debug","Header is called "+ activeLanguage + " language is set ")

  useEffect(() => {
    setActiveLanguage(getLanguage());
  }, [getLanguage()]);

  useEffect(() => {
    fetchLanguages();
  }, []);

  const fetchLanguages = async () => {
    const response = await ApiCall?.getService(
      `${GET_APP_LANG}/${ABSOLUTE_CORE_APP}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      setLanguages(response?.app_langs);
    }
  };

  const handleLogout = async () => {
    const response = await ApiCall?.getService(
      LOGOUT,
      "POST",
      "central-data-management"
    );
    dispatch(removeUserDetails());
    navigator("/login");
  };

  const handleLangChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLang = e?.target?.value;
    setLanguage(selectedLang);
    setActiveLanguage(selectedLang);
    secureLocalStorage.setItem("site_lang", selectedLang);
  };

  useEffect(() => {
    // Function to check and set body styles
    const toggleBodyStyles = () => {
      const hasProfileDropdownOpen =
        document.querySelector(".profileDropdownOpen") !== null;

      if (hasProfileDropdownOpen) {
        document.body.style.height = "100vh";
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.height = "100vh";
        document.body.style.overflow = "auto";
      }
    };

    // Initial check
    toggleBodyStyles();

    // Create a MutationObserver to observe changes in the body
    const observer = new MutationObserver(toggleBodyStyles);
    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup observer on unmount
    return () => {
      observer.disconnect();
      toggleBodyStyles(); // Reset styles when component unmounts
    };
  }, []);
  const navigate = useNavigate();
  return (
    <>
      {isDropdownOpen && <div className="overlayEffect" />}
      <div
        className={`pb-4 ${isDropdownOpen ? "profileDropdownOpen" : "headerWrapper"
          }`}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-end">
            <Link href="/">
              <Icon name="Logo" width="13vw" height="2vw" isIcon={true} />
            </Link>
            <span className="dateTimePosition">
              <DateAndTime
                dataTimeWrapperClassName="d-inline-block ms-2"
                dataTimeClassName="colorSkyBlue poppins-medium headerDateTime"
                isDate={true}
                isTime={true}
                isPipe={true}
                showYear={true}
                showMonth={true}
                showDate={true}
                isDay={true}
                dayClassName="me-2 colorSkyBlue poppins-medium headerDateTime"
              />
            </span>
          </div>
          <div className="pe-5 me-4 mt-2 pt-1">
            <LabelField
              title={headerName}
              className={`colorPrimary poppins-semibold pe-5 me-5 ${headerClassName}`}
            />
          </div>
          <div className="d-inline-flex align-items-center py-2">
            <Nav>
              <span className="cursor-pointer secondaryColorHoverEffect me-3">
                <Icon
                  name="reportIssue"
                  width="2vw"
                  height="2vw"
                  isIcon={true}
                  onClick={() => {
                    navigate('/report-issue');
                  }}
                />
              </span>
              <div className=" d-flex align-items-center mb-3 mb-lg-0 me-lg-3">
                <div className="d-block d-lg-none me-4">
                  {/* <Translate /> */}
                </div>
                <select
                  id="lang-select"
                  className="border rounded-3 cursor-pointer bg-transparent p-2 lang-options "
                  onChange={handleLangChange}
                  value={activeLanguage}
                >
                  {languages &&
                    languages.map((language) => (
                      <option key={language?.id} value={language?.key}>
                        {language?.key}
                      </option>
                    ))}
                </select>
              </div>
              {/* <div className="pe-0 position-relative mb-3 mb-lg-0 me-lg-3">
            <div className="d-flex align-items-center notification-section h-100">
              <Notification />
              <div className="d-block d-lg-none ms-4 color-dark-pink">
                {t("Notification")}
              </div>
            </div>
          </div> */}
            </Nav>
            <LabelField
              title={`Welcome ${user?.currentCompany?.name}`}
              className="colorPrimary poppins-semibold mt-2 pt-1"
            />
            <span className="pt-1">
              <Icon
                name="notification"
                className="mx-3"
                fillColor="#EDE681"
                width="1vw"
                height="1vw"
                isIcon={true}
              />
            </span>
            <Dropdown onToggle={handleToggle}>
              <Dropdown.Toggle
                className="bg-transparent border-0 p-0"
                id="dropdown-basic"
              >
                <div className="d-inline-flex imageBorder align-items-center justify-content-center">
                  <Image
                    src={
                      user?.currentCompany?.profile_picture_url ??
                      defaultCompanyIcon
                    }
                    imageHeight="2.5vw"
                    imageWidth="2.5vw"
                    imageBorderRadius="50%"
                  />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="profile-dropdown-links">
                <div className="contentBg p-0" typeof="button">
                  {displayedCompanies?.map((company: Company, index: Key) => (
                    <Dropdown.Item
                      key={index}
                      className={
                        user?.currentCompany?.name === company?.name
                          ? "active"
                          : ""
                      }
                      onClick={() => {
                        navigator(ROUTE?.LOCATION_DASHBOARD);
                        handleSelect(company);
                      }}
                    >
                      <div
                        className="d-grid align-items-center"
                        style={{ gridTemplateColumns: "auto auto" }}
                      >
                        <div className="d-flex imageBorder align-items-center justify-content-center">
                          <Image
                            src={
                              company?.profile_picture_url ?? defaultCompanyIcon
                            }
                            imageHeight="80.5%"
                            imageWidth="84%"
                            imageBorderRadius="50%"
                          />
                        </div>
                        <LabelField
                          title={company?.name}
                          className="ms-2 colorPrimary poppins-medium mb-0 cursor-pointer"
                          customStyle={{ whiteSpace: "break-spaces" }}
                        />
                      </div>
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Item
                    className={activeItem === "addCompany" ? "active" : ""}
                    onClick={() => handleItemClick("addCompany")}
                  >
                    <IconWithTitle
                      isIcon={true}
                      isTitle={true}
                      name="add"
                      borderRadius="50%"
                      width="2vw"
                      height="2vw"
                      iconImageWrapperClassName="text-center d-flex align-items-center"
                      iconBackgroundColor="#898989"
                      className="p-2"
                      fillColor="#fff"
                      labelClassName="colorPrimary companyName cursor-pointer"
                      title={t("Add company")}
                      titleImageWrapperClassName="text-center ms-2"
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={activeItem === "viewCompanies" ? "active" : ""}
                    onClick={() => navigator(ROUTE?.WELCOME_DSHBOARD)}
                    style={{
                      height: "2.5vw",
                      borderRadius: "1vw",
                      border: "0.052vw solid #fff",
                      padding: "0",
                    }}
                  >
                    <div className="d-flex align-items-center mx-3 viewCompaniesBg">
                      <IconWithTitle
                        isIcon={true}
                        isTitle={true}
                        name="arrowLeftRight"
                        width="2vw"
                        height="2vw"
                        iconImageWrapperClassName="text-center d-flex align-items-center"
                        className="p-2"
                        fillColor="#8571b9"
                        labelClassName="colorPrimary companyName cursor-pointer"
                        title={t("View all companies")}
                        titleImageWrapperClassName="text-center ms-2"
                      />
                    </div>
                  </Dropdown.Item>
                </div>
                <div className="contentBg p-0 mt-4">
                  <Dropdown.Item
                    className={activeItem === "myAccount" ? "active" : ""}
                    onClick={() => navigator(ROUTE?.MY_ACCOUNT)}
                  >
                    <IconWithTitle
                      name="user"
                      title={t("My account")}
                      {...profileDropdownIconProps}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={activeItem === "myDocuments" ? "active" : ""}
                    onClick={() => navigator(ROUTE?.DOCUMENTS_OVERVIEW)}
                  >
                    <IconWithTitle
                      name="documents"
                      title={t("My documents")}
                      {...profileDropdownIconProps}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={activeItem === "managePool" ? "active" : ""}
                    onClick={() => navigator(ROUTE?.EMPLOYEE_OVERVIEW)}
                  >
                    <IconWithTitle
                      name="poolIcon"
                      title={t("Managing your pool")}
                      {...profileDropdownIconProps}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={activeItem === "signOut" ? "active" : ""}
                    onClick={handleLogout}
                  >
                    <IconWithTitle
                      name="logoutIcon"
                      title={t("Logout")}
                      {...profileDropdownIconProps}
                    />
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <>
        <AddCompanyModal
          show={formShow}
          handleClose={handleCloseModal}
          pcValue={pcValue}
          handlePcChange={handlePcChange}
          countries={companyData?.countries}
          pc={companyData?.pc}
          iban={companyData?.iban}
        />
      </>
    </>
  );
};

export default translate(Header);
