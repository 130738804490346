import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ModalPopup from "../common/atoms/ModalPopup";
import Title from "../common/atoms/Title";
import EmpployeeShiftTable from "./EmpployeeShiftTable";
import SelectedEmployeesDetailsTable from "./SelectedEmployeesDetailsTable";
import SelectedEmployeesNameCard from "./SelectedEmployeesNameCard";
import { resetAllData, selectContractType, selectDates, selectEmployees, setTab } from "../../features/planning/planningSlice";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { validatePlanningFields } from "./Validations/PlanningValidations";
import { RootState } from '../../store'; // Adjust based on your store
import CustomNotify from "../common/atoms/CustomNotify";



const EmployeeScheduling = () => {
  const state = useSelector((state: RootState) => state);
  // State to control the visibility of the modal
  const [showModal, setShowModal] = useState(true);
  const monthView = useSelector(selectContractType);

  // State to track the index of the currently selected employee
  const [currentEmployeeIndex, setCurrentEmployeeIndex] = useState<number | null>(0); // Default to 0 if there's at least one employee
  // Get selected employees from Redux store
  const selectedEmployees = useSelector(selectEmployees);
  // State to store the currently active employee
  const [activeEmployee, setActiveEmployee] = useState(selectedEmployees[0]);
  const [error, setError] = useState("");

  // Get selected dates from Redux store
  const dispatch = useDispatch();

  // Handler to close the modal and reset all data
  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(resetAllData());
  };

  // Handler to move to the next employee
  const handleNextEmployee = () => {
    if (currentEmployeeIndex !== null && currentEmployeeIndex < selectedEmployees.length - 1) {
      setCurrentEmployeeIndex(currentEmployeeIndex + 1);
    }
  };

  // Handler to move to the previous employee
  const handlePreviousEmployee = () => {
    if (currentEmployeeIndex !== null && currentEmployeeIndex > 0) {
      setCurrentEmployeeIndex(currentEmployeeIndex - 1);
    }
  };

  // Effect to update the active employee based on the current index
  useEffect(() => {
    currentEmployeeIndex !== null ? setActiveEmployee(selectedEmployees[currentEmployeeIndex]) : setActiveEmployee(selectedEmployees[0]);
  }, [currentEmployeeIndex]);

  // Handler to move to the next tab
  const handleNext = async () => {

    const isValid = await validatePlanningFields(state, dispatch);
    if (!isValid) {
      setShowModal(false);
      dispatch(setTab("tab_3"));
    }
  };

  // Handler to go back to the previous tab
  const handleBackClick = () => {
    dispatch(setTab("tab_1"));
  };
  const getTitle = () => {
    let title = ""
    switch (monthView) {
      case "day":
        title = t("Employee scheduling daily overview")
        break;
      case "week":
        title = t("Employee scheduling weekly overview")
        break;
      case "month":
        title = t("Employee scheduling monthly overview")
        break;
      default:
        break;
    }
    return title;
  }
  return (
    <ModalPopup
      show={showModal}
      handleClose={handleCloseModal}
      modalTitle={getTitle()}
      modalBackgroundColor="#8571b9"
      className="planningModal"
      buttonText={t("Next")}
      showNextIcon={true}
      iconFillColor="#8571b9"
      showBackIcon={true}
      onNextClick={handleNext}
      handleBack={handleBackClick}
    >
      <div className="row">
        <div className="col-9">
          <Title
            title={t("Choose an employee(s) for whom you want to create a schedule") + ":"}
            className="bodyTitle colorPrimary"
          />
        </div>
      </div>

      <div className="row">
        <SelectedEmployeesNameCard
          currentEmployeeIndex={currentEmployeeIndex}
          onEmployeeSelect={(index: number) => setCurrentEmployeeIndex(index)}
        />
      </div>

      {/* Conditionally render the details table and shift table if there's an active employee */}
      {activeEmployee && (
        <>
          <SelectedEmployeesDetailsTable
            onEmployeeSelected={activeEmployee}
            onNextEmployee={handleNextEmployee}
            onPreviousEmployee={handlePreviousEmployee}
          />
          <EmpployeeShiftTable
            onEmployeeSelected={activeEmployee}
          />
        </>
      )}
    </ModalPopup>
  );
};

export default translate(EmployeeScheduling);
