import React, { ChangeEvent, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "../../common/atoms/Button";
import InputTextField from "../../common/atoms//InputTextField";
import { ApiCall } from "../../../services/ApiServices";
import { LabelWithInputField } from "../../common/molecules/LabelWithInputField";
import {
  validateRequired,
  validateTextFiledSpace,
  validateForm,
} from "../../../services/ValidationService";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomNotify from "../../common/atoms/CustomNotify";
import LoadingIcon from "../../common/utlis/LoadingIcon";
import { CLASS_BY_PERMISSION, ROLES } from "../../../routes/ApiEndpoints";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import Title from "../../common/atoms/Title";
import AccessControl from "../../../services/RolesAndPermissions/AccessControl";
import Header from "../../common/layout/Header";
import * as ROUTE from "../../../routes/RouteConstants";
import BackLink from "../../common/atoms/BackLink";
import "./css/roles.css";
import OverFlowWrapper from "../../common/OverFlowWrapper";
interface CheckboxProps {
  classification: string;
  userPermission: string;
  action: string;
  checked: boolean;
  onChange: (userPermission: string, action: string, checked: boolean) => void;
}

interface PermissionCred {
  id: number;
  crud_name: string;
  unique_key: string;
}

interface TabsProps {
  data: {
    permissionsWithClassifications: Record<
      string,
      { permissions: Record<number, string> }
    >;
    permissionCred: PermissionCred[];
  };
  activeTab?: string;
  onTabChange: (userPermission: string) => void;
}

interface RoleData {
  roleName: string;
  action: Record<string, Record<string, boolean>>;
  type?: string;
  roleId?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  classification,
  userPermission,
  action,
  checked,
  onChange,
}) => {
  const checkboxName = `${classification.toLowerCase()}-${action}`;

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    onChange(userPermission, action, checked);
  };

  return (
    <InputTextField
      className="form-check-input rounded-0 shadow-none"
      type="checkbox"
      name={checkboxName}
      checked={checked}
      handleChange={handleCheckboxChange}
    />
  );
};

interface Designation {
  index?: number | null;
  id?: number | null;
  name: string;
}

const Tabs: React.FC<TabsProps> = ({ data, activeTab, onTabChange }) => (
  <>
    <div className="d-none d-md-none d-lg-block">
      <ul className="nav nav-tabs border-0 create-role-tabs mt-3">
        {Object.keys(data.permissionsWithClassifications).map(
          (classification) => (
            <li className="nav-item me-4" key={classification}>
              <Button
                title={t(`${classification}`)}
                handleClick={() => onTabChange(classification)}
                className={`btn ${
                  activeTab === classification
                    ? "active border-0 px-0"
                    : "border-0 px-0"
                }`}
              />
            </li>
          )
        )}
      </ul>
    </div>
    <div className="d-block d-md-block d-lg-none role-dropdown">
      <div className="dropdown">
        <button
          className="btn dropdown-toggle rounded-0 shadow-none"
          type="button"
          id="classificationDropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {activeTab} {/* Display the currently selected classification */}
        </button>
        <div
          className="dropdown-menu rounded-0"
          aria-labelledby="classificationDropdown"
        >
          {Object.keys(data.permissionsWithClassifications).map(
            (classification) => (
              <button
                key={classification}
                className="dropdown-item"
                onClick={() => onTabChange(classification)}
              >
                {classification}
              </button>
            )
          )}
        </div>
      </div>
    </div>
  </>
);

const RolePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [designationError, setDesignationError] = useState<
    Record<string, string>
  >({});
  const [activeTab, setActiveTab] = useState<string | undefined>();
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState<RoleData>({
    roleName: "",
    action: {},
  });
  const [data, setData] = useState<{
    permissionsWithClassifications: Record<
      string,
      { permissions: Record<number, string> }
    >;
    permissionCred: PermissionCred[];
  } | null>(null);
  const navigate = useNavigate();
  const [designations, setDesignations] = useState<Designation[]>([]);
  const [currentDesignation, setCurrentDesignation] = useState<Designation>({
    name: "",
    id: null,
  });

  useEffect(() => {
    if (id) {
      const fetchRoleById = async () => {
        const response = await ApiCall.getService(`${ROLES}/${id}`, "GET");
        setMessage(response.msg);
        if (response.status === 200) {
          const { roleName, action } = response.data;
          setRoleData({ roleName, action });
        }
      };

      fetchRoleById();
    }
    fetchRolesAndPermission();
  }, [id]);

  const fetchRolesAndPermission = async () => {
    const response = await ApiCall.getService(CLASS_BY_PERMISSION, "GET");

    if (response.status === 200) {
      const permissionsWithClassifications =
        response.data.permissionsWithClassifications;
      const classificationKeys = Object.keys(permissionsWithClassifications);
      setData({
        permissionsWithClassifications,
        permissionCred: response.data.permissionCred,
      });
      setActiveTab(classificationKeys[0]);
    }
  };

  const handleCheckboxChange = (
    userPermission: string,
    action: string,
    checked: boolean
  ) => {
    setRoleData((prevRoleData) => {
      const updatedAction = {
        ...prevRoleData.action,
        [userPermission]: {
          ...(prevRoleData.action[userPermission] || {}),
        },
      };

      if (checked) {
        updatedAction[userPermission][action] = true;
      } else {
        delete updatedAction[userPermission][action];
        if (Object.keys(updatedAction[userPermission]).length === 0) {
          delete updatedAction[userPermission];
        }
      }

      const hasAction = Object.keys(updatedAction).length > 0;
      setErrors((prevErrors) => {
        if (hasAction) {
          const { action, ...remainingErrors } = prevErrors;
          return remainingErrors;
        }
        return prevErrors;
      });

      return {
        ...prevRoleData,
        action: updatedAction,
      };
    });
  };

  const validation = (
    name: string,
    value: string,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules = {
      roleName: [validateRequired, validateTextFiledSpace],
    };

    const validationErrors = validateForm(
      { roleName: value, ...roleData.action },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    let error = false;

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (!Object.keys(roleData.action).length) {
      error = true;
      setErrors(() => ({
        ...validationErrors,
        action: t("Please select atleast one action."),
      }));
    }

    return Object.keys(validationErrors).length === 0 && !error;
  };

  const handleTabChange = (userPermission: string | undefined) => {
    setActiveTab(userPermission);
  };

  const handleSubmit = async () => {
    // setLoading(true);
    if (
      validation("roleName", roleData.roleName, true) &&
      (Object.keys(errors).length === 0 || errors.roleName === undefined)
    ) {
      let url = ROLES;
      let method = "POST";
      if (id) {
        // roleData.type = "update";
        roleData.roleId = id;
        url = `${ROLES}/${id}`;
        method = "PUT";
      }
      const response = await ApiCall.service(url, method, {
        data: roleData,
      });
      if (response.status === 200) {
        navigate(ROUTE.MANAGE_ROLES);
        CustomNotify({ type: "success", message: t(response.message) });
      } else {
        CustomNotify({ type: "error", message: t(response.message) });
      }
    }
    setLoading(false);
  };

  const handleRoleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRoleData((prevRoleData) => ({
      ...prevRoleData,
      roleName: value,
    }));

    validation(name, value, true);
  };

  const buttonText: string = id ? t("Update role") : t("Create role");

  let permissionCrud = data?.permissionCred;
  if (activeTab === "Dashboard") {
    permissionCrud = permissionCrud?.filter(
      (crud: any) => crud.unique_key === "read"
    );
  }

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Roles",
  };
  permissionObject[permissionType] = true;
  return (
    // <AccessControl
    //   requiredPermissions={[permissionObject]}
    //   renderNoAccess={true}
    // >
    <>
      {data && (
        <>
          <Header
            headerName={t(`${id ? t("Update") : t("Create")} ${t("role")}`)}
            headerClassName="myAccountCardTitle"
          />
          <div className="search-bar">
            <div className="row">
              <BackLink backLink={ROUTE.MANAGE_ROLES} />
            </div>
            <div className="row">
              <LabelWithInputField
                name="roleName"
                type="text"
                value={roleData.roleName}
                handleChange={handleRoleNameChange}
                placeholder={t("Role name")}
                label={t("Role name")}
                error={errors.roleName}
              />
            </div>
            <Tabs
              data={data}
              activeTab={activeTab}
              onTabChange={handleTabChange}
            />
          </div>
          <div className="createRoleBg pt-0">
            <div className="row pt-3">
              <div className="col-md-12">
                <div className="tab-content create-roles">
                  {activeTab &&
                    data != null &&
                    data.permissionsWithClassifications[activeTab] && (
                      <div className={`tab-pane active pwa`}>
                        <OverFlowWrapper className="createRoleTable">
                          <table className="table table-hover mb-5 customTableProperties">
                            <thead>
                              <tr className="border-0 TableHeader">
                                <th
                                  className="border-0"
                                  style={{ width: "35%" }}
                                ></th>
                                {permissionCrud?.map((cred) => (
                                  <th
                                    key={cred.unique_key}
                                    className="border-0"
                                    style={{ width: "15%" }}
                                  >
                                    {t(`${cred.crud_name}`)}
                                  </th>
                                ))}
                              </tr>
                              <tr>
                                <th
                                  className="emptyRow p-0"
                                  style={{ height: "0.7vw" }}
                                  colSpan={10}
                                ></th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(
                                data.permissionsWithClassifications[activeTab]
                                  .permissions
                              ).map(([permissionId, permissionName]) => (
                                <tr key={permissionId} className="border">
                                  <td className="text-break px-2 pwa-permission ">
                                    {t(`${permissionName}`)}
                                  </td>
                                  {permissionCrud?.map((cred) => (
                                    <td
                                      key={cred.unique_key}
                                      data-label={cred.crud_name}
                                    >
                                      <Checkbox
                                        classification={activeTab}
                                        userPermission={permissionId}
                                        action={cred.unique_key}
                                        checked={
                                          roleData.action[permissionId]?.[
                                            cred.unique_key
                                          ] || false
                                        }
                                        onChange={handleCheckboxChange}
                                      />
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </OverFlowWrapper>
                      </div>
                    )}
                </div>
              </div>
            </div>
            {errors.action && (
              <div className="text-danger">{errors.action}</div>
            )}
          </div>
          <div className="row my-4">
            <div className="col-md-12 text-end">
              {!loading ? (
                <Button
                  title={buttonText}
                  className="searchBtn"
                  handleClick={handleSubmit}
                />
              ) : (
                <LoadingIcon
                  iconType="bars"
                  color="#ff4dae"
                  className="float-end"
                />
              )}
            </div>
          </div>
          {message && <div className="col-md-12">{message}</div>}
        </>
      )}
    </>
  );
};

export default translate(RolePage);
/* </AccessControl > */
