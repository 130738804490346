import React, { useEffect, useState } from "react";
import Header from "../../common/layout/Header";
import { t, translate } from "../translation/Translation";
import { InputText } from "primereact/inputtext";
import InputTextFieldWithLabel from "../../common/molecules/InputTextFieldWithLabel";
import FileUpload from "../../common/molecules/FileUpload";
import {
  ProjectFormData,
  fileData,
  formatHouseNumber,
  formatPostalCode,
  formatStreet,
  inputFields,
  intialFileData,
} from "./ProjectUtils";
import { ApiCall } from "../../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import {
  FILE_URL,
  GET_COUNTRIES,
  LOCATIONS,
  PROJECTS,
  SHIFTS,
} from "../../../routes/ApiEndpoints";
import SelectWithSearch from "../../common/atoms/SelectWithSearch";
import CustomNotify from "../../common/atoms/CustomNotify";
import MultiSelectField from "../../common/atoms/MultiSelectField";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import BackLink from "../../common/atoms/BackLink";
import Button from "../../common/atoms/Button";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../features/auth/AuthSlice";
import Image from "../../common/atoms/Image";
import LabelField from "../../common/atoms/LabelField";
import RadioField from "../../common/atoms/RadioField";

const EditProject: React.FC = () => {
  // const [locationName, setLocationName] = useState("");

  interface Country {
    iso: string;
    value: number;
    label: string;
  }

  interface CompanyObj {
    value: number;
    label: string;
    url: string;
  }
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const user = useSelector(selectAuth).userId;

  const initialFormData: ProjectFormData = {
    project_name: "",
    street: "",
    number: "",
    postal_code: "",
    box: "",
    city: "",
    country: "",
    location_id: 0,
    company_id: [],
  };
  const projectinputFields = inputFields;

  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [formData, setFormData] = useState<ProjectFormData>(initialFormData);
  const [selectedCompanies, setSelectedCompanies] = useState<any[]>([]);
  const [countries, setCountries] = useState([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [companies, setCompanies] = useState<CompanyObj>({
    value: 0,
    label: '',
    url: '',
  });
  // const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedLocation, setSelectedLocation] = useState<Location[]>([]);
  const [url, setUrl] = useState<string | null>(null);
  const [relatedCompanies, setRelatedCompanies] = useState<CompanyObj[]>([]);

  const companiesObj = [
    { value: 1, label: "Option 1" },
    { value: 2, label: "Option 2" },
    { value: 3, label: "Option 3" },
  ];

  interface Location {
    iso: string;
    value: number;
    label: string;
  }

  useEffect(() => {
    // First, load the countries and then fetch the location
    const loadData = async () => {
      await getCompanies();
      await getCountries();
    };
    loadData();
  }, []);

  useEffect(() => {
    getCountries();
    getLocations();
  }, []);

  const getCompanies = async () => {
    const response = await ApiCall.getService(
      `getCompaniesByUserId/${user}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    console.log(response);
    if (response.status == 200) {
      // Convert the data to match the CompanyObj interface
      const formattedCompanies = response.data.map((company: any) => ({
        value: company.id,
        label: company.name,
        // url: company.profile_picture_url,
      }));
      setRelatedCompanies(formattedCompanies);
    }
  };

  // Fetch countries
  const getCountries = async () => {
    try {
      const response = await ApiCall.getService(
        `${GET_COUNTRIES}`,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        const countryList = response.data;
        setCountries(countryList); // Set the countries

        // Once countries are set, fetch the location
        fetchProjectById(countryList);
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  // Fetch the location by ID
  const fetchProjectById = async (countryList: Country[]) => {
    try {
      const response = await ApiCall.getService(
        `${PROJECTS}/${id}`,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.statusCode === 200) {
        const projectData = response.data;

        setCompanies({
          value: projectData.company_id,
          label: projectData.company.official_name,
          url: "https://t3.ftcdn.net/jpg/02/55/86/12/360_F_255861239_viu6hRNcURzeKUoyPJBFOyTu1fA8aJtM.jpg",
        });

        setFormData({
          project_name: projectData.name || "",
          location_id: projectData.location_id || "",
          street: projectData.address?.street || "",
          number: projectData.address?.number || "",
          postal_code: projectData.address?.postal_code || "",
          box: projectData.address?.box || "",
          city: projectData.address?.city || "",
          country: projectData.address?.country?.id || "",
          company_id: projectData.company_id ? [projectData.company_id] : [],
        });




        // Pre-fill selected country and companies once data is loaded
        const selectedCountry = countryList.find(
          (country: Country) =>
            country.value === projectData.address?.country?.id
            // country.id === projectData.address?.country?.id
        );


        setSelectedCountry(selectedCountry || null); // Set the selected country
        setSelectedCompanies([
          { value: projectData.company_id, label: "Company" },
        ]);
      }
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  console.log(companies,"abcd");

  const getLocations = async () => {
    const response = await ApiCall.getService(
      `${LOCATIONS}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.statusCode == 200) {
      const data = response.data;
      // Transform the response data to match the Location interface
      const transformedLocations: Location[] = data.map((location: any) => ({
        value: location.id,
        label: location.name,
      }));
      setLocations(transformedLocations);
      if (transformedLocations.length > 0) {
        // Set the first location in state
        console.log(transformedLocations);
        setSelectedLocation([transformedLocations[0]]);
        setFormData((prevData) => ({
          ...prevData, // Keep the previous form data
          location_id: transformedLocations[0].value, // Update only the project_name key
        }));
      }
    }
  };

  // Handle input change for text fields
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any,
    fieldKey: keyof ProjectFormData
  ) => {
    let value: any = "";
    switch (fieldKey) {
      case "location_id":
        console.log(e);
        break;
      case "country":
        console.log(e);
        setSelectedCountry(e);
        // value = e.iso;
        value = e?.value || "";
        console.log(value);
        // console.log(value);
        break;
      case "postal_code":
        value = formatPostalCode(e.target.value);
        break;
      case "number":
        value = formatHouseNumber(e.target.value);
        break;
      case "street":
        value = formatStreet(e.target.value);
        break;
      case "company_id":
        value = [e.value];
        setCompanies(e);
        break;
      default:
        value = e.target.value;
        break;
    }

    setFormData((prevData) => ({
      ...prevData,
      [fieldKey]: value,
    }));

    // Clear the error for the updated field
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldKey]: value ? "" : prevErrors[fieldKey], // Remove the error if value is not empty
    }));
  };

  const validateForm = () => {
    let errors: Record<string, string> = {};
    // Loop through the form fields to check for validation
    projectinputFields.forEach((field) => {
      if (field.isMandatory && !formData[field.name]) {
        console.log(!formData[field.name]);
        errors[field.name] = `${t(field.label)} is required`;
      }
    });

    return errors;
  };

  // Handle form submit (with validation)
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return; // Stop the form submission if there are errors
    }
    try {
      let data = {
        data: {
          formData,
        },
      };
      const response = await ApiCall.service(
        `${PROJECTS}/${id}`,
        "PUT",
        formData,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.statusCode === 201) {
        CustomNotify({
          type: "success",
          message: t(response.message) || t(response.msg),
        });
        setFormData(initialFormData);
        setTimeout(() => {
          navigate("/projects-manage");
        }, 1000);
      } else if (response.statusCode === 422 || response.statusCode === 500) {
        const errorData = response.data; // Assuming the error data comes in response.data
        let updatedErrors: Record<string, string> = {};

        // Iterate through the error object and set errors for each field
        Object.keys(errorData).forEach((field) => {
          updatedErrors[field] = errorData[field][0]; // Assign the first error message
        });

        // Update form errors state
        setFormErrors(updatedErrors);

        CustomNotify({
          type: "error",
          message: t(response.message) || t(response.msg),
        });
      }
    } catch (error) {
      console.error("Error saving Projects:", error);
    }
  };

  return (
    <>
      <Header
        headerName={t("Edit project")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar">
        <div className="row">
          <BackLink backLink="/projects-manage" />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          {projectinputFields.map((field, index) =>
            field.type === "select" ? (
              <div
                className={`col-6 multiSelectWrapper mb-3 ${field.columnSize}`}
              >
                <SelectWithSearch
                  key={index}
                  search={true}
                  options={field.name == "country" ? countries : locations}
                  value={
                    field.name == "country"
                      ? selectedCountry
                      : selectedLocation
                  }
                  onChange={(e) => handleInputChange(e, field.name)}
                  placeHolder={field.placeholder}
                  isMulti={field.name === "country" ? false : false}
                  isDisabled={field.name === "country" ? false : true}
                  className={`${field.name}-select`}
                  isTranslate={false}
                  isMandatory={field.isMandatory}
                  fieldLabel={t(field.label)}
                  title={t(field.label)}
                  error={formErrors[field.name]}
                />
              </div>
            ) : (
              <div className={`${field.columnSize} mb-3`}>
                <InputTextFieldWithLabel
                  key={index}
                  type="text"
                  className="ps-2"
                  placeholder={t(field.placeholder)}
                  label={t(field.label)}
                  value={formData[field.name]}
                  name={field.name}
                  id={field.id}
                  handleChange={(e) =>
                    handleInputChange(e, field.name as keyof ProjectFormData)
                  }
                  isMandatory={field.isMandatory}
                  error={formErrors[field.name]}
                  colClassName="col-12"
                />
              </div>
            )
          )}
          {/* <div className="col-4 multiSelectWrapper">
            <SelectWithSearch
              // key={index}
              search={true}
              options={relatedCompanies}
              value={companies}
              onChange={(selectedCompanies) =>
                handleInputChange(selectedCompanies, "company_id")
              }
              placeHolder={t("Select companies")}
              isMulti={true}
              isTranslate={false}
              isMandatory={true}
              // isDisabled={true}
              fieldLabel={t("Companies")}
              title={t("Companies")}
            />
          </div> */}
          <span>
            {t("To which company would you like to add this location:")}
          </span>
          <div className="d-flex flex-wrap">
            <div className="d-flex align-items-center flex-column">
              <RadioField
                name="addProject"
                value={companies?.value}
                // value=
                id={`company`}
                ischecked={true}
                disabled={true}
                handleChange={(e) => handleInputChange(e, "company_id")}
                label={
                  <div data-bs-toggle="tooltip" title={companies?.label}>
                    <span>{t(companies?.label)}</span>
                  </div>
                }
                containerClassName="d-inline-flex"
              />
              <div>
                <Image
                  src={companies?.url}
                  imageWidth="4vw"
                  imageHeight="4vw"
                  imageBorderRadius={"50%"}
                />
                <LabelField title={t(companies?.label)} />
              </div>
            </div>
            {/* {companies.map(
              (icon, index) => (
                console.log(selectedIndex, relatedCompanies),
                (
                  <div
                    key={index}
                    className={`radioButtonContainer  ${
                      selectedIndex === index ? "border-class" : ""
                    } d-inline-block me-3`}
                    onClick={() => handleRadioClick(index, icon.value)}
                  >

                  </div>
                )
              )
            )} */}
            </div>
          <div className="col-12 mt-3">
            <Button type="submit" className="searchBtn px-3" title={t("Update project")} />
          </div>
        </div>
      </form>
    </>
  );
};

export default translate(EditProject);
