import React, { useState, useEffect } from "react";
import { PROJECTS } from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import Header from "../../common/layout/Header";
import { t, translate } from "../translation/Translation";
import Icon from "../../../Icon";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import BackLink from "../../common/atoms/BackLink";
import LinkCustom from "../../common/atoms/Link";
import { Link } from "react-router-dom";
import InputTextfield from "../configurations/manageCoefficientDeviations/InputTexfield";
import ResetBtn from "../../common/atoms/ResetBtn";
import SearchBtn from "../../common/atoms/SearchBtn";
import Pagination from "../../common/atoms/Pagination";

interface Project {
  id: number;
  name: string;
}

const ManageProjects: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]); // State to store projects
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]); // State for filtered Projects
  const [searchTerm, setSearchTerm] = useState<string>(""); // State for search input
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state

  // Fetch Projects from API
  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await ApiCall.getService(
          `${PROJECTS}`,
          "GET",
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response.statusCode === 200) {
          const projectsData = response.data.map((item: any) => ({
            id: item.id,
            name: item.name,
          }));
          setProjects(projectsData);
          setFilteredProjects(projectsData); // Initially set filtered projects to all projects
          setLoading(false);
        }
      } catch (error) {
        setError("Failed to fetch projects");
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  // Handle input change for search
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value); // Only store the search term but don't filter yet
  };

  // Handle reset button click to clear search
  const handleResetClick = () => {
    setSearchTerm("");
    setFilteredProjects(projects); // Reset to original projects
  };

  // Handle search button click
  const handleSearchClick = () => {
    // Filter projects based on the search term
    const filteredData = projects.filter((project) =>
      project.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProjects(filteredData);
  };

  return (
    <>
      <Header
        headerName={t("Manage projects")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-4">
        <div className="row mb-2">
          <BackLink backLink="/" />
          <div className="col-6 text-end">
            <Link to="/project/add">
              <div className="searchBtn d-inline-flex align-items-center justify-content-center text-decoration-none w-auto px-3">
                {t("Add project")}
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <InputTextfield
              value={searchTerm}
              handleChange={handleInputChange}
              placeholder={t("Project")}
              className="ps-2"
            />
          </div>
          <div className="col-4">
            <ResetBtn handleResetClick={handleResetClick} />
            <SearchBtn handleSearchClick={handleSearchClick} />
          </div>
        </div>
      </div>
      <div className="managepagesBg">
        <OverFlowWrapper className="table-responsive height-25">
          <table className="table table-hover customTableProperties">
            <thead>
              <tr className="TableHeader">
                <th style={{ width: "60%" }}>{t("Project Name")}</th>
                <th>{t("Action")}</th>
              </tr>
              <tr>
                <th
                  className="emptyRow p-0"
                  style={{ height: "0.7vw" }}
                  colSpan={3}
                ></th>
              </tr>
            </thead>
            <tbody>
              {filteredProjects.map((project) => (
                <tr key={project.id} className="border">
                  <td>{project.name}</td>
                  <td>
                    {/* Edit link using your custom Link component */}
                    <LinkCustom
                      href={`/project/edit/${project.id}`}
                      className="secondaryColorHoverEffect"
                    >
                      <span title={t("Edit project")}>
                        <Icon {...IconProps} name="EditIcon" />
                      </span>
                    </LinkCustom>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </OverFlowWrapper>
        {/* {pageCount > 1 && (
          <div className="pagination justify-content-center mt-4 px-4">
            <Pagination
              currentPage={currentPage + 1}
              totalPages={pageCount}
              onPageChange={handlePageClick}
            />
          </div>
        )} */}
      </div>
    </>
  );
};

export default translate(ManageProjects);
