import React, { useEffect, useState } from "react";
import Header from "../../common/layout/Header";
import { t, translate } from "../translation/Translation";
import { InputText } from "primereact/inputtext";
import InputTextFieldWithLabel from "../../common/molecules/InputTextFieldWithLabel";
import FileUpload from "../../common/molecules/FileUpload";
import { CostCenterFormData, inputFields } from "./CostCenterUtils";
import { ApiCall } from "../../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import {
  COST_CENTERS,
  FILE_URL,
  GET_COUNTRIES,
  LOCATIONS,
  PROJECTS,
  SHIFTS,
} from "../../../routes/ApiEndpoints";
import SelectWithSearch from "../../common/atoms/SelectWithSearch";
import CustomNotify from "../../common/atoms/CustomNotify";
import MultiSelectField from "../../common/atoms/MultiSelectField";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import BackLink from "../../common/atoms/BackLink";
import Button from "../../common/atoms/Button";

const AddCostCenter: React.FC = () => {
  // const [locationName, setLocationName] = useState("");
  const navigate = useNavigate();
  const initialFormData: CostCenterFormData = {
    costcenter_name: "",
    costcenter_id: 0,
    location_id: 0,
    company_id: [],
  };
  const projectinputFields = inputFields;

  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [formData, setFormData] = useState<CostCenterFormData>(initialFormData);
  const [selectedCompanies, setSelectedCompanies] = useState<any[]>([]);
  const [countries, setCountries] = useState([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState<Location[]>([]);
  const [url, setUrl] = useState<string | null>(null);

  const companiesObj = [
    { value: 1, label: "Option 1" },
    { value: 2, label: "Option 2" },
    { value: 3, label: "Option 3" },
  ];

  interface Location {
    iso: string;
    value: number;
    label: string;
  }

  useEffect(() => {
    getLocations();
  }, []);

  const getLocations = async () => {
    const response = await ApiCall.getService(
      `${LOCATIONS}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.statusCode == 200) {
      const data = response.data;
      // Transform the response data to match the Location interface
      const transformedLocations: Location[] = data.map((location: any) => ({
        value: location.id,
        label: location.name,
      }));
      setLocations(transformedLocations);
      if (transformedLocations.length > 0) {
        // Set the first location in state
        console.log(transformedLocations);
        setSelectedLocation([transformedLocations[0]]);
        setFormData((prevData) => ({
          ...prevData, // Keep the previous form data
          location_id: transformedLocations[0].value, // Update only the project_name key
        }));
      }
    }
  };

  // Handle input change for text fields
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any,
    fieldKey: keyof CostCenterFormData
  ) => {
    let value: any = "";
    switch (fieldKey) {
      case "location_id":
        console.log(e);
        break;
      case "costcenter_name":
        value = e.target.value;
        break;
      case "costcenter_id":
        value = e.target.value;
        break;
      case "company_id":
        const selectedCompanyIds = e.map((company: any) => company.value);
        value = selectedCompanyIds;
        setCompanies(e);
        break;
      default:
        value = e.target.value;
        break;
    }

    setFormData((prevData) => ({
      ...prevData,
      [fieldKey]: value,
    }));

    // Clear the error for the updated field
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldKey]: value ? "" : prevErrors[fieldKey], // Remove the error if value is not empty
    }));
  };

  const validateForm = () => {
    let errors: Record<string, string> = {};
    // Loop through the form fields to check for validation
    projectinputFields.forEach((field) => {
      if (field.isMandatory && !formData[field.name]) {
        console.log(!formData[field.name]);
        errors[field.name] = `${t(field.label)} is required`;
      }
    });

    return errors;
  };

  // Handle form submit (with validation)
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return; // Stop the form submission if there are errors
    }
    try {
      let data = {
        data: {
          formData,
        },
      };
      const response = await ApiCall.service(
        COST_CENTERS,
        "POST",
        formData,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.statusCode === 201) {
        CustomNotify({
          type: "success",
          message: t(response.message) || t(response.msg),
        });
        setFormData(initialFormData);
        setTimeout(() => {
          navigate("/costcenters-manage");
        }, 1000);
      } else if (response.statusCode === 422 || response.statusCode === 500) {
        const errorData = response.data; // Assuming the error data comes in response.data
        let updatedErrors: Record<string, string> = {};

        // Iterate through the error object and set errors for each field
        Object.keys(errorData).forEach((field) => {
          updatedErrors[field] = errorData[field][0]; // Assign the first error message
        });

        // Update form errors state
        setFormErrors(updatedErrors);

        CustomNotify({
          type: "error",
          message: t(response.message) || t(response.msg),
        });
      }
    } catch (error) {
      console.error("Error saving projects:", error);
    }
  };

  return (
    <>
      <Header
        headerName={t("Add cost center")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar">
        <div className="row">
          <BackLink backLink="/costcenters-manage" />
        </div>
      </div>
      <div className="createpagesBg">
        <form onSubmit={handleSubmit}>
          <div className="row">
            {projectinputFields.map((field, index) =>
              field.type === "select" ? (
                <div className="col-6 multiSelectWrapper">
                  <SelectWithSearch
                    key={index}
                    search={true}
                    options={locations}
                    value={selectedLocation}
                    onChange={(e) => handleInputChange(e, field.name)}
                    placeHolder={field.placeholder}
                    isMulti={false}
                    isDisabled={false}
                    className={`${field.name}-select`}
                    isTranslate={false}
                    isMandatory={field.isMandatory}
                    fieldLabel={t(field.label)}
                    title={t(field.label)}
                    error={formErrors[field.name]}
                  />
                </div>
              ) : (
                <div className={`${field.columnSize} mb-3`}>
                  <InputTextFieldWithLabel
                    key={index}
                    type="text"
                    className="ps-2"
                    placeholder={t(field.placeholder)}
                    label={t(field.label)}
                    value={formData[field.name]}
                    name={field.name}
                    id={field.id}
                    handleChange={(e) =>
                      handleInputChange(
                        e,
                        field.name as keyof CostCenterFormData
                      )
                    }
                    isMandatory={field.isMandatory}
                    colClassName="col-12"
                    error={formErrors[field.name]}
                  />
                </div>
              )
            )}
            <div className="col-6 multiSelectWrapper">
              <SelectWithSearch
                // key={index}
                search={true}
                options={companiesObj}
                value={companies}
                onChange={(selectedCompanies) =>
                  handleInputChange(selectedCompanies, "company_id")
                }
                placeHolder={t("Select companies")}
                isMulti={true}
                className="country-select"
                isTranslate={false}
                isMandatory={true}
                // isDisabled={true}
                fieldLabel={t("Companies")}
                title={t("Companies")}
              />
            </div>
            <div className="col-12 mt-3 text-end">
              <Button type="submit" className="searchBtn px-3" title={t("Save costcenter")} />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default translate(AddCostCenter);
