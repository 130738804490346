import React from "react";
import Select from "react-select";
import { t } from "../../translation/Translation";
const customStyles = {
  control: (base) => ({
    ...base,
    height: 35,
    minHeight: 35,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "lightgray" : null,
    color: "black",
  }),
};
// Override the IndicatorSeparator to hide it
const customComponents = {
  IndicatorSeparator: () => null,
};
export default function MultiSelect({
  handleChange,
  standards,
  options,
  disabled,
  isMulti = true,
  name = "",
  placeholder = t("Select"),
  className = "",
}) {
  const customFilter = (option, searchText) => {
    if (
      option.label !== undefined &&
      option.label !== null &&
      option.label.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    }
    return false;
  };
  return (
    <Select
      placeholder={placeholder}
      value={standards}
      onChange={handleChange}
      isDisabled={disabled}
      options={options}
      filterOption={customFilter}
      className={`${className} multiSelectWrapper`}
      classNamePrefix="multiSelectDropdown"
      isMulti={isMulti}
      styles={customStyles}
      name={name}
      components={customComponents}
      noOptionsMessage={t("No option")}
    />
  );
}
