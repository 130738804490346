import React, { useEffect, useState } from "react";
import Image from "../common/atoms/Image";
import LoadImage from "../../static/images/DummyImage.jpg";
import Icon from "../../Icon";
import "./css/weekCalender.css";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../services/ApiServices";
import { PLANNING_OVERVIEW } from "../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE, PLANNING_MICROSERVICE } from "../../Constants";
import UserImage from "../../static/images/User.svg";
import { formatDateTime } from "../common/utlis/dateHelper";
import { weekdays } from "moment";
import { getWeekDays } from "../common/utlis/PlanningTimingHelper";

interface Timing {
  ptId: number;
  shift_starttime: string;
  shift_endtime: string;
  shift_sequence: number;
}

interface EventData {
  length: number;
  map(arg0: (employee: EventData) => React.JSX.Element): React.ReactNode;
  id: number;
  employee_id: number;
  name: string;
  employee_profile_pic: string;
  employee_type: string;
  employee_type_id: number;
  function_name: string;
  function_id: number;
  pc_id: number;
  pc_name: string;
  salary: string;
  pdate: string;
  planning_status: string | null;
  failed_reason: string | null;
  location_name: string;
  location_id: number;
  total_hrs: number;
  timings: Timing[];
}

interface WeeklyCalendarProps {
  companyId: string;
  selectedLocation?: { // Add prop for selected location
    address_id: number;
    id: number;
    location_image_id: number;
    name: string;
  };
  startDate?: Date;
  data?: { [key: string]: EventData[] }; // Updated data format to an array of EventData
}

const WeeklyCalendar: React.FC<WeeklyCalendarProps> = ({ startDate,companyId,selectedLocation, data }) => {
  const [weekData, setWeekData] = useState<EventData[]>([]);

  const weekDays = getWeekDays(startDate || new Date());


  useEffect(() => {
    const fetchData = async () => {
      try {
        let dataObj = {
          "company_id": companyId,
          "location_id": selectedLocation?.id,
          "start_date": formatDateTime(weekDays[0]),
          "end_date": formatDateTime(weekDays[weekDays.length - 1])
        }
        let result = await ApiCall.service(PLANNING_OVERVIEW, "POST", dataObj, true, CENTRAL_DATA_MANAGEMENT_MICROSERVICE);
        if (result.status === 200) {
          setWeekData(result.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [startDate]);

  // Get today's date
  const today = new Date();
  const currentDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const IconProps = {
    isIcon: true,
    width: "0.8vw",
    height: "0.8vw",
    cursor: "pointer",
  };
  return (
    <div className="d-grid">
      <div className="weekHeader">
        <div className="weekHeaderRow d-grid">
          {weekDays.map((day, index) => {
            const isToday = day.toDateString() === currentDay.toDateString();
            return (
              <div key={index} className={`weekDay text-center ${isToday ? "currentDay" : ""}`}>
                <div className="dayName">
                  {day.toLocaleDateString("en-US", { weekday: "long" })}
                </div>
                <div className="dayDate">
                  {day.getDate()} {day.toLocaleDateString("en-US", { month: "long" })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="weekData d-grid">
        {Object.keys(weekData).map((dateKey: any, index) => {
          const dayData = weekData[dateKey]; // Get the data for the specific day
          const isToday = weekDays[index].toDateString() === currentDay.toDateString(); // Check if it's today

          return (
            <div key={index} className="dayDataItem">
              {dayData && dayData.length > 0 ? (
                dayData.map((employee: EventData) => (
                  <div key={employee.id} className="dayDataContent mb-2">
                    <div className="row">
                      <div className="col-10 pe-0">
                        <div className="row">
                          <div className="col-3 pe-0">
                            <Image
                              src={employee.employee_profile_pic}
                              imageWidth="2vw"
                              imageHeight="2vw"
                              imageBorderRadius="50%"
                            />
                          </div>
                          <div className="col-9">
                            <div className="planningDetails colorPrimary">
                              <div className="eventName">{employee.name}</div>
                              <div className="eventStatus">{employee.employee_type}</div>
                              <div className="eventFunction">{employee.function_name}</div>
                              <div className="eventPC">{employee.pc_name} | {employee.salary}</div>
                              <div className="eventLocation">{employee.location_name}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2 align-self-center">
                        <div data-bs-toggle="tooltip" title={t("Clone")} onClick={() => { }}>
                          <Icon name="CloneIcon" {...IconProps} />
                        </div>
                        <div data-bs-toggle="tooltip" title={t("Edit")} onClick={() => { }}>
                          <Icon name="EditIcon" {...IconProps} />
                        </div>
                        <div data-bs-toggle="tooltip" title={t("Delete")} onClick={() => { }}>
                          <Icon name="RemoveIcon" {...IconProps} />
                        </div>
                      </div>
                    </div>
                    <div className="row weeklyPlannedTimingWrapper">
                      <div className="col-8">
                        {employee.timings.map((timing) => (
                          <div className="row" key={timing.ptId}>
                            <div className="col-6 pe-0">
                              <div className="plannedTiming">{timing.shift_starttime}</div>
                            </div>
                            <div className="col-6 pe-0">
                              <div className="plannedTiming">{timing.shift_endtime}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="col-4">
                        <div className="plannedOverallTiming">
                          {employee.total_hrs} hrs

                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="dayDataContent">{t('No data for this day')}</div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default translate(WeeklyCalendar);
