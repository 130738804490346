import React, { useState, useEffect } from "react";
import InputField from "./InputTexfield";
import MultiSelectField from "./MultiSelectField";
import ValidateMessage from "./validationError";
import { ApiCall } from "../../../../services/ApiServices";
import {
  FETCH_COEFFICIENT_DEVIATION,
  UPDATE_COEFFICIENT_DEVIATION,
} from "../../../../routes/ApiEndpoints";
import BackLink from "../../../common/atoms/BackLink";
import { t } from "../../../common/atoms/Constants";
import LabelField from "../../../common/atoms/LabelField";
import Button from "../../../common/atoms/Button";
import { FullScreenLoading } from "../../../common/utlis/FullScreenLoading";

const EditCoefficientDeviation = ({
  action,
  entityId = 0,
  entityType = 0,
  editId,
  coefficientDetails = {},
}) => {
  const assignInitialValues = (coefficientDetails) => {
    return {
      name: coefficientDetails.perfcode_name || "",
      code: coefficientDetails.perfcode_code || "",
      employeetypeId: coefficientDetails.employeetype_id || 0,
      coefficient: coefficientDetails.coefficient || "",
      perfcodeId: coefficientDetails.perfcode_id || 0,
      id: coefficientDetails.id || 0,
      coefficientTypes: [
        { label: "Refer coefficient value", value: 0 },
        { label: "Default value", value: 1 },
      ],
      coefficientTypesDefault: coefficientDetails.employeetype_id === 0 ? 1 : 0,
      allCoefficientTypes: coefficientDetails.allCoefficientTypes || [],
    };
  };

  const [state, setState] = useState({
    ...assignInitialValues(coefficientDetails),
    nameWarning: false,
    codeWarning: false,
    coefficientTypesWarning: false,
    allCoefficientTypesWarning: false,
    coefficientWarning: false,
    loading: false,
    action: action,
    loaded: false,
  });

  useEffect(() => {
    fetchData();
  }, [action, Object.keys(coefficientDetails).length]);

  const fetchData = async () => {
    if (state.action === 1) {
      await ApiCall.service(
        FETCH_COEFFICIENT_DEVIATION,
        "POST",
        { id: editId, edit: 0 },
        false,
        "central-data-management"
      )
        .then((response) => {
          if (response.success) {
            const coefficientDetails = response.data[0];
            const initialValues = assignInitialValues(coefficientDetails);

            setState((prevState) => ({
              ...prevState,
              ...initialValues,
              loaded: true,
            }));
          }
        })
        .catch((error) => {
          console.error("Error occurred", error);
        });
    }
  };

  const handleSave = async (draft = 0) => {
    let proceed = true;
    if (!draft) {
      proceed = checkRequiredField();
    }
    if (proceed) {
      try {
        const response = await ApiCall.service(
          UPDATE_COEFFICIENT_DEVIATION,
          "POST",
          getPostData(draft)
        );
        if (response.success) {
          window.location.reload();
        }
      } catch (error) {
        console.error("Error occurred", error);
      }
    }
  };

  const getPostData = (draft) => {
    const {
      name,
      code,
      employeetypeId,
      coefficient,
      perfcodeId,
      id,
      coefficientTypes,
      coefficientTypesDefault,
    } = state;
    return {
      name,
      code,
      employeetypeId,
      coefficient,
      perfcodeId,
      id,
      coefficientTypes,
      coefficientTypesDefault,
    };
  };

  const handleChange = (e, type) => {
    let setObj = { ...state };
    const { value, name } = e.target;

    // Validation for coefficient to accept only digits and comma
    if (name === "coefficient") {
      const validValue = value.replace(/[^0-9,]/g, ""); // Allow only digits and commas
      setObj[name] = validValue;
      setObj["coefficientWarning"] = !validValue; // Set warning if no valid input
    } else {
      setObj[name] = value;
      setObj[`${name}Warning`] = false;
    }

    setState(setObj);
  };

  const checkRequiredField = () => {
    const { name, coefficientTypesDefault, employeetypeId, coefficient } =
      state;
    let proceed = true;
    let setObj = {};

    if (!name.length) {
      setObj["nameWarning"] = true;
      proceed = false;
    }

    if (coefficientTypesDefault === 0 && employeetypeId === 0) {
      alert("0");
      setObj["coefficientTypesWarning"] = true;
      proceed = false;
    } else if (coefficientTypesDefault === 1 && !coefficient) {
      setObj["coefficientWarning"] = true;
      proceed = false;
    }

    setState({ ...state, ...setObj });
    return proceed;
  };

  const onSelect = (e, type) => {
    let setObj = { ...state };

    if (type === 1) {
      setObj.coefficientTypesDefault = e.value;
      setObj.employeetypeId = e.value === 0 ? state.employeetypeId : 0;
      setObj.coefficientTypesWarning = false;
    } else if (type === 2) {
      setObj.employeetypeId = e.value;
      setObj.allCoefficientTypesWarning = false;
    }

    setState(setObj);
  };

  return (
    <div>
      {state.loaded ? (
        <>
          <div className="search-bar pb-3">
            <div className="row">
              <BackLink backLink="/manage/coefficient-deviation?entitytype=1" />
            </div>
          </div>
          <div className="createpagesBg">
            <div className="pb-3">
              <LabelField
                title={t("Code")}
                className="field-label colorPrimary poppins-medium field-label"
                mandatory={false}
              />
              <InputField
                type={"text"}
                value={state.code}
                isDisabled={true}
                placeholder={t("Enter code")}
                handleChange={(e) => handleChange(e, 1)}
                name={"code"}
                className="ps-2"
              />
              {state.codeWarning && (
                <ValidateMessage
                  style={{ margin: 0 }}
                  text={"This field is required."}
                />
              )}
            </div>
            <div className="pb-3">
              <LabelField
                title={t("Name")}
                className="field-label colorPrimary poppins-medium field-label"
                mandatory={false}
              />
              <InputField
                type={"text"}
                className={"ps-2"}
                value={state.name}
                isDisabled={true}
                placeholder={t("Enter name")}
                handleChange={(e) => handleChange(e, 1)}
                name={"name"}
              />
              {state.nameWarning && (
                <ValidateMessage
                  style={{ margin: 0 }}
                  text={"This field is required."}
                />
              )}
            </div>
            <div>
              <div className="pb-3 multiSelectWrapper">
                <LabelField
                  title={t("Select coefficient value type")}
                  mandatory={false}
                  className="field-label colorPrimary poppins-medium field-label"
                />
                <MultiSelectField
                  options={state.coefficientTypes}
                  standards={state.coefficientTypes.filter(
                    (val) => val.value === state.coefficientTypesDefault
                  )}
                  handleChange={(e) => onSelect(e, 1)}
                  isMulti={false}
                  placeholder={t("Select coefficient value type")}
                  classNamePrefix="multiSelectDropdown"
                />
                {state.coefficientTypesWarning && (
                  <ValidateMessage
                    style={{ margin: 0 }}
                    text={t("This field is required") + "."}
                  />
                )}
              </div>
              {state.coefficientTypesDefault === 0 ? (
                <div className="pb-3 multiSelectWrapper">
                  <LabelField
                    title={t("Select reference coefficient")}
                    mandatory={true}
                    className="field-label colorPrimary poppins-medium field-label"
                  />
                  <MultiSelectField
                    options={state.allCoefficientTypes}
                    standards={state.allCoefficientTypes.filter(
                      (val) => val.value === state.employeetypeId
                    )}
                    handleChange={(e) => onSelect(e, 2)}
                    isMulti={false}
                    placeholder={t("Select reference coefficient")}
                    classNamePrefix="multiSelectDropdown"
                  />
                  {state.allCoefficientTypesWarning && (
                    <ValidateMessage
                      style={{ margin: 0 }}
                      text={t("This field is required") + "."}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <LabelField
                    title={t("Coefficient value")}
                    className="poppins-medium-18px"
                    mandatory={true}
                  />
                  <InputField
                    type={"text"}
                    value={state.coefficient}
                    isDisabled={false}
                    placeholder={t("Enter coefficient value")}
                    handleChange={(e) => handleChange(e, 1)}
                    name={"coefficient"}
                  />
                  {state.coefficientWarning && (
                    <ValidateMessage
                      style={{ margin: 0 }}
                      text={
                        t("This field is required and must be a valid number") +
                        "."
                      }
                    />
                  )}
                </div>
              )}
            </div>
            <div className="row pt-4">
              <div className="col-md-12">
                <Button
                  className="searchBtn px-3 float-end"
                  title={t("Update")}
                  handleClick={() => handleSave(0)}
                />
              </div>
            </div>
          </div>
        </>
      ): <FullScreenLoading />}
    </div>
  );
};

export default EditCoefficientDeviation;
