import React, { MouseEventHandler } from "react";
import Image from "../atoms/Image";
import Icon from "../../../Icon";
import UserImage from "../../../static/images/User.svg";

interface PlanningEmployeeDetailsCardProps {
  cardClassName?: string;
  employeeInformationClass?: string;
  employeeName: string;
  employeePosition: string;
  employeeLocation: string;
  employeeRole: string;
  employeePC: string;
  employeeSalary: string;
  employeeImage?: string; // Optional, default image if not provided
  isCardIcon?: boolean;
  iconName?: string;
  cardMainClassName?: string;
  iconClassName?: string;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  favouriteIconClassName?: string;
  isFavourtite?:boolean;
  favouriteIconName?:string;
}

const PlanningEmployeeDetailsCard: React.FC<PlanningEmployeeDetailsCardProps> = ({
  cardClassName,
  employeeInformationClass,
  employeeName,
  employeePosition,
  employeeLocation,
  employeeRole,
  employeePC,
  employeeSalary,
  employeeImage,
  isCardIcon = false,
  iconName,
  cardMainClassName,
  iconClassName,
  handleClick,
  favouriteIconClassName,
  isFavourtite,
  favouriteIconName
}) => {
  return (
    <div className={cardMainClassName}>
   
      {isCardIcon && (
        <div className={iconClassName}>
          <span onClick={handleClick}>
            <Icon isIcon={true} width="1vw" height="1vw" name={iconName} />
          </span>
        </div>
      )}
      <div className={`${cardClassName}`}>
      {isFavourtite && (
        <div className={`${favouriteIconClassName}`}>
          <span onClick={handleClick}>
            <Icon isIcon={true} width="1vw" height="1vw" name={favouriteIconName} />
          </span>
        </div>
      )}
        <div className="mx-3">
        <Image
          src={employeeImage || UserImage} // Default image if none provided
          imageWidth="3vw"
          imageHeight="3vw"
          imageBorderRadius="50%"
      
        />
        </div>
        <div className={employeeInformationClass}>
          <div>{employeeName}</div>
          <div>{employeePosition}</div>
          <div>{employeeLocation}</div>
          <div>{employeeRole}</div>
          <div>{employeePC}</div>
          <div>€ {employeeSalary}</div>
        </div>
      </div>
    </div>
  );
};

export default PlanningEmployeeDetailsCard;
