import React, { useState, useEffect, useMemo } from "react";
import LinkCustom from "../../common/atoms/Link";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import { MANAGE_PC_OVERVIEW_DETAILS } from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import styles from "./Pc.module.css";
import { useNavigate } from "react-router-dom";
import { FullScreenLoading } from "../../common/utlis/FullScreenLoading";
import Header from "../../common/layout/Header";
import Search from "../../common/atoms/Search";
import ResetBtn from "../../common/atoms/ResetBtn";
import SearchBtn from "../../common/atoms/SearchBtn";
import Icon from "../../../Icon";
import BackLink from "../../common/atoms/BackLink";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import Pagination from "../../common/atoms/Pagination";

/**
 * this will project all the partire committee's data.
 * @returns
 */

const ManagePc = () => {
  const [data, setData] = useState([]);
  const [temp, setTemp] = useState([]);
  const [temp2, setTemp2] = useState([]);

  const [searchPcnum, setSearchPcnum] = useState("");
  const [searchPcname, setSearchPcname] = useState("");
  // const [itemsPerPage, setItemsPerPage] = useState(8);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(0);
  const PER_PAGE = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiCall.getService(
          MANAGE_PC_OVERVIEW_DETAILS,
          "GET",
          "central-data-management"
        );
        if (response.success) {
          setData(response.data);
          setTemp(response.data);
          setTemp2(response.data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  let handleSearch = () => {
    setSearch(true);

    const res = temp.filter((val) => {
      const pcNumberMatch = val["pc_number"]
        .trim()
        .includes(searchPcnum.trim());
      const pcAliasNameMatch = val["pc_alias_name"]
        ? val["pc_alias_name"]
          .trim()
          .toLowerCase()
          .includes(searchPcname.trim().toLowerCase())
        : false;
      const pcNameMatch = val["pc_name"]
        ? val["pc_name"]
          .trim()
          .toLowerCase()
          .includes(searchPcname.trim().toLowerCase())
        : false;

      if (searchPcnum && searchPcname) {
        return (
          pcNumberMatch &&
          (pcAliasNameMatch || (!val["pc_alias_name"] && pcNameMatch))
        );
      }

      if (searchPcnum) {
        return pcNumberMatch;
      }

      if (searchPcname) {
        return pcAliasNameMatch || (!val["pc_alias_name"] && pcNameMatch);
      }

      return false;
    });

    setData(res);
    setCurrentPage(0);
  };

  function handleReset() {
    setData(temp);
    setSearchPcnum("");
    setSearchPcname("");
    setSearch(false);
    setCurrentPage(0); // Reset to first page
  }

  //------------------- Pagination code -------------------------//

  const pageCount = Math.ceil(data.length / PER_PAGE);

  useEffect(() => {
    const offset = currentPage * PER_PAGE;
    const currentpgData = data.slice(offset, offset + PER_PAGE);
    setTemp2(currentpgData);
  }, [currentPage, data, search]);
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber - 1); // Adjust for zero-based index
  };

  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
  };
  return (
    <div className="container-fluid p-0">
      {loading === true ? (
        <FullScreenLoading />
      ) : (
        <>
          <Header
            headerName={t("Manage PC")}
            headerClassName="myAccountCardTitle"
          />
          <div className="search-bar pb-4">
            <div className="row mb-2">
              <BackLink backLink="/" />
            </div>
            <div className="row">
              <div className="col-4">
                <Search
                  value={searchPcnum}
                  placeholder={t("PC number")}
                  handleChange={(e) => setSearchPcnum(e.target.value)}
                />
              </div>
              <div className="col-4">
                <Search
                  value={searchPcname}
                  placeholder={t("PC name")}
                  handleChange={(e) => setSearchPcname(e.target.value)}
                />
              </div>
              <div className="col-4">
                <div className="d-flex align-items-center">
                  <ResetBtn handleResetClick={() => handleReset()} />
                  <SearchBtn handleSearchClick={() => handleSearch()} />
                </div>
              </div>
            </div>
          </div>
          <div className="managepagesBg">
            <OverFlowWrapper className="table-responsive managePcTable">
              <table className="table table-hover customTableProperties">
                <thead className="headSticky">
                  <tr className="TableHeader">
                    <th style={{ width: "10%" }}>{t("S No.")}</th>
                    <th style={{ width: "33%" }}>{t("PC number")}</th>
                    <th style={{ width: "38%" }}>{t("PC name")}</th>
                    <th style={{ width: "25%" }}>{t("Actions")}</th>
                  </tr>
                  <tr>
                    <th
                      className="emptyRow p-0"
                      style={{ height: "0.7vw" }}
                      colSpan={3}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {temp2.length > 0 ? (
                    temp2.map((val, key) => (
                      <tr className="border" key={key}>
                        <td>{count + key + 1}</td>
                        <td>{val.pc_number}</td>
                        <td>
                          {val.pc_alias_name ? val.pc_alias_name : val.pc_name}
                        </td>
                        <td>
                          <LinkCustom
                            href={`/editpc/${val.pc_unique_key}`}
                            className="secondaryColorHoverEffect me-2"
                          >
                            <span title={t("Edit PC")}>
                              <Icon name="EditIcon" {...IconProps} />
                            </span>
                          </LinkCustom>
                          <LinkCustom
                            href={`/viewpc/${val.pc_unique_key}`}
                            className="secondaryColorHoverEffect"
                          >
                            <span title={t("View PC")}>
                              <Icon name="viewIcon" {...IconProps} />
                            </span>
                          </LinkCustom>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        className="border text-center text-danger"
                        colSpan={4}
                      >
                        {t("No paritair committee")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </OverFlowWrapper>
            {pageCount > 1 && (
              <div className="pagination justify-content-center mt-4 px-4">
                <Pagination
                  currentPage={currentPage + 1}
                  totalPages={pageCount}
                  onPageChange={handlePageClick}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default translate(ManagePc);
