import React, { useEffect, useState } from "react";
import Header from "../../common/layout/Header";
import { t, translate } from "../translation/Translation";
import { InputText } from "primereact/inputtext";
import InputTextFieldWithLabel from "../../common/molecules/InputTextFieldWithLabel";
import FileUpload from "../../common/molecules/FileUpload";
import {
  LocationFormData,
  fileData,
  formatHouseNumber,
  formatPostalCode,
  formatStreet,
  inputFields,
  intialFileData,
} from "./LocationUtils";
import { ApiCall } from "../../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import {
  FILE_URL,
  GET_COMPANIES_BY_UID,
  GET_COUNTRIES,
  LOCATIONS,
  SHIFTS,
} from "../../../routes/ApiEndpoints";
import SelectWithSearch from "../../common/atoms/SelectWithSearch";
import CustomNotify from "../../common/atoms/CustomNotify";
import MultiSelectField from "../../common/atoms/MultiSelectField";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import BackLink from "../../common/atoms/BackLink";
import Button from "../../common/atoms/Button";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../features/auth/AuthSlice";
import RadioField from "../../common/atoms/RadioField";
import Image from "../../common/atoms/Image";
import LoadImage from "../../../static/images/DummyImage.jpg";
import LabelField from "../../common/atoms/LabelField";

interface Country {
  iso: string;
  value: number;
  label: string;
}

interface CompanyObj {
  value: number;
  label: string;
  url: string;
}

const EditLocation: React.FC = () => {

  const { id } = useParams<{ id: string }>();
  const user = useSelector(selectAuth).userId;
  const initialFormData: LocationFormData = {
    location_pic: "",
    location_name: "",
    street: "",
    number: "",
    postal_code: "",
    box: "",
    city: "",
    country: "",
    company_id: [],
  };

  const locationinputFields = inputFields;

  const navigate = useNavigate();
  const [formData, setFormData] = useState<LocationFormData>(initialFormData);
  const [fileDetails, setFileDetails] = useState<fileData>(intialFileData);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [countries, setCountries] = useState<Country[]>([]);
  // const [companies, setCompanies] = useState([]);
  // const [companies, setCompanies] = useState<CompanyObj>();
  const [companies, setCompanies] = useState<CompanyObj>({
    value: 0,
    label: '',
    url: '',
  });
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedCompanies, setSelectedCompanies] = useState<any[]>([]);
  const [relatedCompanies, setRelatedCompanies] = useState<CompanyObj[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    // First, load the countries and then fetch the location
    const loadData = async () => {
      await getCompanies();
      await getCountries();
    };
    loadData();
  }, []);

  // Fetch countries
  const getCountries = async () => {
    try {
      const response = await ApiCall.getService(
        `${GET_COUNTRIES}`,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        const countryList = response.data;
        setCountries(countryList); // Set the countries

        // Once countries are set, fetch the location
        fetchLocationById(countryList);
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const getCompanies = async () => {
    const response = await ApiCall.getService(
      `getCompaniesByUserId/${user}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status == 200) {
      // Convert the data to match the CompanyObj interface
      const formattedCompanies = response.data.map((company: any) => ({
        value: company.id,
        label: company.name,
        url: "https://t3.ftcdn.net/jpg/02/55/86/12/360_F_255861239_viu6hRNcURzeKUoyPJBFOyTu1fA8aJtM.jpg",
        // url: company.profile_picture_url,
      }));
      setRelatedCompanies(formattedCompanies);
    }
  };


  // Fetch the location by ID
  const fetchLocationById = async (countryList: Country[]) => {
    try {
      const response = await ApiCall.getService(
        `${LOCATIONS}/${id}`,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.statusCode === 200) {
        const locationData = response.data;
        console.log(locationData , "loc")
        setFormData({
          location_pic: locationData.location_image_id || "",
          location_name: locationData.name || "",
          street: locationData.address?.street || "",
          number: locationData.address?.number || "",
          postal_code: locationData.address?.postal_code || "",
          box: locationData.address?.box || "",
          city: locationData.address?.city || "",
          country: locationData.address?.country?.id || "",
          company_id: locationData.company_id ? [locationData.company_id] : [],
        });

        setCompanies({
          value: locationData.company_id,
          label: locationData.company.official_name,
          url: "https://t3.ftcdn.net/jpg/02/55/86/12/360_F_255861239_viu6hRNcURzeKUoyPJBFOyTu1fA8aJtM.jpg",
        });

        // Pre-fill selected country and companies once data is loaded
        const selectedCountry = countryList.find(
          (country: Country) =>
            country.value === locationData.address?.country?.id
        );

        setSelectedCountry(selectedCountry || null); // Set the selected country
        setFileDetails({
          fileId: locationData.location_image.id,
          fileName: locationData.location_image.file_name,
          filePath: locationData.location_image.file_path,
        });

      }
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  console.log(companies , "companies");

  const handleFileUploadResponse = (uploadResponse: any) => {

    setFileDetails({
      fileId:  uploadResponse.response.data.fileId,
      fileName:uploadResponse.response.data.fileName,
      filePath:  uploadResponse.response.data.filePath,
    });
    setFormData((prevData) => ({
      ...prevData,
      location_pic: uploadResponse.response.data.fileId,
    }));
  };


  // In the JSX, the `SelectWithSearch` component will be populated with the countries and selected country value.
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any,
    fieldKey: keyof LocationFormData
  ) => {
    // Handle changes for each field
    let value: any = "";
    switch (fieldKey) {
      case "country":
        value = e?.value || "";
        setSelectedCountry(e);
        break;
      case "company_id":
        // const selectedCompanyIds = e.map((company: any) => company.value);
        // setSelectedCompanies(e);
        // value = selectedCompanyIds;
        let ids = JSON.parse(e.target.value);
        value = [ids];
        console.log("Array of company IDs:", value);
        break;
        case "company_id":
          value = [e.value];
          setCompanies(e);
          break;
      default:
        value = e.target.value;
        break;
    }
    setFormData((prevData) => ({ ...prevData, [fieldKey]: value }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldKey]: value ? "" : prevErrors[fieldKey],
    }));
  };

  // const handleRadioClick = (index: number, companyId: number) => {
  //   if (selectedIndex === index) {
  //     // Reset selection
  //     setSelectedIndex(-1);
  //     setSelectedCompanies([]);
  //     // handleInputChange([], "company_id");
  //   } else {
  //     setSelectedIndex(index);
  //     setSelectedCompanies([companyId]);
  //     // handleInputChange([companyId], "company_id");
  //   }

  const handleSubmit = async (e: React.FormEvent) => {
    console.log(formData);
    e.preventDefault();
    // Validation and API call to update the location
    try {
      let data = {
        data: {
          formData,
        },
      };
      const response = await ApiCall.service(
        `${LOCATIONS}/${id}`,
        "PUT",
        formData,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.statusCode === 201) {
        CustomNotify({
          type: "success",
          message: t(response.message) || t(response.msg),
        });
        setTimeout(() => {
          navigate("/locations-manage");
        }, 1000);
        // }
      } else if (response.statusCode === 422 || response.statusCode === 500) {
        const errorData = response.data; // Assuming the error data comes in response.data
        let updatedErrors: Record<string, string> = {};

        // Iterate through the error object and set errors for each field
        Object.keys(errorData).forEach((field) => {
          updatedErrors[field] = errorData[field][0]; // Assign the first error message
        });

        // Update form errors state
        setFormErrors(updatedErrors);

        CustomNotify({
          type: "error",
          message: t(response.message) || t(response.msg),
        });
      }
    } catch (error) {
      console.error("Error saving locations:", error);
    }
  };

  const handleFileDeleteResponse = (deleteResponse: any, index: number) => {
    console.log(deleteResponse);
    setFileDetails({
      fileId: 0,
      fileName: "",
      filePath: "",
    });
    setFormData((prevData) => ({
      ...prevData,
      location_pic: "",
    }));
  };

  return (
    <>
      <Header
        headerName={t("Edit location")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar">
        <div className="row">
          <BackLink backLink="/locations-manage" />
        </div>
      </div>
      <div className="createpagesBg">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              {fileDetails.filePath ? (
                <img
                  src={
                    fileDetails.filePath.startsWith("https")
                      ? fileDetails.filePath
                      : `${FILE_URL}/${fileDetails.filePath}`
                  }
                  alt={t("Uploaded")}
                  style={{
                    backgroundColor: "var(--color-white)",
                    borderRadius: "50%",
                    boxShadow: "rgb(248 216 235) 0.052vw 0vw 0.521vw 0.156vw",
                  }}
                  width="50%"
                  height="50%"
                />
              ) : null}
              <FileUpload
                id="locations"
                name="location_picture"
                label="Upload"
                file_type={1}
                isMandatory={false}
                edit={false}
                fileId={fileDetails.fileId}
                fileName={fileDetails.fileName}
                filePath={fileDetails.filePath}
                multiple={false}
                uploadPath="locations"
                formats=".pdf,.docx,.doc,.jpg,.jpeg"
                maxFileSize={10 * 1024 * 1024}
                returnFileUploadErrors={(errorInfo) => {
                  // Handle file upload errors
                }}
                fileUploadResponse={handleFileUploadResponse}
                deleteResponse={handleFileDeleteResponse}
              />
            </div>
          </div>
          <div className="row">
            {/* <div className="col-6 multiSelectWrapper"> */}
            {/* <SelectWithSearch
                // key={index}
                search={true}
                options={relatedCompanies}
                value={companies}
                // onChange={handleCompaniesChange}
                onChange={(selectedCompanies) =>
                  handleInputChange(selectedCompanies, "company_id")
                }
                placeHolder={t("Select companies")}
                isMulti={true}
                className="country-select"
                isTranslate={false}
                isMandatory={true}
                fieldLabel={t("Companies")}
                title={t("Companies")}
                isDisabled={true}
              /> */}
            {/* </div> */}
            {locationinputFields.map((field, index) =>
              field.type === "select" ? (
                <div className="col-4 multiSelectWrapper">
                  <SelectWithSearch
                    key={index}
                    search={true}
                    options={countries}
                    value={selectedCountry}
                    onChange={(e) => handleInputChange(e, field.name)}
                    placeHolder={field.placeholder}
                    isMulti={false}
                    className="country-select"
                    isTranslate={false}
                    isMandatory={field.isMandatory}
                    fieldLabel={t("Country")}
                    title={t("Country")}
                    error={formErrors[field.name]}
                  />
                </div>
              ) : (
                <div className={field.columnSize}>
                  <InputTextFieldWithLabel
                    key={index}
                    type="text"
                    className="ps-2"
                    placeholder={t(field.placeholder)}
                    label={t(field.label)}
                    value={formData[field.name]}
                    name={field.name}
                    id={field.id}
                    handleChange={(e) =>
                      handleInputChange(e, field.name as keyof LocationFormData)
                    }
                    isMandatory={field.isMandatory}
                    labelClassName="form-label"
                    colClassName="col-12"
                    error={formErrors[field.name]}
                    isTop={true}
                  />
                </div>
              )
            )}
          </div>
          <span>
            {t("To which company would you like to add this location:")}
          </span>
          <div className="d-flex flex-wrap">
            <div className="d-flex align-items-center flex-column">
              <RadioField
                name="addLocation"
                value={companies?.value}
                // value=
                id={`company`}
                ischecked={true}
                disabled={true}
                handleChange={(e) => handleInputChange(e, "company_id")}
                label={
                  <div data-bs-toggle="tooltip" title={companies?.label}>
                    <span>{t(companies?.label)}</span>
                  </div>
                }
                containerClassName="d-inline-flex"
              />
              <div>
                <Image
                  src={companies?.url}
                  imageWidth="4vw"
                  imageHeight="4vw"
                  imageBorderRadius={"50%"}
                />
                <LabelField title={t(companies?.label)} />
              </div>
            </div>
            {/* {companies.map(
              (icon, index) => (
                console.log(selectedIndex, relatedCompanies),
                (
                  <div
                    key={index}
                    className={`radioButtonContainer  ${
                      selectedIndex === index ? "border-class" : ""
                    } d-inline-block me-3`}
                    onClick={() => handleRadioClick(index, icon.value)}
                  >

                  </div>
                )
              )
            )} */}
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <Button
                type="submit"
                className="searchBtn px-3 float-end"
                title={t("Update location")}
              />
            </div>
          </div>
          {/* <button type="submit" className="btn btn-primary">
            {t("Submit")}
          </button> */}
        </form>
      </div>
    </>
  );
};

export default translate(EditLocation);
