import React, { useEffect, useState } from "react";
// import { t } from "../common/atoms/translation";
import ModalPopup from "../common/atoms/ModalPopup";
import Title from "../common/atoms/Title";
import SelectedEmployeesNameCard from "./SelectedEmployeesNameCard";
import ShiftTiming from "./ShiftTiming";
import EmployeeDetailsCard from "../common/molecules/EmployeeDetailsCard";
import "./css/planning.css";
import { useDispatch } from "react-redux";
import { resetAllData, resetShow, selectCompanyId, selectEmployees, setScheduleErrors, setShow, setTab } from "../../features/planning/planningSlice";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { useSelector } from "react-redux";
import EmpployeeShiftTable from "./EmpployeeShiftTable";
import { ApiCall } from "../../services/ApiServices";
import { CREATE_PLANNING } from "../../routes/ApiEndpoints";
import { PLANNING_MICROSERVICE } from "../../Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { LOCATION_DASHBOARD } from "../../routes/RouteConstants";
import { handleValidationErrors } from "./Validations/PlanningValidations";
import PlanningEmployeeDetailsCard from "../common/molecules/PlanningEmployeeDetailsCard";
import CustomNotify from "../common/atoms/CustomNotify";

const PlanningOverview = () => {

  // State to control the visibility of the modal
  const [showModal, setShowModal] = useState(true);
  // State to track the index of the currently selected employee
  const [currentEmployeeIndex, setCurrentEmployeeIndex] = useState<number | null>(0); // Default to 0 if there's at least one employee
  // Get selected employees from Redux store
  const selectedEmployees = useSelector(selectEmployees);
  const companyId = useSelector(selectCompanyId);
  // State to store the currently active employee
  const [activeEmployee, setActiveEmployee] = useState(selectedEmployees[0]);
  // Get selected dates from Redux store
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const loc = useLocation(); // Get current path


  // Handler to close the modal and reset all data
  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(resetAllData());
    navigator(loc.pathname);
  };

  // Effect to update the active employee based on the current index
  useEffect(() => {
    currentEmployeeIndex !== null ? setActiveEmployee(selectedEmployees[currentEmployeeIndex]) : setActiveEmployee(selectedEmployees[0]);
  }, [currentEmployeeIndex]);

  const handleBackClick = () => {
    // console.log("Setting tab to tab_2"); // Debugging log
    dispatch(setTab('tab_2'));
  };

  const handlePlanningPost = async () => {
    try {
      let dataObj = {
        company_id: companyId,
        user_id: -999,
        planning: selectedEmployees
      };
      console.log(selectedEmployees);
      // Make sure to await the API call response
      // const response = await ApiCall.service(CREATE_PLANNING, "POST", dataObj, false, PLANNING_MICROSERVICE);
      const response = await ApiCall.service(CREATE_PLANNING, "POST", dataObj, false);


      // You can log or handle the response here
      if (response.status == 201) {
        CustomNotify({ type: 'success', message: t(response.message), autoClose: 2500 });
        handleCloseModal();
      } else if (response.status == 422) {
        console.log("Validation error =>", response);
        CustomNotify({ type: 'error', message: t("something went wrong."), autoClose: 2500 });
        handleValidationErrors(response, dispatch);
      }
    } catch (error) {
      // Catch and handle any errors that might occur during the API call
      console.error('Error making API call:', error);
    }
  };

  return (
    <>
      <ModalPopup
        modalTitle={t("Planning overview")}
        show={true}
        handleClose={handleCloseModal}
        modalBackgroundColor="#8571b9"
        className="planningModal planningOverviewModal"
        buttonText={t("Send all")}
        onNextClick={handlePlanningPost}
        showBackIcon={true}
        handleBack={handleBackClick}
      >
        <Title
          title={t("Overview of the entered planning") + ":"}
          className="bodyTitle colorPrimary mb-2"
        />
        <SelectedEmployeesNameCard
          currentEmployeeIndex={currentEmployeeIndex}
          onEmployeeSelect={(index: number) => setCurrentEmployeeIndex(index)}
        />
        <div className="row mb-3">
          <div className="col-3 align-self-center ">
            <PlanningEmployeeDetailsCard
              cardClassName="employeeDetailsCardPlanning colorPrimary text-break text-start d-flex align-items-center justify-content-center"
              employeeInformationClass="planningEmployeeDetails w-75"
              employeeName={activeEmployee.employee_name}
              employeePosition={activeEmployee.employee_type}
              employeeLocation={activeEmployee.location}
              employeeRole={activeEmployee.role}
              employeePC={activeEmployee.pc}
              employeeSalary={activeEmployee.salary}
              employeeImage={activeEmployee.image}
            />
          </div>
          <div className="col-9 align-self-center">
            <EmpployeeShiftTable
              onEmployeeSelected={activeEmployee}
              page={'overview'}
            />
          </div>
        </div>
      </ModalPopup>
    </>
  );
};

export default translate(PlanningOverview);
