import { Dispatch } from 'redux'; // Import Dispatch if needed for typing
import { selectDates, selectEmployees, setScheduleErrors } from '../../../features/planning/planningSlice'; // Adjust the path to your slice
import { RootState } from '../../../store'; // Import the RootState type
import { t } from '../../CentralDataMangement/translation/Translation';

interface Shifts {
  start_time: string;
  end_time: string;
  sequence: number;
}

interface Schedule {
  location_id: number;
  project_id?: number;
  cost_center?: number;
  shifts: Shifts[];
  error?: string | string[];
  warning?: string | string[];
}

interface ScheduleMap {
  [date: string]: Schedule;
}
// Helper function to compare if a time is before the current live time (now)
const isBeforeLiveTime = (date: string, time: string): boolean => {
  const now = new Date(); // Get the current time (live time)
  const shiftTime = new Date(`${date} ${time}`).getTime(); // Parse the shift time

  return shiftTime < now.getTime(); // Return true if the shift is before current live time
};
// Validate time in "HH:MM" format
export const isValidTime = (time: string): boolean =>
  /^([01]\d|2[0-3]):([0-5]\d)$/.test(time) && time.length === 5;

// Validate a shift's start and end times
export const validateShift = (shift: Shifts
): string => {
  const { start_time, end_time } = shift;
  console.log(start_time, end_time);
  // Allow shifts with empty start or end times
  if (start_time === "" && end_time === "") {
    return "Empty Timings"; // Consider empty start or end times as valid; no error message
  }

  // Check if both start and end times are valid
  if (!isValidTime(start_time)) {
    return "Invalid start time format"; // Invalid start time
  }
  if (!isValidTime(end_time)) {
    return "Invalid end time format"; // Invalid end time
  }

  // const [startHours, startMinutes] = start_time.split(":").map(Number);
  // const [endHours, endMinutes] = end_time.split(":").map(Number);

  // // Ensure the end time is later than the start time
  // if (endHours < startHours || (endHours === startHours && endMinutes <= startMinutes)) {
  //   return "End time must be later than start time"; // End time is not valid
  // }
  // // Ensure the end time is later than the start time
  // if (endHours < startHours || (endHours === startHours && endMinutes <= startMinutes)) {
  //   return "End time must be later than start time"; // End time is not valid
  // }

  return ""; // Valid shift; no error message
};
// Validation function with dispatch as a parameter
export const validatePlanningFields = (state: RootState, dispatch: Dispatch): boolean => {
  const dates = selectDates(state);
  const employees = selectEmployees(state);

  let hasErrors = false;
  let errorMessage;




  // Check each employee's schedule
  for (let employee of employees) {
    // Check if the dates array is empty
    // if (dates.length === 0) {
    //   const errorMessage = t('Validation failed: Dates array is empty.');
    //   dispatch(setScheduleErrors({ employee_id: employee.employee_id, date: '', errors: [errorMessage] }));
    //   console.log(errorMessage);
    //   hasErrors = true;
    // }
    if (!employee.schedule || Object.keys(employee.schedule).length === 0) {
      errorMessage = t('No schedule for current employee.');
      dispatch(setScheduleErrors({ employee_id: employee.employee_id, date: '', errors: [errorMessage] }));
      hasErrors = true;
    }

    for (let date in employee.schedule) {
      const shifts = employee.schedule[date]?.shifts;
      // const is_validated = validateShift(shifts);
      if (!shifts || shifts.length === 0) {
        errorMessage = t('No shifts for employee.');
        dispatch(setScheduleErrors({ employee_id: employee.employee_id, date, errors: [errorMessage] }));
        hasErrors = true;
      }
      // Sort shifts by start_time to check for overlap
      const sortedShifts = [...shifts].sort((a, b) => new Date(`${date} ${a.start_time}`).getTime() - new Date(`${date} ${b.start_time}`).getTime());

      for (let i = 0; i < sortedShifts.length; i++) {
        const shift = sortedShifts[i];

        // Check if start_time or end_time is missing
        if (!shift.start_time || !shift.end_time) {
          errorMessage = t('Empty shift times for employee.');
          dispatch(setScheduleErrors({ employee_id: employee.employee_id, date, errors: [errorMessage] }));
          hasErrors = true;
        }

        // Validate shift timing
        const validationError = validateShift(shift);
        if (validationError) {
          errorMessage = t(validationError);
          dispatch(setScheduleErrors({ employee_id: employee.employee_id, date, errors: [errorMessage] }));
          hasErrors = true;
        }

        // Check if the start time is before the current live time
        if (isBeforeLiveTime(date, shift.start_time)) {
          errorMessage = t('Shift for employee starts before the current time.');
          dispatch(setScheduleErrors({ employee_id: employee.employee_id, date, errors: [errorMessage] }));
          hasErrors = true;
        }

        // Compare shifts to detect overlap
        if (i < sortedShifts.length - 1) {
          const nextShift = sortedShifts[i + 1];
          const currentShiftEndTime = new Date(`${date} ${shift.end_time}`).getTime();
          const nextShiftStartTime = new Date(`${date} ${nextShift.start_time}`).getTime();

          if (currentShiftEndTime > nextShiftStartTime) {
            errorMessage = t('Shift overlap detected for employee.');
            dispatch(setScheduleErrors({ employee_id: employee.employee_id, date, errors: [errorMessage] }));
            hasErrors = true;
          }
        }
      }
    }
    console.log(errorMessage);

  }

  return hasErrors;
};
// Function to handle 422 validation errors from the API
export const handleValidationErrors = (response: any, dispatch: Dispatch) => {
  const errors = response.errors; // The errors from the API response

  // Loop through each error key and extract details
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      // Regular expression to match employee ID and date from the error key
      const regex = /planning\.(\d+)\.schedule\.(\d{4}-\d{2}-\d{2})/;
      const match = key.match(regex);
      console.log(match);


      if (match) {
        const employeeId = match[1]; // Extracted employee ID
        const date = match[2]; // Extracted date

        // Get the error message for the specific employee and date
        const errorMessages = errors[key];
        console.log(errorMessages);

        // Dispatch the errors to the store or handle them in the UI
        dispatch(
          setScheduleErrors({
            employee_id: parseInt(employeeId),
            date,
            errors: errorMessages,
          })
        );
      }
    }
  }
};

