import React, { ReactNode } from "react";

interface AccordionCustomProps {
  accordionTitle?: string;
  children?: ReactNode;
  accordionTitleClassName?: string;
  accordionButton?: string;
  id: string; // Added `id` to uniquely identify each accordion
  expanded: boolean; // Whether the accordion is expanded or not
  onToggle: (id: string) => void; // Function to handle the toggle action
}

const DashboardAccordion: React.FC<AccordionCustomProps> = ({
  accordionTitle,
  children,
  accordionTitleClassName,
  accordionButton,
  id,
  expanded,
  onToggle,
}) => {
  return (
    <>
      <div className="accordion" id={`accordionExample${id}`}>
        <div className="accordion-item bg-transparent border-0">
          <h2 className="accordion-header" id={`header${id}`}>
            <button
              className={` ${accordionButton} accordion-button`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${id}`}
              aria-expanded="true"
              aria-controls={`collapse${id}`}
            >
              <div className={accordionTitleClassName}>{accordionTitle}</div>
            </button>
          </h2>
          <div
            id={`collapse${id}`}
            className="accordion-collapse collapse"
            aria-labelledby={`heading${id}`}
            data-bs-parent={`#accordionExample${id}`}
          >
            <div className="accordion-body bg-white border-0">
              <div className="accordion-body">{children}</div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="accordion accordion-flush" id={`accordionFlush${id}`}>
        <div className="accordion-item bg-transparent">
          <h2 className="accordion-header" id={`flush-heading-${id}`}>
            <button
              type="button"
              aria-controls={`flush-collapse-${id}`}
            ></button>
          </h2>
          <div
            id={`flush-collapse-${id}`}
            className={`accordion-collapse collapse ${expanded ? "show" : ""}`}
            aria-labelledby={`flush-heading-${id}`}
            data-bs-parent={`#accordionFlush${id}`}
          ></div>
        </div>
      </div> */}
    </>
  );
};

export default DashboardAccordion;
