// src/components/AddCompanyModal.tsx
import React, { useEffect, useState } from "react";
import ModalPopup from "../common/atoms/ModalPopup";
import Title from "../common/atoms/Title";
import Image from "../common/atoms/Image";
import InputTextFieldWithLabel from "../common/molecules/InputTextFieldWithLabel";
import LabelField from "../common/atoms/LabelField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import userIcon from "../../static/images/User.svg";
import { t } from "../common/atoms/translation";
import Button from "../common/atoms/Button";
import InputTextfield from "../common/atoms/InputTextField";
import CheckBoxField from "../common/atoms/CheckBoxField";
import {
  CREATE_EMPLOYER,
  VAT_DETAILS,
  GET_COUNTRIES,
  SETTINGS_LIST,
  REGISTER_COUNTRY_LIST,
  REGISTER_SETTINGS_LIST,
  REGISTER_VAT_DETAILS,
  REGISTER_EMPLOYER,
  PC_LIST,
} from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import { AxiosCall } from "../../services/AxiosService";
import LoadingIcon from "../common/utlis/LoadingIcon";
import CustomNotify from "../common/atoms/CustomNotify";
import { useSelector } from "react-redux";
import { validatePassword } from "../../services/ValidationService";
import {
  AddCompanyModalProps,
  Company,
  CountriesOptionType,
  FormErrors,
  Option,
  PcOptionType,
} from "./services/CompanyInterface";
import { useDispatch } from "react-redux";
import {
  addUserDetails,
  updateOrAddCompany,
} from "../../features/auth/AuthSlice";
import { FaTrash } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const AddCompanyModal: React.FC<AddCompanyModalProps> = ({
  show,
  handleClose,
  pcValue,
  handlePcChange,
  selectedCompany,
  countries,
  pc,
  iban,
}) => {
  const getDefaultFormValues = {
    formValues: {
      vat_number: "",
      official_name: "",
      search_name: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      security: {
        password: "",
        confirm_password: "",
      },
      profile_picture_url: "",
      profile_id: null,
      profile_path: "",
      is_invoice_address: false,
      is_active: true,
      role: "employer",
      address: {
        official: {
          name: "",
          street: "",
          number: "",
          box: "",
          addition: null,
          postal_code: "",
          city: "",
          country: null,
        },
        invoice: {
          name: "",
          street: "",
          number: "",
          box: "",
          addition: null,
          postal_code: "",
          city: "",
          country: null,
        },
      },
      pc: [],
      iban: ["BE"],
      source: "",
      extrar_ref: "",
    },
    showPassword: false,
    showConfirmPassword: false,
    countryOptions: [] as CountriesOptionType[],
    pcOptions: [] as PcOptionType[],
    loading: false,
    errors: {} as FormErrors,
    ibancountries: [],
    companies: [] as Company[],
    verfiedVatnumber: "",
  };

  const [formState, setFormState] = useState(getDefaultFormValues);
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state?.auth);
  useEffect(() => {
    if (selectedCompany) {
      setFormState((prev) => ({
        ...prev,
        formValues: {
          vat_number: selectedCompany?.vat_number || "",
          official_name: selectedCompany?.official_name || "",
          search_name: selectedCompany?.search_name || "",
          first_name: selectedCompany?.first_name || "",
          last_name: selectedCompany?.last_name || "",
          email: selectedCompany?.email || "",
          phone_number: selectedCompany?.phone_number || "",
          security: {
            password: "",
            confirm_password: "",
          },
          profile_picture_url: selectedCompany?.profile_picture_url || "",
          profile_id: selectedCompany?.profile_id || null,
          profile_path: selectedCompany?.profile_path || "",
          is_invoice_address: selectedCompany?.is_invoice_address,
          is_active:
            selectedCompany?.is_active !== undefined
              ? selectedCompany.is_active
              : true,
          role: selectedCompany?.role || "employer",
          address: {
            official: {
              name: selectedCompany?.address?.official?.name || "",
              street: selectedCompany?.address?.official?.street || "",
              number: selectedCompany?.address?.official?.number || "",
              box: selectedCompany?.address?.official?.box || "",
              addition: selectedCompany?.address?.official?.addition || null,
              postal_code:
                selectedCompany?.address?.official?.postal_code || "",
              city: selectedCompany?.address?.official?.city || "",
              country: selectedCompany?.address?.official?.country || null,
            },
            invoice: {
              name: selectedCompany?.address?.invoice?.name || "",
              street: selectedCompany?.address?.invoice?.street || "",
              number: selectedCompany?.address?.invoice?.number || "",
              box: selectedCompany?.address?.invoice?.box || "",
              addition: selectedCompany?.address?.invoice?.addition || null,
              postal_code: selectedCompany?.address?.invoice?.postal_code || "",
              city: selectedCompany?.address?.invoice?.city || "",
              country: selectedCompany?.address?.invoice?.country || null,
            },
          },
          pc: selectedCompany?.pc || [],
          // iban: selectedCompany?.vat_no
          //   ? [selectedCompany.address?.official?.country]
          //   : ["BE"],
          iban: [selectedCompany?.iban_code]||["BE"],
          source: selectedCompany?.source || "",
          extrar_ref: selectedCompany?.extrar_ref || "",
        },
      }));
      setFormState((prevState) => ({
        ...prevState,
        countryOptions: countries,
        pcOptions: pc,
        ibancountries: iban,
      }));
    }
  }, [selectedCompany]);

  useEffect(() => {
    // Update the form state with new options
    setFormState((prevState) => ({
      ...prevState,
      countryOptions: countries,
      pcOptions: pc,
      ibancountries: iban,
    }));
  }, [countries, pc, iban]);

  const inputFieldSharedProps = {
    isTop: false,
    labelClassName: "label colorPrimary",
    colClassName: "col-6",
    className: "inputFieldColor ps-2",
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    const nameParts = name?.split(".");

    setFormState((prev) => {
      const updatedForm = { ...prev };
      let current: any = updatedForm?.formValues;

      // Traverse to the right nested structure
      nameParts.slice(0, -1).forEach((part) => {
        if (!current[part]) {
          current[part] = {};
        }
        current = current[part];
      });

      if (type === "checkbox") {
        current[nameParts[nameParts.length - 1]] = checked;
        // Handle specific case for is_invoice_address
        if (nameParts[nameParts.length - 1] === "is_invoice_address") {
          updatedForm.formValues.address.invoice = checked
            ? { ...prev?.formValues?.address?.official }
            : {
                name: "",
                street: "",
                number: "",
                box: "",
                addition: null,
                postal_code: "",
                city: "",
                country: null,
              };
        }
      } else {
        current[nameParts[nameParts?.length - 1]] = value;
      }
      if (user?.token) {
        updatedForm.formValues.email = user?.email;
      }
      return updatedForm;
    });
  };

  const handleSelectChange = (
    selectedOption: Option | Option[] | null,
    fieldPath: string
  ) => {
    const value = Array.isArray(selectedOption)
      ? selectedOption?.map((option) => option?.value)
      : selectedOption
      ? selectedOption?.value
      : null;

    setFormState((prevFormState) => {
      const fieldNames = fieldPath?.split(".");
      let updatedFormData: any = { ...prevFormState };

      let currentLevel: any = updatedFormData?.formValues;

      fieldNames?.slice(0, -1)?.forEach((fieldName) => {
        if (!currentLevel[fieldName]) {
          currentLevel[fieldName] = {};
        }
        currentLevel = currentLevel[fieldName];
      });

      if (fieldPath === "iban") {
        currentLevel[fieldNames[fieldNames?.length - 1]] = Array.isArray(value)
          ? value
          : value
          ? [value]
          : [];
      } else {
        currentLevel[fieldNames[fieldNames?.length - 1]] = value;
      }

      return updatedFormData;
    });
  };

  // VAT number validation
  const vatidateVAT_number = (value: string): string | null => {
    if (!value || value?.trim() === "") {
      return t("VAT number is required");
    }
    if (!/^[a-zA-Z0-9]+$/.test(value)) {
      return t("VAT number must contain only alphanumeric characters");
    }
    if (value?.length < 8) {
      return `${t("VAT number must be at least")} 8 ${t("characters long")}`;
    }
    if (value?.length > 15) {
      return `${t("VAT number must be at most")} 15 ${t("characters long")}`;
    }
    return null;
  };

  const handleVerifyVat = async () => {
    const vatError = vatidateVAT_number(formState?.formValues?.vat_number);

    if (vatError) {
      setFormState((prev) => ({
        ...prev,
        errors: { ...prev.errors, vat_number: vatError },
        formValues: {
          ...prev.formValues,
          vat_number: prev?.formValues?.vat_number,
        },
      }));
      return;
    } else {
      setFormState((prev) => ({
        ...prev,
        errors: { ...prev.errors, vat_number: "" },
      }));
    }

    // VAT verification API call
    try {
      let url;

      const vat_number: string = `${formState?.formValues?.iban[0]}${formState?.formValues?.vat_number}`;
      if (user) {
        url = `${REGISTER_VAT_DETAILS}/${vat_number}`;
      } else {
        url = `${VAT_DETAILS}/${vat_number}`;
      }

      const response = await ApiCall.getService(
        url,
        "GET",
        "central-data-management",
        false
      );

      // Check if the response is valid
      if (response?.status === 200) {
        setFormState((prev: any) => {
          // Find the matching country value based on the country name
          const matchedCountry = prev?.countryOptions?.find(
            (country: CountriesOptionType) =>
              country?.label === response?.data?.country
          );
          return {
            ...prev,
            verfiedVatnumber: `${formState?.formValues?.iban[0]}${response?.data?.vatnumber}`,
            formValues: {
              ...prev.formValues,
              vat_number: response?.data?.vatnumber || "",
              official_name: response?.data?.official_name || "",
              search_name: response?.data?.name || "",
              first_name: prev?.formValues?.first_name,
              last_name: prev?.formValues?.last_name,
              email: response?.data?.mail || "",
              phone_number: response?.data?.phone || "",

              // Update address fields inside the official object
              address: {
                ...prev.formValues?.address,
                official: {
                  ...prev?.formValues?.address?.official,
                  name: response?.data?.name || "",
                  street: response?.data?.street || "",
                  number: response?.data?.street_nr || "",
                  box: response?.data?.bus || "",
                  postal_code: response?.data?.postal_code || "",
                  city: response?.data?.city || "",
                  country: matchedCountry ? matchedCountry?.value : null,
                  addition: prev?.formValues?.address?.official?.addition,
                },
                invoice: {
                  ...prev.formValues?.address?.invoice,
                  name: prev?.formValues?.address?.invoice?.name,
                  street: prev?.formValues?.address?.invoice?.street,
                  number: prev?.formValues?.address?.invoice?.number,
                  box: prev?.formValues?.address?.invoice?.box,
                  addition: prev?.formValues?.address?.invoice?.addition,
                  postal_code: prev?.formValues?.address?.invoice.postal_code,
                  city: prev?.formValues?.address?.invoice?.city,
                  country: prev?.formValues?.address?.invoice?.country,
                },
              },
              iban: [response?.data?.vatcountry]||["BE"],
              profile_picture_url: prev?.formValues?.profile_picture_url,
              profile_id: prev?.formValues?.profile_id,
              profile_path: prev?.formValues?.profile_path,
              is_invoice_address: prev?.formValues?.is_invoice_address,
              is_active: prev?.formValues?.is_active,
              role: prev?.formValues?.role,
              pc: prev?.formValues?.pc,
              source: prev?.formValues?.source,
              extrar_ref: prev?.formValues?.extrar_ref,
            },
          };
        });
      } else {
        setFormState((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            vat_number: response?.message || "VAT verification failed.",
          },
        }));
      }
    } catch (error: any) {
      console.error(error);
      if (error.response?.status === 422) {
        // Handle validation errors for status code 422
        setFormState((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            vat_number: error.response?.message || "Validation error.",
          },
          formValues: {
            ...prev.formValues,
            vat_number: prev?.formValues?.vat_number,
          },
        }));
      } else {
        setFormState((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            vat_number: "Failed to verify VAT number.",
          },
          formValues: {
            ...getDefaultFormValues?.formValues,
            vat_number: prev?.formValues?.vat_number,
          },
        }));
      }
    }
  };

  const formValidation = (formState: {
    formValues: any;
    user?: { token?: string };
  }): FormErrors => {
    const errors: any = {};

    const fields: any = {
      string: [
        "vat_number",
        "official_name",
        "search_name",
        "first_name",
        "last_name",
      ],
      number: ["phone_number"],
      address: ["street", "city", "postal_code", "number", "country"],
    };

    // Generic field validator function for strings and numbers
    const validateFields = (
      fieldNames: (keyof FormErrors)[],
      type: "string" | "number"
    ) => {
      fieldNames.forEach((field) => {
        let value = formState?.formValues[field];

        if (type === "number" && field === "phone_number") {
          const trimmedValue = value?.trim();

          if (!trimmedValue || trimmedValue === "" || trimmedValue === null) {
            errors[field] = `This field is required.`;
          } else if (!/^[\d+/. ]{6,15}$/.test(trimmedValue)) {
            errors[field] = `Please enter valid input`;
          }
        } else if (!value || value === "" || value === null) {
          errors[field] = `This field is required.`;
        }
      });
    };

    // Case-insensitive email validation
    const validateEmail = (email: string) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;
      if (!email || email === "") {
        errors.email = `This field is required.`;
      } else if (!emailRegex.test(email)) {
        errors.email = `Incorrect Format`;
      }
    };

    // Address validation function for official and invoice addresses
    const validateAddress = (
      address: Record<string, any>,
      addressType: "official" | "invoice"
    ) => {
      if (!errors?.address) {
        errors.address = {};
      }
      if (!errors.address[addressType]) {
        errors.address[addressType] = {};
      }

      // Validate country separately since it's not part of the main fields array
      if (
        addressType === "official" &&
        (!address?.country ||
          address?.country === "" ||
          address?.country === "Select")
      ) {
        errors.address[addressType].country = `This field is required.`;
      } else if (
        !address.country ||
        address.country === "" ||
        address?.country === "Select"
      ) {
        errors.address[addressType].country = `This field is required.`;
      }

      // Validate each field in the address (e.g., street, city, number, postal code)
      fields.address.forEach((field: any) => {
        const value = address[field];
        if (!value || value === "" || value === null) {
          errors.address[addressType][field] = `This field is required.`;
        }
      });
    };

    const validatePc = (pcValues: string[]) => {
      if (!pcValues || pcValues.length === 0) {
        errors.pc = `This field is required.`;
      }
    };
    validateFields(fields.string, "string");
    validateFields(fields.number, "number");

    if (!user.token) {
      validateEmail(formState?.formValues?.email);

      const passwordError = validatePassword(
        formState?.formValues?.security?.password
      );
      if (passwordError) {
        errors.security = { ...errors.security, password: passwordError };
      }
      const confirmPasswordError = validatePassword(
        formState?.formValues?.security?.confirm_password
      );
      if (confirmPasswordError) {
        errors.security = {
          ...errors.security,
          confirm_password: confirmPasswordError,
        };
      }
      if (
        formState?.formValues?.security?.confirm_password !==
        formState?.formValues?.security.password
      ) {
        if (!errors?.security) {
          errors.security = {};
        }
        errors.security.confirm_password = `The two password field didn't match`;
      }
    }

    validateAddress(formState?.formValues?.address?.official, "official");
    validatePc(formState?.formValues?.pc);

    if (formState?.formValues?.is_invoice_address) {
      validateAddress(formState?.formValues?.address?.invoice || {}, "invoice");
    }

    return errors;
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event?.target?.files && event?.target?.files[0];
    const allowedFormats = [".jpg", ".jpeg", ".png"];
    let errorString = "";

    if (file) {
      const fileExtension = `.${file?.name?.split(".").pop()?.toLowerCase()}`;

      // Check for allowed formats
      if (!allowedFormats.includes(fileExtension)) {
        errorString = `${t("Invalid file format")}, ${t(
          "please upload only"
        )} ${allowedFormats.join(", ")} ${t("file format")}`;
        console.error(errorString);
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, file: errorString },
        }));
        return; // Prevent further execution if file format is invalid
      }

      // Check file size
      if (file.size > 2 * 1024 * 1024) {
        // File size validation: greater than 2MB
        errorString = t("File size is greater than 2MB");
        console.error(errorString);
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, file: errorString },
        }));
        return;
      }

      setFormState((prev) => ({ ...prev, loading: true }));
      await uploadFile(file);
      setFormState((prev) => ({ ...prev, loading: false }));
    }
  };

  const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_type", "10");
    formData.append("name", "company_profile_picture");

    try {
      const response = await AxiosCall.fileUploadService(formData, "Employer");
      setFormState((prevValues) => ({
        ...prevValues,
        formValues: {
          ...prevValues?.formValues,
          profile_picture_url: response?.data["fileUrl"],
          profile_id: response?.data["fileId"],
        },
        errors: { ...prevValues?.errors, file: "" },
      }));
    } catch (error) {
      console.error("File upload failed", error);
      setFormState((prev) => ({
        ...prev,
        errors: { ...prev?.errors, file: t("File upload failed.") },
      }));
    }
  };

  const handleDeleteImage = () => {
    setFormState((prevValues: any) => ({
      ...prevValues,
      formValues: {
        ...prevValues?.formValues,
        profile_picture_url: "",
        profile_id: "",
      },
      errors: { ...prevValues?.errors, file: "" },
    }));

    // Reset the file input to allow re-uploading the same image
    const fileInput = document.getElementById(
      "profile-picture-input"
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleSubmit = async () => {
    // Perform general form validation
    let errors = formValidation(formState);

    // Only check VAT number verification if it has been edited
    if (selectedCompany?.vat_number !== formState?.formValues?.vat_number) {
      if (
        formState?.verfiedVatnumber !==
        `${formState?.formValues?.iban[0]}${formState?.formValues?.vat_number}`
      ) {
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, vat_number: "Please verify VAT number" },
          formValues: {
            ...prev.formValues,
            vat_number: prev?.formValues?.vat_number,
          },
        }));
        return;
      }
    }
    // Other field validations
    if (
      Object.keys(errors).length > 1 &&
      errors?.address &&
      Object.keys(errors?.address).length > 0 &&
      (errors.address.official === undefined || {}) &&
      (errors.address.invoice === undefined || {})
    ) {
      setFormState((prev) => ({
        ...prev,
        errors,
      }));
      return;
    }

    try {
      let response;

      // Determine the request method based on the selected company
      if (user?.token) {
        response = selectedCompany?.id
          ? await ApiCall.service(
              `${CREATE_EMPLOYER}/${selectedCompany?.id}`,
              "PUT",
              formState?.formValues
            )
          : await ApiCall.service(
              CREATE_EMPLOYER,
              "POST",
              formState?.formValues
            );
      } else {
        response = await ApiCall.service(
          REGISTER_EMPLOYER,
          "POST",
          formState?.formValues
        );
      }

      // Handle response based on status
      if (response?.status === 200) {
        const newCompany = {
          id: response?.data?.id,
          name: response?.data?.official_name,
          search_name: response?.data?.search_name,
          profile_picture_url: response?.data?.profile_picture_url,
          locations: [],
        };

        // Dispatch the new company to Redux state
        dispatch(updateOrAddCompany(newCompany));

        // Update form state with default values
        setFormState((prevState) => ({
          ...prevState,
          formValues: getDefaultFormValues?.formValues,
          pc: [],
          errors: {},
        }));
        CustomNotify({ type: "success", message: response?.message });
        handleClose();
      } else if (response?.status === 422) {
        const errorMessages = response?.errors;
        const formattedErrorMessages = Object.values(errorMessages)
          .flat()
          .join(", ");
        setFormState((prevState) => ({
          ...prevState,
          errors: errorMessages,
        }));
        CustomNotify({ type: "error", message: formattedErrorMessages });
      } else {
        throw new Error(response?.message);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleShowPasswordToggle = () => {
    setFormState((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  const handleShowConfirmPasswordToggle = () => {
    setFormState((prevState) => ({
      ...prevState,
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  };
  return (
    <ModalPopup
      show={show}
      handleClose={handleClose}
      modalTitle={t("Register company")}
      modalBackgroundColor="#8571b9"
      className="addCompanyModal"
      buttonText={selectedCompany?.id ? "Update" : "Add"}
      onNextClick={handleSubmit}
    >
      <div className="companyData">
        <div className="row">
          <div className="col-12">
            <Title
              title={t("Company profile") + ":"}
              className="bodyTitle colorPrimary"
            />
            <div className="my-3 position-relative">
              <div
                className={`addCompanyImage d-flex align-items-center justify-content-center ${
                  formState?.formValues?.profile_picture_url
                    ? "userIconPresent"
                    : "p-2"
                }`}
                onClick={() =>
                  document.getElementById("profile-picture-input")?.click()
                }
                style={{ cursor: "pointer" }}
              >
                {formState.loading ? (
                  <LoadingIcon
                    iconType="bars"
                    color="#8571b9"
                    className="fileUploadLoading"
                  />
                ) : (
                  <>
                    <Image
                      src={
                        formState?.formValues?.profile_picture_url || userIcon
                      }
                      imageWidth="100%"
                      imageHeight="100%"
                      imageBorderRadius="50%"
                    />
                  </>
                )}
              </div>

              <InputTextfield
                type="file"
                id="profile-picture-input"
                name="company_profile_picture"
                customStyle={{ display: "none" }}
                handleChange={handleFileChange}
                autoComplete="off"
              />
              <div>
                {formState?.formValues?.profile_picture_url && (
                  <div
                    onClick={handleDeleteImage}
                    className="delete-icon position-absolute p-1"
                    role="button"
                    data-bs-toggle="tooltip"
                    title={t("Delete Profile")}
                  >
                    <FaTrash />
                  </div>
                )}
              </div>
              {formState?.errors?.file && (
                <p className="text-danger mt-2">{formState?.errors?.file}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row mb-2">
                <div className="col-3 align-self-center">
                  <LabelField
                    title={t("VAT number") + ":"}
                    className="label colorPrimary"
                    isMandatory
                  />
                </div>
                <div className="col-6">
                  <div className="row d-flex">
                    <div className="col-4 multiSelectWrapper">
                      <SelectWithSearch
                        search
                        options={formState?.ibancountries}
                        value={formState?.formValues?.iban?.map((ibanValue: string) => ({
                          label: ibanValue||"BE",
                        }))}
                        onChange={(selectedOptions: Option[]) =>
                          handleSelectChange(selectedOptions, "iban")
                        }
                        isMulti={false}
                        error=""
                        id="selectField"
                        name="selectField"
                        selectPrefix="vatNumber"
                        isDisabled={false}
                      />
                    </div>
                    <div className="col-8 ps-0">
                      <InputTextfield
                        type="text"
                        value={formState?.formValues?.vat_number}
                        handleChange={handleChange}
                        name={"vat_number"}
                        error={formState?.errors?.vat_number}
                        id="vat_number"
                        autoComplete="off"
                        {...inputFieldSharedProps}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <Button
                    handleClick={handleVerifyVat}
                    title={t("Verify")}
                    className="modalSaveBtn"
                  />
                </div>
              </div>

              <div className="row mb-2">
                <InputTextFieldWithLabel
                  label={t("Company name") + ":"}
                  type="text"
                  value={formState?.formValues?.search_name}
                  handleChange={handleChange}
                  name={"search_name"}
                  error={formState?.errors?.search_name}
                  id="search_name"
                  autoComplete="off"
                  {...inputFieldSharedProps}
                  isMandatory
                />
              </div>
              <div className="row mb-2">
                <InputTextFieldWithLabel
                  label={t("Official name") + ":"}
                  type="text"
                  value={formState?.formValues?.official_name}
                  handleChange={handleChange}
                  name={"official_name"}
                  error={formState?.errors?.official_name}
                  id="official_name"
                  {...inputFieldSharedProps}
                  isMandatory
                  autoComplete="off"
                />
              </div>
              <div className="row mb-2">
                <InputTextFieldWithLabel
                  label={t("First name") + ":"}
                  type="text"
                  value={formState?.formValues?.first_name}
                  handleChange={handleChange}
                  name={"first_name"}
                  error={formState?.errors?.first_name}
                  id="first_name"
                  {...inputFieldSharedProps}
                  isMandatory
                  autoComplete="off"
                />
              </div>
              <div className="row mb-2">
                <InputTextFieldWithLabel
                  label={t("Last name") + ":"}
                  type="text"
                  value={formState?.formValues?.last_name}
                  handleChange={handleChange}
                  name={"last_name"}
                  error={formState?.errors?.last_name}
                  id="last_name"
                  {...inputFieldSharedProps}
                  isMandatory
                  autoComplete="off"
                />
              </div>
              <div className="row mb-2">
                <InputTextFieldWithLabel
                  label={t("Email address") + ":"}
                  type="email"
                  value={
                    user?.token ? user?.email : formState?.formValues?.email
                  }
                  handleChange={handleChange}
                  error={formState?.errors?.email}
                  name={"email"}
                  id="email"
                  {...inputFieldSharedProps}
                  isMandatory
                  autoComplete="off"
                  isDisabled={user.token ? true : false}
                />
              </div>
              <div className="row mb-2">
                <InputTextFieldWithLabel
                  label={t("Phone number") + ":"}
                  type="text"
                  value={formState?.formValues?.phone_number}
                  handleChange={handleChange}
                  error={formState?.errors?.phone_number}
                  name={"phone_number"}
                  id="phone_number"
                  {...inputFieldSharedProps}
                  isMandatory
                  autoComplete="off"
                />
              </div>
              <div className="row mb-2">
                <div className="col-3 align-self-center">
                  <LabelField
                    title={t("Password") + ":"}
                    className="label colorPrimary"
                    isMandatory
                  />
                </div>
                <div className="col-8 position-relative ">
                  <div className="row d-flex">
                    <div className="col-9 position-relative">
                      <InputTextfield
                        value={formState?.formValues?.security?.password}
                        type={formState?.showPassword ? "text" : "password"}
                        handleChange={handleChange}
                        error={formState?.errors?.security?.password}
                        name={"security.password"}
                        id="security.password"
                        {...inputFieldSharedProps}
                        autoComplete="off"
                        isDisabled={user.token ? true : false}
                        className="pr-5"
                      />
                      <Button
                        type="button"
                        handleClick={handleShowPasswordToggle}
                        className="btn position-absolute password shadow-none border-0 secondaryColorHoverEffect"
                        style={{
                          right: "1vw",
                          top: "50%",
                          transform: "translateY(-50%)",
                          color: "var(--color-primary)",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={formState?.showPassword ? faEye : faEyeSlash}
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-3 align-self-center">
                  <LabelField
                    title={t("Confirm password") + ":"}
                    className="label colorPrimary"
                    isMandatory
                  />
                </div>
                <div className="col-8 position-relative">
                  <div className="row d-flex">
                    <div className="col-9 position-relative">
                      <InputTextfield
                        value={
                          formState?.formValues?.security?.confirm_password
                        }
                        type={
                          formState?.showConfirmPassword ? "text" : "password"
                        }
                        handleChange={handleChange}
                        error={formState?.errors?.security?.confirm_password}
                        name={"security.confirm_password"}
                        id="security.confirm_password"
                        {...inputFieldSharedProps}
                        autoComplete="off"
                        isDisabled={user.token ? true : false}
                        className="pr-5"
                      />
                      <Button
                        type="button"
                        handleClick={handleShowConfirmPasswordToggle}
                        className="btn position-absolute password shadow-none border-0 secondaryColorHoverEffect"
                        style={{
                          right: "1vw",
                          top: "50%",
                          transform: "translateY(-50%)",
                          color: "var(--color-primary)",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={
                            formState?.showConfirmPassword ? faEye : faEyeSlash
                          }
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <LabelField
                title={t("Address registered office") + ":"}
                className="bodyTitle colorPrimary my-3"
              />
            </div>
            <div className="row mb-2">
              <InputTextFieldWithLabel
                label={t("Street name") + ":"}
                type="text"
                autoComplete="off"
                value={formState?.formValues?.address?.official?.street}
                handleChange={handleChange}
                name="address.official.street"
                id="street"
                error={formState?.errors?.address?.official?.street}
                {...inputFieldSharedProps}
                isMandatory
              />
            </div>
            <div className="row mb-2">
              <InputTextFieldWithLabel
                label={t("House no") + ":"}
                type="text"
                value={formState?.formValues?.address?.official?.number}
                handleChange={handleChange}
                name={"address.official.number"}
                autoComplete="off"
                error={formState?.errors?.address?.official?.number}
                id="house_no"
                {...inputFieldSharedProps}
                isMandatory
              />
            </div>
            <div className="row mb-2">
              <InputTextFieldWithLabel
                label={t("Postal code") + ":"}
                type="text"
                value={formState?.formValues?.address?.official?.postal_code}
                handleChange={handleChange}
                autoComplete="off"
                error={formState?.errors?.address?.official?.postal_code}
                name={"address.official.postal_code"}
                id="postal_code"
                {...inputFieldSharedProps}
                isMandatory
              />
            </div>
            <div className="row mb-2 ">
              <InputTextFieldWithLabel
                label={t("City") + ":"}
                type="text"
                value={formState?.formValues?.address?.official?.city}
                handleChange={handleChange}
                autoComplete="off"
                name={"address.official.city"}
                error={formState?.errors?.address?.official?.city}
                id="city"
                {...inputFieldSharedProps}
                isMandatory
              />
            </div>
            <div className="row mb-2 multiSelectWrapper">
              <SelectWithSearch
                fieldLabel={`${t("Country")}: `}
                title={t("Country") + ":"}
                name="address.official.country"
                placeHolder={t("Select")}
                search
                isMandatory
                options={formState?.countryOptions}
                value={
                  formState?.countryOptions?.find(
                    (option: any) =>
                      option.value ===
                      formState?.formValues?.address?.official?.country
                  ) || null
                }
                onChange={(selectedOption: Option) =>
                  handleSelectChange(selectedOption, "address.official.country")
                }
                isMulti={false}
                className="select-field"
                error={formState?.errors?.address?.official?.country}
                isTranslate
                labelWrapperClassName="col-3 align-self-center"
                colClassName="col-6"
              />
            </div>

            <div className="row">
              <LabelField
                title={
                  t(
                    "Is the employment address the same as the registered office address?"
                  ) + "?"
                }
                className="bodyTitle colorPrimary mt-3"
              />
              <div className="d-inline-block">
                <CheckBoxField
                  name="is_invoice_address"
                  label={t("Yes")}
                  className="my-checkbox"
                  isChecked={formState?.formValues?.is_invoice_address}
                  onChangeHandler={handleChange}
                  id="checkboxExternal"
                  lineHeight="25px"
                />
              </div>
            </div>
            <div className="row">
              <LabelField
                title={t("Employement address") + ":"}
                className="bodyTitle colorPrimary my-3"
              />
            </div>
            <div className="row mb-2">
              <InputTextFieldWithLabel
                label={t("Street name") + ":"}
                type="text"
                value={
                  formState?.formValues?.is_invoice_address
                    ? formState?.formValues?.address?.official?.street
                    : formState?.formValues?.address.invoice?.street
                }
                handleChange={handleChange}
                error={formState?.errors?.address?.invoice?.street}
                name={"address.invoice.street"}
                id="employmentStreetName"
                autoComplete="off"
                {...inputFieldSharedProps}
              />
            </div>
            <div className="row mb-2">
              <InputTextFieldWithLabel
                label={t("House no") + ":"}
                type="text"
                value={
                  formState.formValues.is_invoice_address
                    ? formState.formValues.address?.official?.number
                    : formState?.formValues?.address?.invoice.number
                }
                handleChange={handleChange}
                error={formState?.errors?.address?.invoice?.number}
                name={"address.invoice.number"}
                id="employmentHouseNo"
                autoComplete="off"
                {...inputFieldSharedProps}
              />
            </div>
            <div className="row mb-2">
              <InputTextFieldWithLabel
                label={t("Postal code") + ":"}
                type="text"
                value={
                  formState?.formValues?.is_invoice_address
                    ? formState?.formValues?.address?.official?.postal_code
                    : formState?.formValues?.address?.invoice?.postal_code
                }
                handleChange={handleChange}
                error={formState?.errors?.address?.invoice?.postal_code}
                name={"address.invoice.postal_code"}
                id="employmentPostalCode"
                autoComplete="off"
                {...inputFieldSharedProps}
              />
            </div>
            <div className="row mb-2 multiSelectWrapper">
              <InputTextFieldWithLabel
                label={t("City") + ":"}
                type="text"
                value={
                  formState?.formValues?.is_invoice_address
                    ? formState.formValues.address?.official?.city
                    : formState?.formValues?.address?.invoice?.city
                }
                handleChange={handleChange}
                name={"address.invoice.city"}
                id="employmentCity"
                autoComplete="off"
                error={formState?.errors?.address?.invoice?.city}
                {...inputFieldSharedProps}
              />
            </div>
            <div className="row mb-2 multiSelectWrapper">
              <SelectWithSearch
                fieldLabel={`${t("Country")}: `}
                title={t("Country") + ":"}
                name="address.invoice.country"
                placeHolder={t("Select")}
                search
                options={formState.countryOptions}
                value={
                  formState.formValues.is_invoice_address
                    ? formState?.countryOptions?.find(
                        (option: any) =>
                          option.value ===
                          formState?.formValues?.address?.official?.country
                      ) || null
                    : formState?.countryOptions?.find(
                        (option: any) =>
                          option.value ===
                          formState?.formValues?.address?.invoice?.country
                      ) || null
                }
                onChange={(selectedOption: Option) =>
                  handleSelectChange(selectedOption, "address.invoice.country")
                }
                isMulti={false}
                error={formState?.errors?.address?.invoice?.country}
                className="select-field"
                isTranslate
                labelWrapperClassName="col-3 align-self-center"
                colClassName="col-6"
              />
            </div>
            <div className="row">
              <LabelField
                title={t("Sector") + ":"}
                className="bodyTitle colorPrimary my-3"
              />
            </div>
            <div className="row multiSelectWrapper">
              <SelectWithSearch
                fieldLabel={`${t("Paritair committee")}: `}
                title={t("Paritair committee") + ":"}
                name="pc"
                placeHolder={t("Select")}
                search
                options={formState.pcOptions}
                value={formState?.formValues?.pc?.map((pcValue: string) =>
                  formState?.pcOptions?.find(
                    (option: PcOptionType) => option?.value === pcValue
                  )
                )}
                onChange={(selectedOptions: Option[]) =>
                  handleSelectChange(selectedOptions, "pc")
                }
                isMulti={true}
                className="select-field"
                error={formState?.errors?.pc}
                isMandatory
                isTranslate
                labelWrapperClassName="col-3 align-self-center"
                colClassName="col-6"
              />
            </div>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};

export default AddCompanyModal;
