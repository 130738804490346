import React, { useState, useEffect, useMemo, useCallback } from "react";
import ModalPopup from "../../common/atoms/ModalPopup";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { t } from "../../common/atoms/translation";
import { translate } from "../translation/Translation";
import { ApiCall } from "../../../services/ApiServices";
import { EMPLOYEE_TYPES } from "../../../routes/ApiEndpoints";
import Header from "../../common/layout/Header";
import Search from "../../common/atoms/Search";
import Button from "../../common/atoms/Button";
import BackLink from "../../common/atoms/BackLink";
import ResetBtn from "../../common/atoms/ResetBtn";
import SearchBtn from "../../common/atoms/SearchBtn";
import Icon from "../../../Icon";
import "./css/manageEmployeeType.css";
import Pagination from "../../common/atoms/Pagination";
import OverFlowWrapper from "../../common/OverFlowWrapper";

const PER_PAGE = 8;

function EmployeeTypeList({ employeeTypes = [], headers }) {
  const [filteredEmployeeTypes, setFilteredEmployeeTypes] = useState(employeeTypes);
  const [searchParams, setSearchParams] = useState({ name: "" });
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);


  const navigate = useNavigate();

  useEffect(() => {
    setFilteredEmployeeTypes(employeeTypes);
  }, [employeeTypes]);

  const handleSearch = () => {
    const filteredResults = employeeTypes.filter((employee) =>
      employee.name.toLowerCase().includes(searchParams.name.trim().toLowerCase())
    );
    setFilteredEmployeeTypes(filteredResults);
    setCurrentPage(0); // Reset to first page
  };
  const handleReset = () => {
    setFilteredEmployeeTypes(employeeTypes);
    setSearchParams({ name: "" });
    setCurrentPage(0); // Reset to first page
  };

  const handleInputChange = (key) => (e) => {
    setSearchParams((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber - 1); // Adjust for zero-based index
  };

  const paginatedEmployeeTypes = useMemo(() => {
    const offset = currentPage * PER_PAGE;
    return filteredEmployeeTypes.slice(offset, offset + PER_PAGE);
  }, [currentPage, filteredEmployeeTypes]);

  const pageCount = useMemo(() => Math.ceil(filteredEmployeeTypes.length / PER_PAGE), [
    filteredEmployeeTypes.length,
  ]);

  const handleActionClick = (action, id) => {
    if (action === "delete") {
      setSelectedRow(id);
      setShowModal(true);
    } else if (action === "edit") {
      navigate("/manage/employee-types/edit/" + id);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleSaveChanges = async () => {
    if (selectedRow) {
      await handleDelete(selectedRow);
    }
    setShowModal(false);
  };

  const handleDelete = async (id) => {
    try {
      const response = await ApiCall.service(
        EMPLOYEE_TYPES + "/" + id,
        "DELETE",
        {},
        false,
        "central-data-management"
      );
      if (response.success) {
        window.location.reload();
      } else {
        console.error("Failed to delete the employee type.");
      }
    } catch (error) {
      console.error("An error occurred while deleting the employee type:", error);
    }
  };

  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
  };


  const renderActions = (id) => (
    <>
      <span
        onClick={() => handleActionClick("edit", id)}
        title={t("Edit")}
        className="secondaryColorHoverEffect me-2"
      >
        <Icon {...IconProps} name="EditIcon" />
      </span>
      <span
        onClick={() => handleActionClick("delete", id)}
        title={t("Delete")}
        className="secondaryColorHoverEffect"
      >
        <Icon {...IconProps} name="delete" />
      </span>
    </>
  );



  return (
    <>
      <Header
        headerName={t("Manage employee types")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar">
        <div className="row">
          <BackLink backLink="/config/settings" />
          <div className="col-6 text-end mb-3">
            <Button
              handleClick={() => navigate("/manage/employee-types/add")}
              title={"+ " + t("Add employee type")}
              className="searchBtn px-3"
            />
          </div>
        </div>
        <div className="row pb-4">
          <div className="col-8">
            <Search
              value={searchParams.name}
              placeholder={t("Search by name")}
              handleChange={handleInputChange("name")}
            />
          </div>
          <div className="col-4">
            <div className="d-flex align-items-center">
              <ResetBtn handleResetClick={handleReset} />
              <SearchBtn handleSearchClick={handleSearch} />
            </div>
          </div>
        </div>
      </div>
      <div className="managepagesBg">
        <OverFlowWrapper className="table-responsive manageEmployeeTypes height-25">
          <table className="table table-hover customTableProperties ">
            <thead>
              <tr className="TableHeader">
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
              <tr>
                <th
                  className="emptyRow p-0"
                  style={{ height: "0.7vw" }}
                  colSpan={3}
                ></th>
              </tr>
            </thead>
            <tbody>
              {/* {state.currentItems.length > 0 ? (
                state.currentItems.map((row) => (
                  <tr key={row.id} className="border">
                    <td>{row.name}</td>
                    <td>{renderActions(row.id)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="border text-danger text-center">
                    {t("No records")}
                  </td>
                </tr>
              )} */}

              {paginatedEmployeeTypes.length > 0 ? (
                paginatedEmployeeTypes.map((row) => (
                  <tr key={row.id} className="border">
                    <td>{row.name}</td>
                    <td>{renderActions(row.id)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={2} className="border text-danger text-center">
                    {t("No records found")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </OverFlowWrapper>
        {pageCount > 1 && (
          <>
            <div className="pagination justify-content-center align-items-center mt-4 px-4">
              <Pagination
                currentPage={currentPage + 1}
                totalPages={pageCount}
                onPageChange={handlePageClick}
              />
            </div>
          </>
        )}
        <ModalPopup
          show={showModal}
          handleClose={handleModalClose}
          modalTitle={t("Delete confirmation")}
          modalBackgroundColor="#8571b9"
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        >
          {t("Are you sure you want to delete") + "?"}
        </ModalPopup>
      </div>
    </>
  );
}

export default translate(EmployeeTypeList);
