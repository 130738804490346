import React, { ChangeEvent } from "react";
import LabelField from "../atoms/LabelField";
import SelectWithSearch from "../atoms/SelectWithSearch";
import Title from "../atoms/Title";
import ToggleButton from "../atoms/ToggleButton";
import { t } from "../atoms/translation";
import { LabelWithInputField } from "./LabelWithInputField";
import {
  EmployeeFormData,
  Countries,
  OptionsData,
  HandleChange,
} from "../../EmployeeRegistration/Registration";

interface Option {
  value: string | number;
  label: string;
}
interface AddressProps {
  formData: EmployeeFormData;
  handleChange: HandleChange;
  handleToggle: () => void;
  countryOptions: Countries;
  optionsData: OptionsData;
  handleSelectChange: (selectedOption: Option, fieldPath: string) => void;
}
const Address: React.FC<AddressProps> = ({
  formData,
  handleChange,
  handleToggle,
  countryOptions,
  optionsData,
  handleSelectChange,
}) => {
  const inputFieldSharedProps = {
    isTop: false,
    labelClassName: "poppins-medium colorPrimary ",
  };
  return (
    <>
      <div className="row mb-1">
        <div className="col-12">
          <Title
            title={t("Address") + ":"}
            className="bodyTitle colorPrimary tab-title"
          />
        </div>
      </div>
      <div className="row">
        {/* Residential address */}
        <Title
          title={t("Residential address") + ":"}
          className="bodyTitle colorPrimary tab-title mb-3"
        />
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("Street name") + ":"}
              type="text"
              value={formData.address.residence.street}
              handleChange={handleChange("address", "residence", "street")}
              name={"residence_streetName"}
              id="residence_streetName"
              {...inputFieldSharedProps}
              className="inputFieldColor"
              colClassName="col-8"
              labelWrapperClassName="col-4 align-self-center"
              isMandatory
              error={formData.errors["address.residence.street"] || ""}
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("House no") + ":"}
              type="text"
              value={formData.address.residence.number}
              handleChange={handleChange("address", "residence", "number")}
              name={"residence_houseNo"}
              id="residence_houseNo"
              className="inputFieldColor"
              colClassName="col-8"
              isMandatory
              error={formData.errors["address.residence.number"] || ""}
              labelWrapperClassName="col-4 align-self-center"
              {...inputFieldSharedProps}
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("Box") + ":"}
              type="text"
              value={formData.address.residence.box}
              handleChange={handleChange("address", "residence", "box")}
              name={"residence_box"}
              id="residence_box"
              className="inputFieldColor"
              colClassName="col-8"
              error={formData.errors["address.residence.box"] || ""}
              labelWrapperClassName="col-4 align-self-center"
              {...inputFieldSharedProps}
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("Postal code") + ":"}
              type="text"
              value={formData.address.residence.postal_code}
              handleChange={handleChange("address", "residence", "postal_code")}
              name={"residence_postalCode"}
              id="residence_postalCode"
              className="inputFieldColor"
              colClassName="col-8"
              isMandatory
              error={formData.errors["address.residence.postal_code"] || ""}
              labelWrapperClassName="col-4 align-self-center"
              {...inputFieldSharedProps}
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("City") + ":"}
              type="text"
              value={formData.address.residence.city}
              handleChange={handleChange("address", "residence", "city")}
              name={"residence_city"}
              id="residence_city"
              isMandatory={true}
              error={formData.errors["address.residence.city"] || ""}
              className="inputFieldColor"
              colClassName="col-8"
              labelWrapperClassName="col-4 align-self-center"
              {...inputFieldSharedProps}
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row multiSelectWrapper">
            <SelectWithSearch
              fieldLabel={`${t("Country")}:`}
              title={t("Country")}
              name="residence_country"
              placeHolder={t("Select")}
              isMandatory
              search
              options={countryOptions.countries}
              value={
                countryOptions.countries.find(
                  (option) =>
                    option.value === formData.address.residence.country
                ) || null
              }
              onChange={(selectedOption: Option) =>
                handleSelectChange(selectedOption, "address.residence.country")
              }
              isMulti={false}
              className="select-field"
              error={formData.errors["address.residence.country"] || ""}
              isTranslate
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
        {/* checkbox */}
        <div className="col-12 d-flex align-items-center mb-3">
          <LabelField
            title={
              t(
                "Is the domiciliary address the same as the residential address"
              ) + ":"
            }
            className="mb-0 colorPrimary poppins-medium me-3"
          />
          <ToggleButton value={formData.sameAddress} onToggle={handleToggle} />
        </div>
        <div className="col-12">
          <Title
            title={t("Domicile address") + ":"}
            className="bodyTitle colorPrimary tab-title mb-3"
          />
        </div>
        {/* Domicilary address */}
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("Street name") + ":"}
              type="text"
              value={
                formData.sameAddress
                  ? formData.address.residence.street
                  : formData.address.domicile.street
              }
              handleChange={handleChange("address", "domicile", "street")}
              name={"street"}
              id="street"
              isMandatory={true}
              error={formData.errors["address.domicile.street"] || ""}
              {...inputFieldSharedProps}
              className="inputFieldColor"
              colClassName="col-8"
              labelWrapperClassName="col-4 align-self-center"
              isDisabled={formData.sameAddress}
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("House no") + ":"}
              type="text"
              value={
                formData.sameAddress
                  ? formData.address.residence.number
                  : formData.address.domicile.number
              }
              handleChange={handleChange("address", "domicile", "number")}
              name={"number"}
              id="number"
              isMandatory={true}
              className="inputFieldColor"
              colClassName="col-8"
              error={formData.errors["address.domicile.number"] || ""}
              labelWrapperClassName="col-4 align-self-center"
              isDisabled={formData.sameAddress}
              {...inputFieldSharedProps}
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("Box") + ":"}
              type="text"
              value={
                formData.sameAddress
                  ? formData.address.residence.box
                  : formData.address.domicile.box
              }
              handleChange={handleChange("address", "domicile", "box")}
              name={"box"}
              id="box"
              className="inputFieldColor"
              colClassName="col-8"
              labelWrapperClassName="col-4 align-self-center"
              isDisabled={formData.sameAddress}
              {...inputFieldSharedProps}
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("Postal code") + ":"}
              type="text"
              value={
                formData.sameAddress
                  ? formData.address.residence.postal_code
                  : formData.address.domicile.postal_code
              }
              handleChange={handleChange("address", "domicile", "postal_code")}
              name={"postal_code"}
              isMandatory={true}
              error={formData.errors["address.domicile.postal_code"] || ""}
              id="postal_code"
              className="inputFieldColor"
              colClassName="col-8"
              labelWrapperClassName="col-4 align-self-center"
              isDisabled={formData.sameAddress}
              {...inputFieldSharedProps}
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("City") + ":"}
              type="text"
              value={
                formData.sameAddress
                  ? formData.address.residence.city
                  : formData.address.domicile.city
              }
              handleChange={handleChange("address", "domicile", "city")}
              name={"city"}
              id="city"
              isMandatory={true}
              error={formData.errors["address.domicile.city"] || ""}
              className="inputFieldColor"
              colClassName="col-8"
              labelWrapperClassName="col-4 align-self-center"
              isDisabled={formData.sameAddress}
              {...inputFieldSharedProps}
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row multiSelectWrapper">
            <SelectWithSearch
              fieldLabel={`${t("Country")}:`}
              title={t("Country")}
              name="country"
              placeHolder={t("Select")}
              isMandatory
              search
              options={countryOptions.countries}
              value={
                formData.sameAddress
                  ? countryOptions.countries.find(
                      (option) =>
                        option.value === formData?.address?.residence?.country
                    ) || null
                  : countryOptions.countries.find(
                      (option) =>
                        option.value === formData?.address?.domicile?.country
                    ) || null
              }
              onChange={(selectedOption: Option) =>
                handleSelectChange(selectedOption, "address.domicile.country")
              }
              isMulti={false}
              className="select-field"
              error={formData.errors["address.domicile.country"] || ""}
              isTranslate
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
              isDisabled={formData.sameAddress}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Address;
