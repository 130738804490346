import React, { useEffect, useState } from "react";
import SearchModel from "../../common/atoms/Search";
import Pagination from "../../common/atoms/Pagination";
import { ApiCall } from "../../../services/ApiServices";
import { Link } from "react-router-dom";
import CustomNotify from "../../common/atoms/CustomNotify";
import ModalPopup from "../../common/atoms/ModalPopup";
// import "../../../static/css/permission.css";
import Button from "../../common/atoms/Button";
import {
  GET_PERMISSIONS,
  DELETE_PERMISSION,
  CHANGE_PERMISSIONS,
} from "../../../routes/ApiEndpoints";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import Title from "../../common/atoms/Title";
import AccessControl from "../../../services/RolesAndPermissions/AccessControl";
import Header from "../../common/layout/Header";
import BackLink from "../../common/atoms/BackLink";
import Icon from "../../../Icon";
import { BsTrash } from "react-icons/bs";
import ConfirmPopup from "../../common/atoms/ConfirmPopup";
import OverFlowWrapper from "../../common/OverFlowWrapper";

const ManagePermissions = () => {
  interface Permission {
    permission_id?: number;
    name?: string;
    classification_name?: string;
  }

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchAllPermissions();
  }, []);

  const fetchAllPermissions = async () => {
    const response = await ApiCall.getService(CHANGE_PERMISSIONS, "GET");
    if (response.status === 200) {
      setPermissions(response.data);
    }
  };

  const deletePermission = (id: number | undefined) => {
    setShowModal(true);
    setDeleteId(id);
  };

  // Filter the data based on the search query
  const filteredData = permissions.filter((item: Permission) =>
    item.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  // Get the current items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(e.target.value);
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page when the search query changes
  };

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Modal popup for delete confirmation
  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${CHANGE_PERMISSIONS}/${deleteID}`;
      const response = await ApiCall.service(url, "DELETE");
      if (response.status === 200) {
        setPermissions((prevdata) =>
          prevdata.filter(
            (permissions) => permissions.permission_id !== deleteID
          )
        );
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Roles",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div>
        <Header
          headerName={t("Permissions")}
          headerClassName="myAccountCardTitle"
        />
        <div className="search-bar pb-4">
          <div className="row">
            <BackLink backLink="/" />
            <div className="col-6 text-end">
              <Link to="/permission/create">
                <div className="searchBtn d-inline-flex align-items-center justify-content-center text-decoration-none w-auto px-3">
                  {t("Create permisison")}
                </div>
              </Link>
            </div>
            <div className="col-12 pt-3">
              <SearchModel
                className="form-control"
                placeholder={t("Search by name")}
                handleChange={handleSearchChange}
                value={searchQuery}
              />
            </div>
          </div>
        </div>
        <div className="managepagesBg">
          <OverFlowWrapper className="table-responsive height-25">
            <table className="table table-hover createTableProperties">
              <thead>
                <tr className="TableHeader">
                  <th>{t("Name")}</th>
                  <th>{t("Classification")}</th>
                  <AccessControl
                    requiredPermissions={[
                      {
                        permission: "Roles",
                        update: true,
                        delete: true,
                      },
                    ]}
                    actions={true}
                    strict={false}
                  >
                    <th>{t("Actions")}</th>
                  </AccessControl>
                </tr>
                <tr>
                  <th
                    className="emptyRow p-0"
                    style={{ height: "0.7vw" }}
                    colSpan={3}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {currentItems && currentItems.length > 0 ? (
                  currentItems?.map((item, index) => (
                    <tr key={item.permission_id} className="border">
                      <td className="text-break" data-label={t("Id")}>
                        {(currentPage - 1) * itemsPerPage + index + 1}
                      </td>
                      <td className="text-break" data-label={t("Name")}>
                        {item.name}
                      </td>
                      <td
                        className="text-break"
                        data-label={t("Classification")}
                      >
                        {item.classification_name}
                      </td>
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Roles",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                      >
                        <td className="px-2 table-action-icons">
                          <AccessControl
                            requiredPermissions={[
                              {
                                permission: "Roles",
                                update: true,
                              },
                            ]}
                          >
                            <Link
                              to={`/permission/edit/${item.permission_id}`}
                              className="btn p-0 border-0 me-1"
                            >
                              <span
                                className="secondaryColorHoverEffect"
                                title={t("Edit")}
                              >
                                <Icon
                                  isIcon={true}
                                  width="1vw"
                                  height="1vw"
                                  name="EditIcon"
                                />
                              </span>
                            </Link>
                          </AccessControl>
                          <AccessControl
                            requiredPermissions={[
                              {
                                permission: "Roles",
                                delete: true,
                              },
                            ]}
                          >
                            <span
                              className="secondaryColorHoverEffect"
                              title={t("Edit")}
                              onClick={() =>
                                deletePermission(item.permission_id)
                              }
                            >
                              <Icon
                                isIcon={true}
                                width="1vw"
                                height="1vw"
                                name="delete"
                              />
                            </span>
                          </AccessControl>
                        </td>
                      </AccessControl>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="border text-danger text-center">
                      {t("No records")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </OverFlowWrapper>
          {totalPages > 1 && (
            <div className="pagination justify-content-center mt-4">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
      <ConfirmPopup
        show={showModal}
        onHide={handleModalClose}
        title={t("Delete confirmation")}
        body={t("Are you sure want to delete") + "?"}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </AccessControl>
  );
};

export default translate(ManagePermissions);
