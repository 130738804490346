import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"; // import the icons
import React, { useState } from "react";
import Button from "../common/atoms/Button";
import { t } from "../common/atoms/translation";
import { LabelWithInputField } from "../common/molecules/LabelWithInputField";
import { EmployeeFormData, HandleChange } from "./Registration";

interface EmployeeCommunicationProps {
  formData: EmployeeFormData;
  handleChange: HandleChange;
}

const EmployeeCommunication: React.FC<EmployeeCommunicationProps> = ({
  formData,
  handleChange,
}) => {
  // State for toggling password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <div className="row">
      {/* E-mail Field */}
      <div className="col-4 mb-3">
        <div className="row">
          <LabelWithInputField
            label={t("E-mail") + ":"}
            className="inputFieldColor"
            colClassName="col-8"
            type="email"
            value={formData.email}
            handleChange={handleChange("email")}
            name="email"
            isMandatory={true}
            error={formData?.errors?.email ?? undefined}
            id="email"
            labelWrapperClassName="col-4 align-self-center"
            autoComplete="off"
          />
        </div>
      </div>
      <div className="col-4 mb-3">
        <div className="row">
          <LabelWithInputField
            label={t("Repeat E-mail") + ":"}
            className="inputFieldColor"
            colClassName="col-8"
            type="email"
            value={formData.confirm_email}
            handleChange={handleChange("confirm_email")}
            name="confirm_email"
            isMandatory={true}
            error={formData?.errors?.confirm_email ?? undefined}
            id="confirm_email"
            labelWrapperClassName="col-4 pe-0 align-self-center"
            autoComplete="off"
          />
        </div>
      </div>

      {/* Phone Number Field */}
      <div className="col-4 mb-3">
        <div className="row">
          <LabelWithInputField
            label={t("Phone") + ":"}
            className="inputFieldColor"
            colClassName="col-8"
            type="text"
            value={formData.phone_number}
            handleChange={handleChange("phone_number")}
            name="phone_number"
            isMandatory={true}
            error={formData?.errors?.phone_number ?? undefined}
            id="phone_number"
            labelWrapperClassName="col-4 align-self-center"
            autoComplete="off"
          />
        </div>
      </div>

      {/* Password Field */}
      <div className="col-4 mb-3">
        <div className="row align-items-center position-relative">
          <LabelWithInputField
            label={t("Password") + ":"}
            className="inputFieldColor"
            colClassName="col-8"
            type={showPassword ? "text" : "password"} // toggle password visibility
            value={formData.security.password}
            handleChange={handleChange("security", "password")}
            name="password"
            id="password"
            labelWrapperClassName="col-4 align-self-center"
            isMandatory={formData.id === 0 ? true : false}
            error={formData?.errors?.["security.password"] ?? undefined}
            autoComplete="off"
          />
          <span
            onClick={() => setShowPassword(!showPassword)} // toggle state
            className="secondaryColorHoverEffect cursor-pointer col-auto eyeIcon"
          >
            <FontAwesomeIcon
              icon={showPassword ? faEye : faEyeSlash} // change icon based on state
            />
          </span>
        </div>
      </div>

      {/* Confirm Password Field */}
      <div className="col-4 mb-3">
        <div className="row align-items-center position-relative">
          <LabelWithInputField
            label={t("Confirm password") + ":"}
            className="inputFieldColor"
            colClassName="col-8"
            type={showConfirmPassword ? "text" : "password"} // toggle confirm password visibility
            value={formData.security.confirm_password}
            handleChange={handleChange("security", "confirm_password")}
            name="confirm_password"
            id="confirm_password"
            labelWrapperClassName="col-4 align-self-center"
            isMandatory={formData.id === 0 ? true : false}
            error={formData?.errors?.["security.confirm_password"] ?? undefined}
            autoComplete="off"
          />
          <span
            onClick={() => setShowConfirmPassword(!showConfirmPassword)} // toggle state
            className="secondaryColorHoverEffect cursor-pointer col-auto eyeIconPass"
          >
            <FontAwesomeIcon
              icon={showConfirmPassword ? faEye : faEyeSlash} // change icon based on state
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCommunication;
