import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { USER_ROLE_ENTITY_TYPE } from "./Constants";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import ReactPaginate from "react-paginate";
import {
  t,
  translate,
} from "../../../CentralDataMangement/translation/Translation";
import InputTextfield from "../../../common/atoms/InputTextField";
import ResetBtn from "../../../common/atoms/ResetBtn";
import SearchBtn from "../../../common/atoms/SearchBtn";
import OverFlowWrapper from "../../../common/OverFlowWrapper";
import BackLink from "../../../common/atoms/BackLink";
import Icon from "../../../../Icon";
import Pagination from "../../../common/atoms/Pagination";

const ITEMS_PER_PAGE = 8;

const CoefficientOverview = ({
  headers,
  rows,
  entityId,
  entityType,
  ...props
}) => {
  if (entityType === 2 && headers.indexOf("Employer") > -1) {
    headers.splice(headers.indexOf("Employer"), 1);
  }

  const navigate = useNavigate();
  const [state, setState] = useState({
    searchName: "",
    searchEmployer: "",
    searchCompany: "",
    filterRows: rows,
    currentItems: [],
    pageCount: 0,
    itemOffset: 0,
    currentPage: 0,
  });
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setState({ ...state, filterRows: rows });
  }, [rows.length]);

  const handleActionClick = (action, eachRow) => {
    if (action === "edit") {
      navigate(
        `/manage/coefficient-deviation?entitytype=${entityType}&entityid=${entityId}&action=1&id=${eachRow.id}`
      );
    }
  };

  const handleSearch = (search) => {
    let filterRows = [];
    let { searchCode = "", searchName = "" } = state;
    if (search && (searchCode || searchName)) {
      filterRows = rows.filter((item) => {
        let status = true;
        if (searchName)
          status =
            `${item["perfcode_name"]}`
              .toLowerCase()
              .toString()
              .indexOf(searchName.toLowerCase().toString()) !== -1;
        if (status && searchCode)
          status =
            `${item["perfcode_code"]}`
              .toLowerCase()
              .toString()
              .indexOf(searchCode.toLowerCase().toString()) !== -1;
        return status;
      });
    } else {
      filterRows = rows;
      searchCode = "";
      searchName = "";
    }
    setState({
      ...state,
      searchName,
      searchCode,
      filterRows: filterRows,
      currentPage: 0,
      itemOffset: 0,
    });
  };

  // ------------------- Pagination code -------------------------//
  const pageCount = Math.ceil(state.filterRows.length / ITEMS_PER_PAGE);

  const currentItems = useMemo(() => {
    const offset = currentPage * ITEMS_PER_PAGE;
    return state.filterRows.slice(offset, offset + ITEMS_PER_PAGE);
  }, [currentPage, state.filterRows]);

  console.log(currentItems);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber - 1);
  };

  const getNeededActions = (eachRow) => {
    return (
      <>
        {/* DESKTOP */}
        {[
          USER_ROLE_ENTITY_TYPE.ABSOLUTE_YOU_ADMIN,
          USER_ROLE_ENTITY_TYPE.SALES_AGENT,
        ].includes(entityType) && (
          <span
            title={t("Edit")}
            className="secondaryColorHoverEffect"
            onClick={() => handleActionClick("edit", eachRow)}
          >
            <Icon isIcon={true} width="1vw" height="1vw" name="EditIcon" />
          </span>
        )}
      </>
    );
  };

  return (
    <>
      <div className="search-bar pb-4">
        <div className="row mb-2">
          <BackLink backLink="/config/settings" />
        </div>
        <div className="row">
          {[
            { value: "searchCode", label: t("Coefficient code") },
            { value: "searchName", label: t("Coefficient name") },
          ].map((key, index) => {
            if (
              (entityType == 2 && key.label === "employer") ||
              (entityType === 3 && key.label !== "Document")
            )
              return;
            return (
              <div
                key={index}
                className={
                  entityType === 3
                    ? "col-md-12"
                    : entityType === 2
                    ? "col-4"
                    : "col-4"
                }
              >
                <InputTextfield
                  value={state[key.value]}
                  handleChange={(e) =>
                    setState({
                      ...state,
                      [key.value]: e.target.value,
                    })
                  }
                  placeholder={t(`${key.label}`)}
                  onKeyUp={(e) => (e.key === "Enter" ? handleSearch(1) : null)}
                />
              </div>
            );
          })}
          <div
            className={
              entityType === 3
                ? "col-lg-3 col-md-7"
                : entityType === 2
                ? "col-4"
                : "col-4"
            }
          >
            <ResetBtn handleResetClick={() => handleSearch(0)} />
            <SearchBtn handleSearchClick={() => handleSearch(1)} />
          </div>
        </div>
      </div>
      <div className="managepagesBg">
        <OverFlowWrapper className="table-responsive height-25">
          <table
            className={`${
              entityType === 3 ? "" : entityType === 2 ? "" : ""
            } table table-hover customTableProperties`}
          >
            <thead>
              <tr key={"header-row-tr"} className="TableHeader">
                {headers.map((eachHeader, index) => (
                  <th
                    key={`tablecol${index}`}
                    className="align-middle "
                    scope="col"
                    style={{ width: "33%" }}
                  >
                    {t(eachHeader)}
                  </th>
                ))}
              </tr>
              <tr>
                <td
                  className="emptyRow p-0"
                  style={{ height: "0.7vw" }}
                  colSpan={3}
                ></td>
              </tr>
            </thead>
            <tbody>
              {currentItems && currentItems.length > 0 ? (
                currentItems.map((eachRow) => (
                  <tr key={eachRow.id} id={eachRow.id} className="border">
                    <td data-label="Code">{eachRow.perfcode_code}</td>
                    <td> {eachRow.perfcode_name} </td>
                    <td>{getNeededActions(eachRow)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="border text-danger text-center">
                    {t("No records")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {state.filterRows.length > ITEMS_PER_PAGE && (
            <div className="pagination justify-content-center mt-4 px-4">
              <Pagination
                currentPage={currentPage + 1}
                totalPages={pageCount}
                onPageChange={handlePageClick}
                maxVisiblePages={5}
              />
            </div>
          )}
        </OverFlowWrapper>
      </div>
    </>
  );
};

export default translate(CoefficientOverview);
