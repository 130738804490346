import React from "react";
import BackLink from "../../common/atoms/BackLink";
import Header from "../../common/layout/Header";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import { t } from "../translation/Translation";
import LocationCard from "./LocationCard";

const ManageLocationNew = () => {
  return (
    <>
      <Header
        headerName={t("Manage location")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar">
        <div className="row">
          <BackLink backLink="/welcome-dashboard" />
        </div>
      </div>
      <div className="createpagesBg">
        <OverFlowWrapper className="yourPoolWrapper">
          <div className="container-fluid ps-0">
            <div className="row">
              <div className="col-4 mb-3">
                <LocationCard
                  companyName="testing"
                  isComapny={true}
                  isLocationManager={true}
                />
              </div>
            </div>
          </div>
        </OverFlowWrapper>
      </div>
    </>
  );
};

export default ManageLocationNew;
