import React, { useState } from 'react';
import Image from "../common/atoms/Image";
import AccessControl from "../../services/RolesAndPermissions/AccessControl";
import Icon from "../../Icon";
import { t } from "../CentralDataMangement/translation/Translation";
import UserImage from "../../static/images/User.svg";
import InputTextfield from '../common/atoms/InputTextField';
import { formatTimeInput } from '../common/utlis/PlanningTimingHelper';
import { calculateTotalTime } from '../common/utlis/PlanningUtility';
import { useSelector } from 'react-redux';
import { ApiCall } from '../../services/ApiServices';
import { CREATE_PLANNING } from '../../routes/ApiEndpoints';
import CustomNotify from '../common/atoms/CustomNotify';
import DayPlanningOverview from './DayPlanningOverview';
import { DAY_PLANNING } from '../../routes/RouteConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectCurrentCompany } from '../../features/auth/AuthSlice';

interface Shift {
    start_time: string;
    end_time: string;
    sequence: number;
    // Add other shift fields if necessary
}

interface Schedule {
    shifts: Shift[];
    project_id: number | null;
    cost_center_id: number | null;
}

interface PlanningLayoutData {
    planning_id?: number;
    planning_employee_detail_id?: number;
    employee_id?: number;
    employee_name?: string;
    employee_type_id?: number;
    employee_type?: string;
    company_id?: number,
    location_id?: number;
    pc_id?: number;
    pc?: string;
    function_id?: number;
    function?: string;
    salary?: string;
    start_date?: string;
    end_date?: string;
    schedule: {
        [date: string]: Schedule;
    };
}
interface Props {
    emp: PlanningLayoutData,
}

const EmployeeLayoutRow: React.FC<Props> = ({ emp }) => {
    const [employeeData, setEmployeeData] = useState<PlanningLayoutData>(emp);
    const IconProps = {
        isIcon: true,
        width: "1vw",
        height: "1vw",
    };
    const companyId = useSelector(selectCurrentCompany);
    const navigator = useNavigate();
    const loc = useLocation(); // Get current path

 
    const handleShiftChange = (date: string, index: number, key: string, value: string) => {
        const formattedValue = formatTimeInput(value);
        setEmployeeData(prevState => {
            const updatedSchedule = { ...prevState.schedule };
            const updatedShifts = [...updatedSchedule[date].shifts];
            updatedShifts[index] = { ...updatedShifts[index], [key]: formattedValue };
            updatedSchedule[date].shifts = updatedShifts;

            return { ...prevState, schedule: updatedSchedule };
        });
    };
    const handleSendPlanning = async () => {
        console.log(employeeData);
        try {
            employeeData.company_id = parseInt(companyId.id);
            let dataObj = {
                company_id: parseInt(companyId.id),
                user_id: -999,
                planning: [employeeData]
            };
            // Make sure to await the API call response
            // const response = await ApiCall.service(CREATE_PLANNING, "POST", dataObj, false, PLANNING_MICROSERVICE);
            const response = await ApiCall.service(CREATE_PLANNING, "POST", dataObj, false);


            // You can log or handle the response here
            if (response.status == 201) {
                CustomNotify({ type: 'success', message: t(response.message), autoClose: 2500 });
                navigator("/location-dashboard"); // Navigate to the current path to "reload" the page
            } else if (response.status == 422) {
                console.error('Error making API call:', response.errors);
                CustomNotify({ type: 'error', message: t(response.message), autoClose: 2500 });
            }
        } catch (error) {
            // Catch and handle any errors that might occur during the API call
            console.error('Error making API call:', error);
        }
    }

    const handleAddShift = (date: string) => {
        setEmployeeData(prevState => {
            const updatedSchedule = { ...prevState.schedule };
            const existingShifts = updatedSchedule[date]?.shifts || [];

            // Only add a new shift if the existing shifts are less than 2
            if (existingShifts.length < 2) {
                const newShift = { start_time: '', end_time: '', sequence: existingShifts.length + 1 };
                updatedSchedule[date].shifts = [...existingShifts, newShift];
            }

            return { ...prevState, schedule: updatedSchedule };
        });
    };


    const shiftCells = (daySchedule: Shift[], date: string) => (
        <>
            {daySchedule.map((shift, index) => (
                <>
                    <td key={index} className="align-middle" style={{ width: '5%' }} // Custom width
                    >
                        <InputTextfield
                            value={shift.start_time}
                            className="me-2"
                            handleChange={(e) => handleShiftChange(date, index, "start_time", e.target.value)}
                        />
                    </td>

                    <td key={index} className=" align-middle" style={{ width: '5%' }} // Custom width
                    >
                        <InputTextfield
                            value={shift.end_time}
                            className="ms-2"
                            handleChange={(e) => handleShiftChange(date, index, "end_time", e.target.value)}
                        />
                    </td>
                </>
            ))}
            {daySchedule.length < 2 && (
                <td className="align-midde" colSpan={daySchedule.length == 0 ? 4 : 2} >
                    <span
                        data-bs-toggle="tooltip"
                        title={"Add"}
                        onClick={() => handleAddShift(date)}
                    >
                        <Icon
                            name="add"
                            width="0.8vw"
                            height="0.8vw"
                            fillColor="#8571b9"
                            isIcon={true}
                        />
                    </span>
                </td>
            )}
            <td className="align-middle">
                {"00:00"}
            </td>
            <td className="align-middle">
                <div className="totalHours text-white poppins-medium">
                    {daySchedule.length > 1
                        ? calculateTotalTime(
                            daySchedule[0].start_time,
                            daySchedule[1].end_time,
                            daySchedule[0].end_time,
                            daySchedule[1].start_time
                        )
                        : "00:00"}
                </div>
            </td>
        </>
    );

    const getDateSchedules = (date: string) => {
        return employeeData.schedule[date]?.shifts || [];
    };

    return (
        <>
            {Object.keys(employeeData.schedule).map((date) => (
                <React.Fragment key={date}>
                    <tr>
                        <td>
                            <Image
                                src={UserImage}
                                imageWidth="3vw"
                                imageHeight="3vw"
                                imageBorderRadius="50%"
                            />
                        </td>
                        <td>{employeeData.employee_name}</td>
                        <td>{employeeData.employee_type}</td>
                        <td>{employeeData.function}</td>
                        <td>{employeeData.pc}</td>
                        <td>€{employeeData.salary}</td>
                        {shiftCells(getDateSchedules(date), date)}
                        <td className="actionWrapper">
                            <div>
                                <span
                                    data-bs-toggle="tooltip"
                                    title={t("send")}
                                    onClick={handleSendPlanning}
                                    className="me-2"
                                >
                                    <Icon name="sendIcon" {...IconProps} />
                                </span>
                                {/* <span
                                    data-bs-toggle="tooltip"
                                    title={t("Edit")}
                                    onClick={() => { }}
                                    className="me-2"
                                >
                                    <Icon name="EditIcon" {...IconProps} />
                                </span>
                                <span
                                    data-bs-toggle="tooltip"
                                    title={t("Remove")}
                                    onClick={() => { }}
                                >
                                    <Icon name="RemoveIcon" {...IconProps} />
                                </span> */}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td
                            colSpan={15}
                            style={{ height: "0.7vw" }}
                            className="emptyRow p-0 bg-transparent"
                        ></td>
                    </tr>
                </React.Fragment>
            ))}
        </>
    );
};

export default EmployeeLayoutRow;
