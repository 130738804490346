import React, { MouseEventHandler } from "react";
import Image from "../atoms/Image";
import Icon from "../../../Icon";
import UserImage from "../../../static/images/User.svg";
import { selectAuth } from "../../../features/auth/AuthSlice";
import { useSelector } from "react-redux";

interface Employee {
  id: number;
  first_name: string;
  last_name: string;
  is_fav: boolean;
  profile_picture_url: string | null;
  employee_type: string;
  employee_type_id: number;
  employee_type_category: string;
  employee_type_category_id: number;
  paritaire_commitee: string;
  paritaire_commitee_id: number;
  functional_salary: string;
  function_id: number;
  function_name: string;  
}

interface CardConfig {
  cardClassName: string;
  employeeInformationClass: string;
  cardMainClassName: string;
  iconClassName: string;
  isCardIcon: boolean;
  iconName: string;
  favouriteIconName: string;
  noFavouriteIconName: string; // Add this for the "unfavorited" state
  isFavourite: boolean;
}

interface EmployeeDetailsCardProps {
  employee: Employee;
  cardConfig: CardConfig;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  handleFavIcon?: MouseEventHandler<HTMLButtonElement>;
  favouriteIconClassName?: string;
}

const EmployeeDetailsCard: React.FC<EmployeeDetailsCardProps> = ({
  employee,
  cardConfig,
  handleClick,
  handleFavIcon,
  favouriteIconClassName,
}) => {
  const {
    profile_picture_url,
  } = employee;
  const {
    cardClassName,
    employeeInformationClass,
    cardMainClassName,
    iconClassName,
    isCardIcon,
    iconName,
    favouriteIconName,
    noFavouriteIconName, // Add unfavorited icon
    isFavourite,
  } = cardConfig;

  // Optionally, you can use this for conditional rendering based on the user
  const currentUser = useSelector(selectAuth);

  return (
    <div className={cardMainClassName}>
      {isCardIcon && (
        <div className={iconClassName}>
          <span onClick={handleClick}>
            <Icon isIcon={true} width="1vw" height="1vw" name={iconName} />
          </span>
        </div>
      )}
      <div className={`${cardClassName}`}>
        {handleFavIcon && (
          <div className={favouriteIconClassName}>
            <span onClick={handleFavIcon}>
              <Icon
                className="cursor-pointer"
                isIcon={true}
                width="1vw"
                height="1vw"
                name={isFavourite ? favouriteIconName : noFavouriteIconName} // Toggle between icons
              />
            </span>
          </div>
        )}
        <div className="mx-3">
          <Image
            src={profile_picture_url || UserImage} // Default image if none provided
            imageWidth="3vw"
            imageHeight="3vw"
            imageBorderRadius="50%"
          />
        </div>
        <div className={employeeInformationClass}>
          <div>{employee?.first_name + " " + employee?.last_name}</div>
          <div>{employee?.employee_type}</div>
          <div>{employee?.function_name}</div>
          <div>{employee?.functional_salary}</div>
          <div>{employee?.paritaire_commitee}</div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetailsCard;
