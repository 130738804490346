import React, { useEffect, useRef, useState } from "react";
import Icon from "../../Icon";
import IconWithTitle from "../common/molecules/IconWithTitle";
import LoadImage from "../../static/images/DummyImage.jpg";
import { useSelector } from "react-redux";
import { selectCompanyEmployees } from "../../features/planning/employeeSlice";
import userIcon from "../../static/images/User.svg";
import { selectCurrentCompany } from "../../features/auth/AuthSlice";
import { ApiCall } from "../../services/ApiServices";

// Define a type for the employee object
interface Employee {
  id: number;
  first_name: string;
  last_name: string;
  is_fav: boolean;
  profile_picture_url: string | null;
  employee_type: string;
  employee_type_id: number;
  employee_type_category: string;
  employee_type_category_id: number;
  paritaire_commitee: string;
  paritaire_commitee_id: number;
  functional_salary: string;
  function_id: number;
  function_name: string;
}
interface Props {
  onEmployeeClick?: (employee: Employee) => void; // Update prop type
  selectedEmployee?:Employee | null
}


const EmployeesNameCard: React.FC<Props> = ({ onEmployeeClick,selectedEmployee }) => {

  const currentCompanyId = useSelector(selectCurrentCompany)?.id;
  const [companyEmployees, setEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState<boolean>(true);


  const fetchInfo = async () => {
    try {
      setLoading(true);
      const res = await ApiCall.getService(
        `companyEmployees?id= ${currentCompanyId}`,
        "GET"
      );
      if (res.status !== 200) {
        throw new Error("Network response was not ok");
      }
      if (res.status === 200) {
        setLoading(false);
        setEmployees(res.data);
      } else {
        throw new Error(res.message);
      }
    } catch (err) {
      console.error(err);
    }
  };


  useEffect(() => {
    fetchInfo();
  }, [currentCompanyId]);
  // Sort employees so that favorite employees are listed first
  const sortedEmployees = [...companyEmployees].sort((a, b) =>
    a.is_fav === b.is_fav ? 0 : a.is_fav ? -1 : 1
  );

  // Use ref to access the scrollable container
  const scrollRef = useRef<HTMLDivElement>(null);

  // Function to handle scroll (left or right)
  const handleScroll = (direction: "left" | "right") => {
    if (scrollRef.current) {
      const scrollAmount = direction === "right" ? 200 : -200;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  // Loading indicator
  const loadingIndicator = (
    <div className="d-flex justify-content-center align-items-center">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only"></span>
      </div>
    </div>
  );

  return (
    <div className="col-12">

      <div className="card border-0 px-2 py-1 mb-3">
        {loading ? (
          loadingIndicator
        ) : (
          <div className="d-flex align-items-center">
            {/* Left arrow to scroll left */}
            <div
              style={{ width: "1%" }}
              className="secondaryColorHoverEffect"
              onClick={() => handleScroll("left")}
            >
              <Icon isIcon={true} width="1vw" height="1vw" name="backIcon" />
            </div>

            {/* Scrollable Employee List */}

            <div
              ref={scrollRef}
              className="mx-auto d-flex"
              style={{
                overflowX: "auto",
                width: "96%",
                scrollbarWidth: "none", // For Firefox
              }}
            >
              {/* Use inline styles for WebKit scrollbar hiding */}
              <style>
                {`
                ::-webkit-scrollbar {
                  display: none;
                }
              `}
              </style>

              {sortedEmployees.map((employee: Employee) => (
                <div
                  key={employee.id}
                  className="d-inline-flex align-items-center flex-column justify-content-center mx-3 text-center"
                  style={{ minWidth: "6.5%", width: "6.5%" }}
                >
                  <div style={{ width: "100%" }} className={`text-center employeeNameCardHover cursor-pointer ${selectedEmployee?.id == employee.id ? "employeeNameCardActive":"" }`} onClick={() => onEmployeeClick?.(employee)} // Pass employee object
                  >
                    <span className="mb-1">
                      <Icon
                        isIcon={true}
                        width="1vw"
                        height="1vw"
                        name={employee.is_fav ? "starIcon" : ""}
                        onClick={() => { }}
                      />
                    </span>
                    <IconWithTitle
                      src={employee.profile_picture_url ?? userIcon}
                      isImage={true}
                      imageWidth="2vw"
                      imageHeight="2vw"
                      imageBorderRadius="50%"
                      title={
                        `${employee.first_name} ${employee.last_name.charAt(
                          0
                        )} ` + "."
                      }
                      isTitle={true}
                      labelClassName="colorPrimary poppins-medium employeeName cursor-pointer"
                    />
                    <div className="mb-2 text-cneter">
                      <div className="plannedColor mx-auto" />
                      {/* {isPlanned? "plannnedColor" : "notPlannedColor"} */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Right arrow to scroll right */}
            <div
              style={{ width: "1%" }}
              className="secondaryColorHoverEffect"
              onClick={() => handleScroll("right")}
            >
              <Icon isIcon={true} width="1vw" height="1vw" name="nextIcon" />
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default EmployeesNameCard;
