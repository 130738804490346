const CODE_LENGTHS:any = {
    AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
    CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
    FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
    HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
    LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
    MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
    RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,   
    AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
    SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
  };
  // Function to validate IBAN
  export function isValidIBANNumber(iban: any) {
    console.log("iban", iban);
    if(iban === ""){
      return null;
    }
    const formattedIban = formatIban(iban);
    console.log("formattedIban", formattedIban);
    const code = extractCountryCode(formattedIban);
    console.log("code", code);
  
    // Check if the country code is valid
    if (!code) {
      return 'Invalid IBAN: Country code is missing or incorrect.';
    }
    console.log("validLength", !isValidLength(formattedIban, code));
  
    // Check if the IBAN length is valid for the country
    if (!isValidLength(formattedIban, code)) {
      return `Invalid IBAN: Incorrect length for country code ${code}.`;
    }
  
    // Rearrange and check the checksum using mod97
    const rearrangedDigits = rearrangeIban(formattedIban, code);
    if (mod97(rearrangedDigits) !== 1) {
      return 'Invalid IBAN: Checksum failed.';
    }
  
    // If all checks pass, return null (no error)
    return null;
  }
  
  // Helper function to format IBAN (keep only alphanumeric characters)
  function formatIban(iban: any) {
    return String(iban).toUpperCase().replace(/[^A-Z0-9]/g, '');
  }
  
  // Helper function to extract the country code
  function extractCountryCode(iban: any) {
    const match = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
    return match ? match[1] : null;
  }
  
  // Helper function to validate IBAN length based on country code
  function isValidLength(iban: any, countryCode: any) {
    return iban.length === CODE_LENGTHS[countryCode];
  }
  
  // Helper function to rearrange IBAN for validation
  function rearrangeIban(iban: any, countryCode: any) {
    const rearranged = iban.slice(4) + countryCode + iban.slice(2, 4);
    return rearranged.replace(/[A-Z]/g, (letter: any) => letter.charCodeAt(0) - 55);
  }
  
  // Function to calculate mod97 checksum
  function mod97(digits: any) {
    let checksum = digits.slice(0, 2);
    for (let offset = 2; offset < digits.length; offset += 7) {
      const fragment = String(checksum) + digits.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
  }