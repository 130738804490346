import React, { ChangeEvent } from "react";

interface Props {
  name?: string;
  value?: string | number;
  ischecked?: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label: React.ReactNode;
  customStyle?: React.CSSProperties;
  id?: string;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
}

const RadioField: React.FC<Props> = ({
  name = "",
  value = "",
  ischecked = false,
  handleChange,
  label,
  customStyle = {},
  id = "",
  className,
  containerClassName = "d-inline-block w-auto me-4", //default
  disabled = true,
}) => {
  console.log('dis',disabled);
  return (
    <>
      <span className={containerClassName}>
        <label style={{ cursor: "pointer" }} className={className || ""}>
          <input
            style={{ ...customStyle, ...{ marginRight: "10px" } }}
            type="radio"
            name={name}
            checked={ischecked}
            onChange={handleChange}
            id={id}
            value={value}
            disabled={disabled}
            className="form-check-input shadow-none cursor-pointer select_border min-width1em"
          />
          <span style={{fontSize:"0.9vw", lineHeight:"1vw"}}>{label}</span>
        </label>
      </span>
    </>
  );
};

export default RadioField;
