import React, { useState, useRef, useContext, useEffect } from "react";
import {
  GET_SALARY_BENIFITS,
  STORE_SALARY_BENIFITS,
} from "../../../routes/ApiEndpoints";
import styles from "./Pc.module.css";
import MultiSelectField from "./MultiSelectField";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../../services/ApiServices";
import { FaRegPlusSquare, FaRegMinusSquare } from "react-icons/fa";
import styles2 from "./SalaryBenefits.module.css";
import DateField from "./DateField";
import { useSearchParams, useParams, useNavigate, useLocation } from "react-router-dom";
import Header from "../../common/layout/Header";
import BackLink from "../../common/atoms/BackLink";
import "./multiSelect.css";
import CheckBoxField from "../../common/atoms/CheckBoxField";
import LabelField from "../../common/atoms/LabelField";
import InputTextfield from "../../common/atoms/InputTextField";
import Icon from "../../../Icon";

const SalaryBenefits = (props) => {

  const location = useLocation();
  const pathParts = location.pathname.split('/'); // Split the path into parts
  let actionType = pathParts[pathParts.length - 1];

  const [salaryBenefits, setSalaryBenefits] = useState([]);
  const [pc_unique_key, setpc_unique_key] = useState([]);
  const [errors, setErrors] = useState({});

  const options = [
    { value: 0, label: "--Select--" },
    { value: 1, label: "Hour" },
    { value: 2, label: "Day" },
    { value: 3, label: "Week" },
    { value: 4, label: "Contract" },
  ];
  const [compState, setCompState] = useState({
    expand: {},
  });
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const k = searchParams.get("k");
    console.log(searchParams);
    setpc_unique_key(k);
    ApiCall.getService(
      GET_SALARY_BENIFITS + "/" + k,
      "GET",
      "central-data-management"
    )
      .then((result) => {
        if (result.data.length > 0) {
          console.log(result.data);
          setSalaryBenefits(result.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const getOptionObj = (val) => {
    var V = "";
    options.forEach((data) => {
      if (data.value == val) {
        V = data;
      }
    });
    return V;
  };

  const onSelect = (e) => {
    if (e.length > 0) {
      let data = [...salaryBenefits];
      let index = data.findIndex((x) => x.sb_main_id === e[0].sb_main_id);
      data[index].selected = "1";
      setSalaryBenefits(data);
    }
  };

  const updateBenefitList = () => {
    let data = [...salaryBenefits];
    let dataArray = data.filter((object1) => {
      return object1["selected"] !== "1";
    });
    return dataArray;
  };
  const onDelete = (index) => {
    let data = [...salaryBenefits];
    data[index].selected = "3";
    setSalaryBenefits(data);
  };

  const expandMinimizeDiv = (sb_main_id) => {
    let expand = { ...compState.expand };
    expand[sb_main_id] = !expand[sb_main_id];
    setCompState({ ...compState, expand: expand });
  };
  const showEachBenifitDetails = (val, index) => {
    const { expand } = compState;
    return (
      <div className="basic_details">
        <div key={val.pc_id} className={`${styles2["expand-minimize-div"]}`}>
          <div className="mb-3 d-flex align-items-center">
            <span
              onClick={() => expandMinimizeDiv(val.sb_main_id)}
              title={expand[val.sb_main_id] === true ? t("Open") : t("Close")}
              className={`${styles2["expand-minimize-span"]} close_open_basic_details secondaryColorHoverEffect`}
              style={{ width: "3%" }}
            >
              {!expand[val.sb_main_id] ? (
                <FaRegMinusSquare />
              ) : (
                <FaRegPlusSquare />
              )}
            </span>
            <div
              onClick={() => expandMinimizeDiv(val.sb_main_id)}
              className={`${styles2["expand-minimize-box"]} inputFieldColor`}
              style={{ width: "94%" }}
            >
              <span className="colorPrimary ps-2">
                {" "}
                {salaryBenefits[index].name}{" "}
              </span>
            </div>
            {actionType != "view" && (
              <span
                onClick={() => onDelete(index)}
                title={"Delete"}
                className={`actionWrapper text-center`}
                style={{ width: "3%" }}
              >
                <Icon isIcon={true} width="0.8vw" height="0.8vw" name="close" />
              </span>
            )}
          </div>
          {!expand[val.sb_main_id] ? (
            <div className={`${styles2["salay-content-div"]}`}>
              {showSalaryContent(index)}
            </div>
          ) : null}
        </div>
      </div>
    );
  };



  const updateValue = (index, value, type) => {

    var object = [...salaryBenefits];
    console.log(object, "obj");
    switch (type) {
      case "is_mandatory":
        object[index].is_mandatory = value === true ? true : false;
        break;
      case "is_sales_can_change":
        object[index].is_sales_can_change = value === true ? true : false;
        break;

      case "start_date":
        object[index].start_date = value;
        break;

      case "end_date":
        object[index].end_date = value;
        break;

      case "occurence":
        object[index].occurence = value;
        break;

      case "amount":
        object[index].amount = value;
        break;

      case "is_skip_absence":
        object[index].is_skip_absence = value;
        break;

      default:
        break;
    }
    setSalaryBenefits(object);
  };
  const validation = () => {
    // European currency format: 123.456,78 or 123456,78
    const europeanAmountFormatRegex = /^(\d{1,3}(\.\d{3})*|\d+)(,\d{1,2})?$/;

    let isValid = true;
    let errorsObj = {};

    salaryBenefits.forEach((benefit, index) => {
      if (benefit.paritaircommittee_id != '') {
        if (benefit.start_date != '' && benefit.end_date != '') {
          if (benefit.start_date > benefit.end_date) {
            errorsObj[`endDate_${index}`] = "End date should be greater than start date";
            isValid = false;
          }
        }

        if (benefit.amount != '') {
          // Remove spaces and convert '.' to ',' for validation
          const amount = benefit.amount.replace(/\s+/g, '').replace('.', ',');

          if (!europeanAmountFormatRegex.test(amount)) {
            errorsObj[`amount_${index}`] = "Invalid amount format";
            isValid = false;
          }
        }
      }
    });

    setErrors(errorsObj);
    return isValid;
  };

  const Submit = (e) => {
    e.preventDefault();
    validation()
      ? ApiCall.service(
        STORE_SALARY_BENIFITS + "/" + pc_unique_key,
        "POST",
        { data: salaryBenefits },
        false,
        "central-data-management"
      )
        .then((res) => {
          if (res.success) {
            navigate("/editpc/" + pc_unique_key);
          }
        })
        .catch((error) => { })
      : console.log("false validation");
  };

  const showSalaryContent = (index) => {
    let salaryDataArray = salaryBenefits[index] || [];
    let tableHtmlContent = [];
    tableHtmlContent.push(
      <div
        className={`mb-3 rounded-3 border p-3`}
        style={{ width: "94%", margin: "auto" }}
      >
        <div className="row">
          {/* is_mandatory */}

          <div className={"col-3 mb-3"}>
            <CheckBoxField
              name={`is_mandatory${salaryDataArray.sb_main_id}`}
              label={`${t("Is this mandatory")}?`}
              className="my-checkbox"
              isChecked={salaryDataArray.is_mandatory == true}
              onChangeHandler={(e) => {
                if (actionType !== "view") {
                  updateValue(index, e.target.checked, "is_mandatory");
                }
              }}
              id="activateAddProject"
              lineHeight="1vw"
              disable={actionType === "view"} // Disable the checkbox when in "view" mode
            />


          </div>

          {/* is_sales_can_change */}
          <div className={"col-9 mb-3"}>
            <CheckBoxField
              name={"sales_agent" + salaryDataArray.sb_main_id}
              label={
                t(
                  "Allow sales agent to update the value during creation of cooperation agreement"
                ) + "?"
              }
              className="my-checkbox"
              isChecked={
                salaryDataArray.is_sales_can_change == true
              }
              onChangeHandler={(e) => {
                updateValue(index, e.target.checked, "is_sales_can_change");
              }}
              id="activateAddProject"
              lineHeight="1vw"
              disable={actionType == "view"}
            />
          </div>

          {/* start date */}
          <div className="col-4">
            <LabelField title={t("Start date")} />
            <DateField
              id={"date"}
              placeholder={"date"}
              handleChange={(e) => {
                updateValue(index, e.target.value, "start_date");
              }}
              style={{ marginLeft: "0.8rem" }}
              className="date_field_salary_benefits"
              value={
                salaryDataArray.selected == "0"
                  ? new Date()
                  : salaryDataArray.start_date
              }
              isDisabled={actionType == "view"}
            />
            <p style={{ color: "red" }} className="error">
              {salaryDataArray.date_err}
            </p>
          </div>

          {/* end date */}
          <div className="col-4">
            <LabelField title={t("End date")} />

            <DateField
              id={"date"}
              placeholder={"date"}
              handleChange={(e) => {
                updateValue(index, e.target.value, "end_date");
              }}
              style={{ marginLeft: "0.8rem" }}
              className="date_field_salary_benefits"
              value={
                salaryDataArray.selected == "0"
                  ? new Date()
                  : salaryDataArray.end_date
              }
              isDisabled={actionType == "view"}
            />
            {errors[`endDate_${index}`] && (
              <p style={{ color: "red" }} className="error">
                {errors[`endDate_${index}`]}
              </p>
            )}
          </div>

          {/* occurence  */}
          <div className={"col-4 multiSelectWrapper"}>
            <LabelField title={t("Occurence")} />
            <MultiSelectField
              name={"occurence" + salaryDataArray.sb_main_id}
              id={"select_id"}
              options={options}
              standards={getOptionObj(salaryDataArray.occurence)}
              handleChange={(e) => {
                updateValue(index, e.value, "occurence");
              }}
              isMulti={false}
              className="w-100"
              disabled={actionType == "view"}
              classNamePrefix={`multiSelectDropdown`}
            />
            <p style={{ color: "red" }} className="error">
              {salaryDataArray.o_err}
            </p>
            {/* Nothing extra is displayed for occurence === 0 */}
          </div>
          <div className="col-12 mb-3">
            {/* Render CheckBoxField for occurence 1 or 2 */}
            {(salaryDataArray.occurence === 1 ||
              salaryDataArray.occurence === 2) && (
                <div className={salaryDataArray.occurence === 2 ? "row" : ""}>
                  <div className={salaryDataArray.occurence === 2 ? "col-6" : ""}>
                    <CheckBoxField
                      name={`is_mandatory${salaryDataArray.is_skip_absence}`}
                      label={
                        t(
                          "Is the benefit granted in case of absence of the employee"
                        ) + "?"
                      }
                      className="my-checkbox"
                      isChecked={salaryDataArray.is_skip_absence}
                      onChangeHandler={(e) =>
                        updateValue(index, e.target.checked, "is_skip_absence")
                      }
                      id="activateAddProject"
                      lineHeight="1vw"
                      disable={actionType === "view"}
                    />
                  </div>

                  {/* Conditionally render InputTextfield for occurence 2 */}
                  {salaryDataArray.occurence === 2 && (
                    <div className="col-6">
                      <LabelField title={t("Amount")} />
                      <InputTextfield
                        value={salaryDataArray.amount}
                        type="text"
                        handleChange={(e) =>
                          updateValue(index, e.target.value, "amount")
                        }
                        disabled={actionType === "view"}
                        placeholder={t("Enter amount")}
                      />
                      {errors[`amount_${index}`] && (
                        <p style={{ color: "red" }} className="error">
                          {errors[`amount_${index}`]}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              )}

            {/* Render InputTextfield for occurence 3 or 4 */}
            {(salaryDataArray.occurence === 3 ||
              salaryDataArray.occurence === 4) && (
                <>
                  <LabelField title={t("Amount")} />
                  <InputTextfield
                    type="text"
                    value={salaryDataArray.amount}
                    handleChange={(e) => updateValue(index, e.target.value, "amount")}
                    placeholder={t("Enter amount")}
                    disabled={actionType === "view"}
                  />
                  {errors[`amount_${index}`] && (
                    <p style={{ color: "red" }} className="error">
                      {errors[`amount_${index}`]}
                    </p>
                  )}
                </>
              )}
          </div>
        </div>
      </div>
    );
    return tableHtmlContent;
  };

  return (
    <>
      <Header

        headerName={`${actionType == "view" ? "View" : "Edit"} salary benefit`}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-3">

        <BackLink
          backLink={() => {

            if (actionType == "view") {
              navigate("/viewpc/" + pc_unique_key);

            } else {
              navigate("/editpc/" + pc_unique_key);
            }
          }}
        />

      </div>
      <form onSubmit={Submit} className="managepagesBg">
        <div className="row ">
          <div className="col-md-12">
            <div className={`${styles["salary-benefits-tab-parent"]}`}>
              <div>
                <div className={""}>
                  {salaryBenefits.map((val, index) =>
                    val.selected === "1"
                      ? showEachBenifitDetails(val, index)
                      : ""
                  )}
                </div>
              </div>
              {actionType != "view" && (
                <div className="row">
                  <div className="col-md-12 multiSelectWrapper">
                    <MultiSelectField
                      options={updateBenefitList()}
                      handleChange={onSelect}
                      isMulti={true}
                      standards={[]}
                      classNamePrefix={`multiSelectDropdown`}
                      placeholder={t("Select benefit")}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="text-end col-md-12 mt-3">
            <button
              // onClick={()=>Submit}
              type="submit"
              className="searchBtn shadow-none"
            >
              {t("SAVE")}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
export default translate(SalaryBenefits);
