import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IconWithTitle from "../molecules/IconWithTitle";
import { t } from "./translation";
import { Overlay, Popover } from "react-bootstrap";
import LabelField from "./LabelField";

interface SidebarItem {
  name: string;
  title: string;
  path?: string;
  submenu?: { name: string; title: string; path: string }[];
}

const sidebarItems: SidebarItem[] = [
  { name: "dashboard", title: "Dashboard", path: "/location-dashboard" },
  { name: "Planning", title: "Planning", path: "/day-planning-overview" },
  {
    name: "timeRegistration",
    title: "Time registration",
    path: "/day-planning-overview",
    submenu: [
      { name: "open", title: "Open", path: "/time-registration" },
      { name: "closed", title: "Closed", path: "/time-registration" },
    ],
  },
  {
    name: "documentation",
    title: "Documents",
    path: "/none",
    submenu: [
      { name: "invoices", title: "Invoices", path: "/invoice-overview" },
      { name: "contracts", title: "Contracts", path: "/employee-contracts" },
      { name: "documents", title: "Documents", path: "/documents-overview" },
    ],
  },
  { name: "ManageCompanies", title: "Manage companies", path: "/welcome-dashboard" },
  { name: "employeeGroup", title: "Your pool", path: "/employee-overview" },
  { name: "accountIcon", title: "Account", path: "/my-account" },
];

const Sidebar: React.FC = () => {
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleBackdropClick = () => setShow(false);

    if (show) {
      document.body.style.overflow = "hidden";
      const backdrop = document.createElement("div");
      backdrop.className = "overlay-backdrop";
      backdrop.addEventListener("click", handleBackdropClick);
      document.body.appendChild(backdrop);

      return () => {
        document.body.style.overflow = "";
        backdrop.removeEventListener("click", handleBackdropClick);
        document.body.removeChild(backdrop);
      };
    }
  }, [show]);

  const handleItemClick = (
    event: React.MouseEvent<HTMLElement>,
    item: SidebarItem
  ) => {
    setActiveItem(item.name);
    if (item.submenu) {
      setTarget(event.currentTarget);
      setShow((prev) => !prev);
    } else {
      setShow(false);
      navigate(item.path || "/default-path");
    }
  };

  const renderSidebarItem = (item: SidebarItem, index: number) => {
    const isActive = item.name === activeItem;
    const itemClassName = isActive
      ? "sidebarItemWrapper active"
      : "sidebarItemWrapper";

    return (
      <div
        key={index}
        className={itemClassName}
        ref={item.name === "documentation" ? ref : null}
      >
        <span
          onClick={(event) => handleItemClick(event, item)}
          className="cursor-pointer"
        >
          <div
            className={`col-12 sidebarContent text-center sidebarBorder ${
              isActive ? "active" : ""
            }`}
          >
            <IconWithTitle
              name={item.name}
              isIcon={true}
              isTitle={true}
              width="10vw"
              height="2vw"
              className="img-fluid mx-auto"
              title={t(item.title)}
              labelClassName={`text-white sidebarTitle cursor-pointer ${
                isActive ? "activeTitle" : ""
              }`}
            />
          </div>
        </span>
      </div>
    );
  };
  const handleSubmenuClick = (path: string, subitemName?: string) => {
    setShow(false);

    // Determine the state based on subitemName
    const state =
      subitemName === "closed"
        ? { activeTab: "menu1" }
        : subitemName === "open"
        ? { activeTab: "home" }
        : undefined;

    navigate(path, { state });
  };

  const renderSubmenu = () => {
    const activeItemObj = sidebarItems.find(
      (item) => item.name === activeItem && item.submenu
    );

    return activeItemObj?.submenu?.map((subitem, subindex) => (
      <span
        key={subindex}
        className="submenuItem d-block px-5 py-1 text-center cursor-pointer"
        onClick={() => handleSubmenuClick(subitem.path, subitem.name)}
      >
        <LabelField
          title={subitem.title}
          className="cursor-pointer poppins-medium colorPrimary"
        />
      </span>
    ));
  };
  return (
    <div className="sideBar">
      <div className="scrollBar h-100 overflow-auto">
        <div
          className="col-12 sidebarContent sidebarBorder"
          style={{ height: "6vw" }}
        >
          {/* Sidebar Header or Branding */}
        </div>
        {sidebarItems.map(renderSidebarItem)}

        <Overlay
          show={show}
          target={target}
          placement="right"
          container={ref.current}
          containerPadding={20}
        >
          <Popover id="popover-basic">
            <Popover.Body>{renderSubmenu()}</Popover.Body>
          </Popover>
        </Overlay>
      </div>
    </div>
  );
};

export default Sidebar;
