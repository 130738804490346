import React, { useEffect, useState, useMemo } from "react";
import Icon from "../../Icon";
import DateAndTime from "../common/atoms/DateWithTime";
import InputTextfield from "../common/atoms/InputTextField";
import OverlayTooltip from "./TypeBasedPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  selectContractType,
  selectLocationId,
  selectTab,
  setScheduleErrors,
  updateEmployeeSchedule,
} from "../../features/planning/planningSlice";
import {
  formatTimeInput,
  updateSchedule,
  calculateTotalTime,
  formatDateTimeInputToUTC,
  extractTimeFromUTCString,
} from "../common/utlis/PlanningTimingHelper";
import { t } from "../CentralDataMangement/translation/Translation";
import { validateShift } from "./Validations/PlanningValidations";

interface Shifts {
  start_time: string;
  end_time: string;
  sequence: number;
}

interface Schedule {
  location_id: number;
  project_id?: number;
  cost_center?: number;
  shifts: Shifts[];
  error?: string | string[];
  warning?: string | string[];
}

interface ScheduleMap {
  [date: string]: Schedule;
}

interface Props {
  employee: number;
  planningSchedule: ScheduleMap;
}

const PlanningShiftsTimings: React.FC<Props> = ({
  employee,
  planningSchedule,
}) => {
  const defaultShift = useMemo(
    () => ({ start_time: "", end_time: "", sequence: 2 }),
    []
  );
  const dispatch = useDispatch();
  const [schedule, setSchedule] = useState<ScheduleMap>(planningSchedule || {});
  const [validationErrors, setValidationErrors] = useState<
    Record<string, boolean>
  >({});
  const locationId = useSelector(selectLocationId);
  const contractType = useSelector(selectContractType);

  const tab = useSelector(selectTab);

  useEffect(() => {
    setSchedule(planningSchedule || {});
  }, [planningSchedule]);

  const handleShiftChange = (
    date: string,
    index: number,
    key: keyof Shifts,
    value: string
  ) => {
    const formattedValue = formatTimeInput(value);
    setSchedule((prevSchedule) => {
      const newSchedule = prevSchedule[date].shifts.map((shift, i) => {
        if (i === index) {
          const updatedShift = { ...shift, [key]: formattedValue };
          const isValid = validateShift(updatedShift);
          const updatedSchedule = {
            ...prevSchedule[date],
            error: "",
            shifts: prevSchedule[date].shifts.map((s, shiftIndex) =>
              shiftIndex === i ? updatedShift : s
            ),
          };
          dispatch(setScheduleErrors({ employee_id: employee, date: '', errors: [] }));
          // Update the schedule with the new shift and error state
          updateSchedule(
            date,
            updatedSchedule,
            schedule,
            setSchedule,
            employee,
            dispatch,
            updateEmployeeSchedule
          );
          return updatedShift;
        }
        return shift;
      });
      return prevSchedule;
    });
  };

  const handleAddShift = (date: string) => {
    setSchedule((prevSchedule) => {
      const currentShifts = prevSchedule[date]?.shifts || [];
      const lastShiftLocationId = prevSchedule[date]?.location_id || locationId;

      const newShift: Shifts = {
        start_time: "",
        end_time: "",
        sequence: currentShifts.length + 1,
      };

      const newSchedule: Schedule = {
        shifts: [...currentShifts, newShift],
        location_id: lastShiftLocationId,
      };

      if (newSchedule.shifts.length <= 2) {
        updateSchedule(
          date,
          newSchedule,
          schedule,
          setSchedule,
          employee,
          dispatch,
          updateEmployeeSchedule
        );
      }

      return { ...prevSchedule, [date]: newSchedule };
    });
  };

  const handleRemoveShift = (date: string, index: number) => {
    setSchedule((prevSchedule) => {
      const daySchedule = prevSchedule[date];

      // Check if daySchedule exists
      if (!daySchedule) return prevSchedule;

      // Create a new array for the shifts with the selected shift removed
      const updatedShifts = daySchedule.shifts.filter((_, i) => i !== index);

      // Create a new schedule object with updated shifts
      const updatedSchedule: Schedule = {
        ...daySchedule,
        shifts: updatedShifts,
      };

      // Remove the entry if no shifts are left
      const newSchedule =
        updatedShifts.length > 0
          ? { ...prevSchedule, [date]: updatedSchedule }
          : { ...prevSchedule };
      if (updatedShifts.length === 0) delete newSchedule[date];

      if (employee) {
        dispatch(
          updateEmployeeSchedule({
            employee_id: employee,
            scheduleMap: newSchedule,
          })
        );
      }

      return newSchedule;
    });
  };

  // Function to handle project clicks
  const OnProjectClick = (projectId: number, type: number, date?: string) => {
    if (type === 1 && date) {
      // Update project ID for a specific day's schedule
      setSchedule((prevSchedule) => {
        const daySchedule = prevSchedule[date];
        if (daySchedule) {
          const updatedSchedule = {
            ...daySchedule,
            project_id: projectId, // Update project ID for that day
          };
          updateSchedule(
            date,
            updatedSchedule,
            schedule,
            setSchedule,
            employee,
            dispatch,
            updateEmployeeSchedule
          );
        }
        return prevSchedule;
      });
    } else if (type === 2) {
      // Update project ID for all schedules of the employee
      setSchedule((prevSchedule) => {
        const updatedSchedules: ScheduleMap = { ...prevSchedule };

        // Iterate over each schedule date and update the project_id
        for (const scheduleDate in updatedSchedules) {
          if (updatedSchedules.hasOwnProperty(scheduleDate)) {
            updatedSchedules[scheduleDate] = {
              ...updatedSchedules[scheduleDate],
              project_id: projectId, // Update project ID for all schedules
            };

            // Update the schedule with the new project ID for each date
          }
        }
        if (employee) {
          dispatch(
            updateEmployeeSchedule({
              employee_id: employee,
              scheduleMap: updatedSchedules,
            })
          );
        }
        return updatedSchedules; // Return the updated schedule
      });
    }
  };

  const handleCloneDates = (dates: string[]) => {
    // TO DO: implement the logic to clone the dates
    console.log("Clone dates:", dates);
  };

  const shiftCells = (daySchedule: Shifts[], date: string) => (
    <>
      {daySchedule.map((shift, index) => (
        <td key={index} className="bodyBg align-middle">
          <div className="col-12 d-flex align-items-center">
            <InputTextfield
              value={shift.start_time}
              // value={extractTimeFromUTCString(shift.start_time)}
              className={`form-control employeeDailyOverviewTable ${validationErrors[`${date}-${index}`] ? "error-border" : ""
                }`}
              handleChange={(e) =>
                handleShiftChange(date, index, "start_time", e.target.value)
              }
              isDisabled={tab === "tab_3"}
            />
            <div className="mx-1">{" - "}</div>
            <InputTextfield
              // value={extractTimeFromUTCString(shift.end_time)}
              value={shift.end_time}
              className={`form-control employeeDailyOverviewTable ${validationErrors[`${date}-${index}`] ? "error-border" : ""
                }`}
              handleChange={(e) =>
                handleShiftChange(date, index, "end_time", e.target.value)
              }
              isDisabled={tab === "tab_3"}
            />
          </div>
        </td>
      ))}
      {daySchedule.length < 2 && (
        <td className="bodyBg align-middle">
          <span
            data-bs-toggle="tooltip"
            title={"Add"}
            onClick={() => handleAddShift(date)}
          >
            <Icon
              name="add"
              width="0.8vw"
              height="0.8vw"
              fillColor="#8571b9"
              isIcon={true}
            />
          </span>
        </td>
      )}
      <td className="bodyBg align-middle">
        <div className="totalHours text-white poppins-medium">
          {calculateTotalTime(daySchedule)}
        </div>
      </td>
    </>
  );

  return (
    <>
      {Object.keys(schedule).length > 0 ? (
        <table className="shiftTimingTable table table-borderless mb-0">
          <thead>
            <tr>
              <th></th>
              <th>{t("Shift 1")}</th>
              <th>{t("Shift 2")}</th>
              <th className="text-start">{t("Total")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(schedule).map(([day, daySchedule]) => (
              <React.Fragment key={day}>
                <tr
                  className={`spaced-row shiftTimingTableData ${daySchedule.error ? "error-border-card" : ""
                    }`}
                >
                  <td className="p-0">
                    <DateAndTime
                      isDay={true}
                      isDate={true}
                      showDate={true}
                      dataTimeClassName="d-flex flex-column align-items-center shiftDate"
                      monthFormat="short"
                      value={day}
                    />
                  </td>
                  {shiftCells(daySchedule.shifts, day)}

                  <td className="actionWrapper bodyBg align-middle">
                    <div className="d-flex">
                      {tab === "tab_3" ? (
                        <>
                          <span data-bs-toggle="tooltip" title={t("Edit")}>
                            <Icon
                              name="EditIcon"
                              width="0.8vw"
                              height="0.8vw"
                              fillColor="#8571b9"
                              className="me-2 cursor-pointer"
                              isIcon={true}
                            />
                          </span>
                          <span
                            data-bs-toggle="tooltip"
                            title={t("Remove shift")}
                            onClick={() =>
                              handleRemoveShift(
                                day,
                                daySchedule.shifts.length - 1
                              )
                            }
                            className="me-2"
                          >
                            <Icon
                              name="RemoveIcon"
                              width="0.8vw"
                              height="0.8vw"
                              fillColor="#8571b9"
                              className="cursor-pointer"
                              isIcon={true}
                            />
                          </span>
                        </>
                      ) : (
                        <>
                          <OverlayTooltip
                            tooltipText="Projects"
                            selectedProject={(
                              projectId: number,
                              type: number
                            ) => OnProjectClick(projectId, type, day)}
                            selectedId={daySchedule.project_id} // Pass `day` here
                          >
                            <Icon
                              name="locationIcon"
                              width="0.8vw"
                              height="0.8vw"
                              className="cursor-pointer"
                              isIcon={true}
                            />
                          </OverlayTooltip>
                          {contractType == "month" && (
                            // <OverlayTooltip tooltipText="Clone" selectedCloneDates={(dates: string[]) => handleCloneDates(dates)}>
                            <OverlayTooltip tooltipText="Clone">
                              <Icon
                                name="CloneIcon"
                                width="0.8vw"
                                height="0.8vw"
                                className="cursor-pointer"
                                fillColor="#8571b9"
                                isIcon={true}
                              />
                            </OverlayTooltip>
                          )}
                          <OverlayTooltip tooltipText="Shifts">
                            <Icon
                              name="shiftsIcon"
                              width="0.8vw"
                              height="0.8vw"
                              fillColor="#8571b9"
                              className="cursor-pointer"
                              isIcon={true}
                            />
                          </OverlayTooltip>
                          <span
                            data-bs-toggle="tooltip"
                            title={t("Remove shift")}
                            onClick={() =>
                              handleRemoveShift(
                                day,
                                daySchedule.shifts.length - 1
                              )
                            }
                            className="me-2"
                          >
                            <Icon
                              name="RemoveIcon"
                              width="0.8vw"
                              height="0.8vw"
                              fillColor="#8571b9"
                              className="cursor-pointer"
                              isIcon={true}
                            />
                          </span>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
                {(daySchedule.warning || daySchedule.error) && (
                  <tr>
                    <td colSpan={5} className="p-0 pt-2">
                      <div
                        className={`alert alert-dismissible fade show mb-0 p-2 ${daySchedule.error ? "alert-danger" : "alert-warning "}`}
                        role="alert"
                        aria-colspan={5}
                      >
                        {daySchedule.warning
                          ? daySchedule.warning
                          : daySchedule.error}
                        <button
                          type="button"
                          className="btn-close p-2"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                      </div>
                    </td>
                  </tr>
                )}
                <tr>
                  <td
                    colSpan={5}
                    style={{ height: "0.7vw" }}
                    className="emptyRow p-0"
                  ></td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-schedule-message text-danger text-center">
          {t("No schedule for current employee.")}
        </div>
      )}
    </>
  );
};

export default PlanningShiftsTimings;
