import React, { useEffect, useState } from "react";
// import './App.css';
import { Routes } from "./routes/Routes";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store"; // Import your Redux store and persistor
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApiCall } from "./services/ApiServices";
import { ABSOLUTE_CORE_APP, CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from './Constants';
import secureLocalStorage from "react-secure-storage";
import LoadingOverlay from "./components/common/utlis/LoadingOverlay";
import { getLanguage, setLanguageChangeCallback, setTranslations, setDefaultLanguage } from './components/CentralDataMangement/translation/Translation';


function App() {
  const [rerenderKey, setRerenderKey] = useState(0);

  useEffect(() => {
    setDefaultLanguage(secureLocalStorage.getItem('site_lang') ?? 'en');
    fetchTranslations(getLanguage());
    setLanguageChangeCallback(handleLanguageChange); // Set the callback
  }, [rerenderKey]);

  const handleLanguageChange = (lang: any) => {
    // Fetch translations when language changes
    fetchTranslations(lang);
    setRerenderKey((prevKey) => prevKey + 1);
    // setForceUpdate(prev => prev + 1);
  };
  
  const fetchTranslations = async (lang_key: any) => {
    const response = await ApiCall.service(
      `translationsByLanguage`,
      "POST",
      {
        app_name: ABSOLUTE_CORE_APP,
        lang: lang_key,
      },
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    setTranslations(response.translations);
  }


  return (
    <>
      {/* {/ Redux Provider wraps the entire app, making the Redux store available to all components /} */}
      <Provider store={store}>
        {/* PersistGate delays the rendering of your app's UI until your persisted state has been retrieved
            and saved to redux. 'loading={null}' means nothing is displayed while loading. */}
        <PersistGate loading={null} persistor={persistor}>
          {/* {/ Custom LoadingOverlay component, initially hidden /} */}
          <LoadingOverlay display="none" />

          {/* RouterProvider sets up the routing for your app using the routes defined in 'Routes'
              The 'key' prop with 'rerenderKey' is used to force a re-render of the router when needed */}
          <RouterProvider router={Routes} key={rerenderKey} />

          {/* ToastContainer is likely from a library like react-toastify, used for displaying notifications
              'limit={1}' restricts the number of toasts shown at once to 1 */}
          <ToastContainer limit={1} />
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;