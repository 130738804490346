import React, { useState, useEffect } from "react";
import { LOCATIONS } from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import Header from "../../common/layout/Header";
import { t, translate } from "../translation/Translation";
import Icon from "../../../Icon";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import BackLink from "../../common/atoms/BackLink";
import LinkCustom from "../../common/atoms/Link";
import { Link } from "react-router-dom";
import InputTextfield from "../configurations/manageCoefficientDeviations/InputTexfield";
import ResetBtn from "../../common/atoms/ResetBtn";
import SearchBtn from "../../common/atoms/SearchBtn";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../features/auth/AuthSlice";
import Pagination from "../../common/atoms/Pagination";

interface Location {
  id: number;
  name: string;
  location_image?: {
    file_url: string;
  } | null;
}

const ManageLocations: React.FC = () => {
  const user = useSelector(selectAuth).userId;
  const [locations, setLocations] = useState<Location[]>([]); // State to store locations
  const [filteredLocations, setFilteredLocations] = useState<Location[]>([]); // State for filtered locations
  const [searchTerm, setSearchTerm] = useState<string>(""); // State for search input
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state

  // Fetch locations from API
  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await ApiCall.getService(
          `${LOCATIONS}`,
          "GET",
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response.statusCode === 200) {
          const locationsData = response.data.map((item: any) => ({
            id: item.id,
            name: item.name,
            location_image: item.location_image ? { file_url: item.location_image.file_url } : null,
          }));

          setLocations(locationsData);
          setFilteredLocations(locationsData); // Initially set filteredLocations to all locations
          setLoading(false);
        }
      } catch (error) {
        setError("Failed to fetch locations");
        setLoading(false);
      }
    };

    fetchLocations();
  }, []);

  // Handle input change for search
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value); // Only store the search term but don't filter yet
  };

  // Handle reset button click to clear search
  const handleResetClick = () => {
    setSearchTerm("");
    setFilteredLocations(locations); // Reset to original locations
  };

  // Handle search button click
  const handleSearchClick = () => {
    // Filter locations based on the search term
    const filteredData = locations.filter((location) =>
      location.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredLocations(filteredData);
  };

  return (
    <>
      <Header
        headerName={t("Manage locations")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-4">
        <div className="row mb-2">
          <BackLink backLink="/" />
          <div className="col-6 text-end">
            <Link to="/location/add">
              <div className="searchBtn d-inline-flex align-items-center justify-content-center text-decoration-none w-auto px-3">
                {t("Add location")}
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <InputTextfield
              value={searchTerm}
              handleChange={handleInputChange}
              placeholder={t("Location")}
              className="ps-2"
            />
          </div>
          <div className="col-4">
            <ResetBtn handleResetClick={handleResetClick} />
            <SearchBtn handleSearchClick={handleSearchClick} />
          </div>
        </div>
      </div>
      <div className="managepagesBg">
        <OverFlowWrapper className="table-responsive height-25">
          <table className="table table-hover customTableProperties">
            <thead>
              <tr className="TableHeader">
              <th style={{ width: "60%" }}>{t("")}</th>
                <th style={{ width: "60%" }}>{t("Location Name")}</th>
                <th>{t("Action")}</th>
              </tr>
              <tr>
                <th
                  className="emptyRow p-0"
                  style={{ height: "0.7vw" }}
                  colSpan={3}
                ></th>
              </tr>
            </thead>
            <tbody>
              {filteredLocations.map((location) => (
                <tr key={location.id} className="border">
                   <td>
                    {location.location_image ? (
                      <img
                        src={location.location_image.file_url}
                        alt={location.name}
                        style={{ width: "50px", height: "50px" }}
                      />
                    ) : (
                      <span>{t("No Image Available")}</span>
                    )}
                 </td>
                  <td>{location.name}</td>
                  <td>
                    {/* Edit link using your custom Link component */}
                    <LinkCustom
                      href={`/location/edit/${location.id}`}
                      className="secondaryColorHoverEffect"
                    >
                      <span title={t("Edit location")}>
                        <Icon {...IconProps} name="EditIcon" />
                      </span>
                    </LinkCustom>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </OverFlowWrapper>
        {/* {pageCount > 1 && (
          <div className="pagination justify-content-center mt-4 px-4">
            <Pagination
              currentPage={currentPage}
              totalPages={pageCount}
              onPageChange={handlePageClick}
            />
          </div>
        )} */}
      </div>
    </>
  );
};

export default translate(ManageLocations);
