import React, { useEffect, useState } from 'react';
import { ApiCall } from '../../../services/ApiServices';
import { GET_SALARY_BENIFITS } from '../../../routes/ApiEndpoints';
import { useNavigate, useParams } from 'react-router-dom';
import CustomNotify from '../../common/atoms/CustomNotify';
import { t } from '../translation/Translation';
import Icon from '../../../Icon';
import { FullScreenLoading } from '../../common/utlis/FullScreenLoading';
import Header from '../../common/layout/Header';
import { MANAGE_SALARY_BENEFITS } from '../../../routes/RouteConstants';
import SelectWithSearch from '../../common/atoms/SelectWithSearch';
import { PcOptionType } from '../../Dashboard/services/CompanyInterface';
import LabelField from '../../common/atoms/LabelField';
import MultiSelectField from '../../common/atoms/MultiSelectField';

function SalaryBenefitForm() {
  const [data, setData] = useState({
    salaryBenefitName: 'Betaalde feestdag',
    startDate : '2023-01-10',
    endDate: '2023-01-10',
    coefficientType: '0',
    coefficientValue: '1',
    occurrence: '',
    occurrenceValue: '',
    absenceBenefit: false,
    loading: false,    
  });

  const navigate = useNavigate();

  const handleRadioChange = (event: any) => {
    setData((prevState: any) => ({
      ...prevState,
      coefficientType: event.target.value,
      coefficientValue: event.target.value === '0' ? [] : prevState.coefficientValue, // Reset if switching types
    }));
  };

  const handleAbsenceRadioChange = (event:any) => {
    setData((prevState) => ({
      ...prevState,
      absenceBenefit: event.target.value,
    }));
  };

  const handleChange = (event:any) => {
    const { name, value, selected } = event.target;
    switch (name) {
      case 'start_date':
        setData((prevState: any) => ({
          ...prevState,
          startDate : value,
        }));
        break;
      case 'end_date':
        setData((prevState: any) => ({
          ...prevState,
          endDate : value,
        }));
        break;
      case 'coefficient_value':
        setData((prevState) => ({
          ...prevState,
          coefficientValue : value,
        }));
        break;
      case 'occurrence':
        setData((prevState:any) => ({
          ...prevState,
          occurrence : value,
        }));
        break;
      default:
        break;
    }
  };

  const handleCoefficientChange = (selected: any) => {
    const selectedValue = selected ? selected.value : null; 
  
    setData((prevState) => ({
      ...prevState,
      coefficientValue: selectedValue,
    }));
  };
  
  const { id } = useParams(); // Get the ID from the URL

  useEffect(() => {
    if (id) {
      fetchData();
    }  
  }, [id]);

  const fetchData = async () => {
    let url = `${GET_SALARY_BENIFITS}/${id}`
    try {
      const response = await ApiCall.getService(
        url,
        "GET",
        "central-data-management"
      );
      if (response.success) {
        setData((prevState: any) => ({
          ...prevState,
          salaryBenefitName: response?.data?.name,
          startDate: response?.data?.start_date,
          endDate: response?.data?.end_date,
          occurrence: response?.data?.occurence,
          occurrenceValue: response?.data?.occurence_value,
          absenceBenefit: response?.data?.is_skip_absence,
          coefficientType: response?.data?.applicable_coefficient_type,
          coefficientValue: response?.data?.coefficient_value
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSubmit = async (e: any) => {
    let url = `${GET_SALARY_BENIFITS}/${id}`;
   
    const dataObj = {
      salary_benefits_id: id,
      startdate: data.startDate,
      enddate: data.endDate,
      occurance: data.occurrence,
      occurance_value: data.occurrenceValue, 
      coefficient_type: data.coefficientType,
      coefficient_value: data.coefficientValue,
      is_holiday_applicable: data.absenceBenefit, 
      bbright_perfcode_id: 1
    };

    try {
      setData((prevState: any) => ({
        ...prevState,
        loading: true,
      }));
      const response = await ApiCall.service(
        url,
        "PUT",   
        dataObj
      );
 
      if (response?.status === 200) {
        if (response.success) {
          setData((prevState: any) => ({
            ...prevState,
            startDate: response?.data?.startdate,
            endDate: response?.data?.enddate,
            occurrence: response?.data?.occurance,
          }));
        }
       
        CustomNotify({ message: t("Salary benefits successfully updated"), type: "success", autoClose: 3500 });
        // navigate(`${MANAGE_SALARY_BENEFITS}`);
      } else {
        CustomNotify({ message: t("Failed to update the data. Please try again."), type: "error", autoClose: 3500 });
      }
    } catch (error) {
      console.error("Error submitting report:", error);
      CustomNotify({ message: t("An error occurred while updating the data."), type: "error", autoClose: 3500 });
    } finally {
      setData((prevState: any) => ({
        ...prevState,
        loading: false,
      }));
    }
  };
  

  const handleoccurenceValue = (e: any) => {
    setData((prevState: any) => ({
      ...prevState,
      occurrenceValue: e.target.value,
    }));      
  };
  console.log(data.absenceBenefit);
  const options = [
    { value: "coef_reduced", label: "Coef Reduced" },
    { value: "coef_transport", label: "Coef Transport" },
    { value: "coef_holiday", label: "Coef Holiday" },
    { value: "coef_holiday_reduced", label: "Coef Holiday Reduced" },
    { value: "coef_mtc", label: "Coef MTC" },
    { value: "coef_payroll", label: "Coef Payroll" },
    { value: "coef_meal_vouchers", label: "Coef Meal Vouchers" },
    { value: "coef_selection", label: "Coef Selection" },
    { value: "coef_holiday_payroll", label: "Coef Holiday Payroll" },
    { value: "dimona_cost", label: "Dimona Cost" },
    { value: "coef_eco", label: "Coef Eco" },
  ];

    return (
    <div className="container-fluid p-0">   
      <>
        <Header headerName="Manage Salary Benefits Edit" headerClassName="myAccountCardTitle" />
        <div className="row p-0 m-0">
          <div className="col-md-12 border-form-sec px-4">
            <div className="col-lg-6 col-md-10 pb-2 pt-4">
              <label className="mb-2 poppins-regular-18px" htmlFor="name">
                Salary benefit name <span style={{ color: 'red' }}>* </span>
              </label>
              <input
                type="text"
                name="name"
                disabled
                className="form-control col-md-6 width-100 poppins-regular-18px border-4C4D554D rounded-0 shadow-none border-color-addeditsalary-benefits mb-2"
                placeholder="Please add salary benefit"
                value={data.salaryBenefitName}
              />
            </div>
            <div className='row'>
              <div className="pt-2 pb-4 col-lg-3 col-md-10">
                <label className="mb-3 poppins-regular-18px" htmlFor="start_date">
                  Start date
                </label>
                <input
                  type="date"
                  name="start_date"
                  min="2023-09-29"
                  max="2025-09-28"
                  className="form-control col-lg-6 col-md-10 salary-date poppins-regular-18px border-4C4D554D rounded-0 shadow-none border-color-addeditsalary-benefits"
                  value={data.startDate}
                  onChange={handleChange}
                />
              </div>

              <div className="pt-2 pb-4 col-lg-3 col-md-10">
                <label className="mb-3 poppins-regular-18px" htmlFor="end_date">
                  End date
                </label>
                <input
                  type="date"
                  name="end_date"
                  min="2023-09-29"
                  max="2025-09-28"
                  className="form-control col-lg-6 col-md-10 salary-date poppins-regular-18px border-4C4D554D rounded-0 shadow-none border-color-addeditsalary-benefits"
                  value={data.endDate}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 mx-0 px-0">
              <label className="poppins-regular-18px px-0" style={{ display: 'block', padding: '0.5rem 0rem', fontSize: '1rem', fontWeight: 400, color: 'rgb(33, 37, 41)' }}>
                Applicable coefficient
              </label>
              <label className="" style={{ cursor: 'pointer' }}>
                <input
                  type="radio"
                  name="coefficient"
                  id="coefficient-employee-type"
                  value="0"
                  checked={data.coefficientType === '0'}
                    onChange={() => {
                      setData((prevState) => ({
                        ...prevState,
                        coefficientType : '0',
                      }));
                  }}
                  style={{ margin: '8px 10px 8px 0px' }}
                />
                Based on employee type in the cooperation agreement
              </label>
              {data.coefficientType === '0' && 
                  <SelectWithSearch
                    search
                    options={options}
                    value={options.find(option => option.value === data.coefficientValue) || null}
                    onChange={handleCoefficientChange}
                    isMulti={false}
                    error=""
                    id="coefficient_value"
                    name="coefficient_value"
                    isDisabled={false}
                  />
            }
              <br />
              <label className="" style={{ cursor: 'pointer' }}>
                <input
                  type="radio"
                  name="coefficient_value"
                  id="coefficient-other"
                  value="1"
                  checked={data.coefficientType === '1'}
                  // checked={data.coefficientType === '1'}
                  onChange={handleRadioChange}
                  style={{ margin: '8px 10px 8px 0px' }}
                />
                Other
              </label>
              {data.coefficientType === "1" && <input
                type="text"
                name="coefficient_value"
                className="form-control col-md-12 poppins-regular-18px border-4C4D554D rounded-0 shadow-none border-color-addeditsalary-benefits"
                placeholder="Enter value"
                value={data.coefficientValue}
                onChange={handleChange}
              />}
              <div className="col-md-12 row mx-0 px-0 py-2">
                    <div className="col-md-12 mx-0 px-0">
                      <label className="poppins-regular-18px px-0" style={{ display: 'block', padding: '0.5rem 0rem', fontSize: '1rem', fontWeight: 400, color: 'rgb(33, 37, 41)' }}>
                        Occurrence
                      </label>
                      <select
                        name="occurrence"
                        className="form-control col-md-12 poppins-regular-18px border-4C4D554D rounded-0 shadow-none border-color-addeditsalary-benefits"
                        value={data.occurrence}
                        onChange={handleChange}
                      >
                        <option value="hourly">Hourly</option>
                        <option value="day">Day</option>
                        <option value="weekly">Weekly</option>
                        <option value="contract">Contract</option>
                      </select>
                    </div>             
              </div>
              { data.occurrence === 'day' && (
                  <div className="col-md-12 row mx-0 px-0 py-2">
                    <input
                      type="text"
                      name="occurrence_value"
                      className="form-control col-md-12 poppins-regular-18px border-4C4D554D rounded-0 shadow-none border-color-addeditsalary-benefits"
                      placeholder="Enter amount"
                      value={data.occurrenceValue}
                      onChange={handleoccurenceValue} 
                    />                
              
                    <div className="col-md-12 mx-0 px-0">
                      <label className="poppins-regular-18px px-0" style={{ display: 'block', padding: '0.5rem 0rem', fontSize: '1rem', fontWeight: 400, color: 'rgb(33, 37, 41)' }}>
                        Is the benefit granted in case of absence of the employee
                      </label>
                      <label className="" style={{ cursor: 'pointer' }}>
                        <input
                          type="radio"
                          name="absence"
                          id="absence-yes"
                          value="Yes"
                          checked={data.absenceBenefit === true}
                          onChange={handleAbsenceRadioChange}
                          style={{ margin: '8px 10px 8px 0px' }}
                        />
                        Yes
                      </label>
                      <br />
                      <label className="" style={{ cursor: 'pointer' }}>
                        <input
                          type="radio"
                          name="absence"
                          id="absence-no"
                          value="No"
                          checked={data.absenceBenefit === false}
                          onChange={handleAbsenceRadioChange}
                          style={{ margin: '8px 10px 8px 0px' }}
                        />
                        No
                      </label>
                    </div>
                  </div>
                )}

                  {
                    data.occurrence === "hourly" && 
                    <div className="col-md-12 mx-0 px-0">
                    <label className="poppins-regular-18px px-0" style={{ display: 'block', padding: '0.5rem 0rem', fontSize: '1rem', fontWeight: 400, color: 'rgb(33, 37, 41)' }}>
                      Is the benefit granted in case of absence of the employee
                    </label>
                    <label className="" style={{ cursor: 'pointer' }}>
                      <input
                        type="radio"
                        name="absence"
                        id="absence-yes"
                        value="true"
                        checked={data.absenceBenefit === true}
                        onChange={handleAbsenceRadioChange}
                        style={{ margin: '8px 10px 8px 0px' }}
                      />
                      Yes
                    </label>
                    <br />
                    <label className="" style={{ cursor: 'pointer' }}>
                      <input
                        type="radio"
                        name="absence"
                        id="absence-no"
                        value="false"
                        checked={data.absenceBenefit === false}
                        onChange={handleAbsenceRadioChange}
                        style={{ margin: '8px 10px 8px 0px' }}
                      />
                      No
                    </label>
                  </div>
                  }
                  {(data.occurrence === 'weekly' || data.occurrence === 'contract') && 
                    <input
                      type="text"
                      name="occurrence_value"
                      className="form-control col-md-12 poppins-regular-18px border-4C4D554D rounded-0 shadow-none border-color-addeditsalary-benefits"
                      placeholder="Enter amount"
                      value={data.occurrenceValue}
                      onChange={handleoccurenceValue} 
                    />
                  }
           
                <div className="row mt-3">
                  <div className="col-12 text-end">
                    <div
                      className="d-inline-flex align-items-center cursor-pointer secondaryColorHoverEffect sendText"
                      onClick={handleSubmit} // Call handleSubmit when clicked
                    >
                      <span>{data.loading ? t("Saving...") : t("Save")}</span>
                      <span className="ms-1">
                        <Icon
                          name="paperPlaneIcon"
                          isIcon={true}
                          width="0.8vw"
                          height="0.8vw"
                          className='cursor-pointer'
                        />
                      </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </>      
    </div>
  );
}

export default SalaryBenefitForm;
