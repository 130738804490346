import React, { useState, useEffect, useRef } from "react";
import Icon from "../../../Icon";

interface PlanningEmployeeSearchProps {
    placeholder?: string;
    value: string; // Expect an array of Employee objects
    onEmployeeSelect: (values: string) => void; // Callback to handle employee selection
}

const PlanningEmployeeSearch: React.FC<PlanningEmployeeSearchProps> = ({
    placeholder,
    value,
    onEmployeeSelect,
}) => {

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onEmployeeSelect) {
            onEmployeeSelect(event.target.value);
        }
    }

    return (
        <div className="d-flex flex-column position-relative">
            <input
                type="text"
                value={value}
                onChange={handleSearchChange}
                placeholder={placeholder}
                className="form-control"
            />
            <Icon
                name="searchIcon"
                fillColor="#8571b9"
                width="1vw"
                height="1vw"
                className="position-absolute top-50 end-0 translate-middle-y me-2"
                isIcon={true}
            />
        </div>
    );
};

export default PlanningEmployeeSearch;
