import React, { useEffect, useState } from "react";
import Header from "../../common/layout/Header";
import { t, translate } from "../translation/Translation";
import { InputText } from "primereact/inputtext";
import InputTextFieldWithLabel from "../../common/molecules/InputTextFieldWithLabel";
import FileUpload from "../../common/molecules/FileUpload";
import {
  LocationFormData,
  fileData,
  formatHouseNumber,
  formatPostalCode,
  formatStreet,
  inputFields,
  intialFileData,
} from "./LocationUtils";
import { ApiCall } from "../../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import {
  FILE_URL,
  GET_COMPANIES_BY_UID,
  GET_COUNTRIES,
  LOCATIONS,
  SHIFTS,
} from "../../../routes/ApiEndpoints";
import SelectWithSearch from "../../common/atoms/SelectWithSearch";
import CustomNotify from "../../common/atoms/CustomNotify";
import MultiSelectField from "../../common/atoms/MultiSelectField";
import BackLink from "../../common/atoms/BackLink";
import Button from "../../common/atoms/Button";
import "./location.css";
import CheckBoxField from "../../common/atoms/CheckBoxField";
import Image from "../../common/atoms/Image";
import { icons } from "../../Dashboard/WelcomeDashboard";
import RadioField from "../../common/atoms/RadioField";
// import CompanyRadioButtons from "../../common/atoms/CompanyRadioButtons";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../features/auth/AuthSlice";
import { Navigate, useNavigate, useParams } from "react-router-dom";

interface CompanyObj {
  value: number;
  label: string;
  // url: string;
}


const AddLocation: React.FC = () => {
  // const [locationName, setLocationName] = useState("");
  const user = useSelector(selectAuth).userId;

  const initialFormData: LocationFormData = {
    location_pic: "",
    location_name: "",
    street: "",
    number: "",
    postal_code: "",
    box: "",
    city: "",
    country: "",
    company_id: [],
  };

  interface CompanyObj {
    value: number;
    label: string;
    url: string;
  }

  const locationinputFields = inputFields;
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [fileDetails, setFileDetails] = useState<fileData>(intialFileData);
  const [formData, setFormData] = useState<LocationFormData>(initialFormData);
  const [selectedCompanies, setSelectedCompanies] = useState<any[]>([]);
  const [countries, setCountries] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [checkedItems, setCheckedItems] = useState<Set<number>>(new Set());
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [relatedCompanies, setRelatedCompanies] = useState<CompanyObj[]>([]);
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    getCountries();
    getCompanies();
  }, []);

  const getCountries = async () => {
    const response = await ApiCall.getService(
      `${GET_COUNTRIES}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status == 200) {
      console.log(response.data);
      setCountries(response.data);
    }
  };

  const getCompanies = async () => {
    console.log(`${GET_COMPANIES_BY_UID}/${user}`);
    const response = await ApiCall.getService(
      `${GET_COMPANIES_BY_UID}/${user}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    console.log(response);
    if (response.status == 200) {
      // Convert the data to match the CompanyObj interface
      const formattedCompanies = response.data.map((company: any) => ({
        value: company.id,
        label: company.name,
        url: company.profile_picture_url ?? "https://t3.ftcdn.net/jpg/02/55/86/12/360_F_255861239_viu6hRNcURzeKUoyPJBFOyTu1fA8aJtM.jpg",
      }));
      setRelatedCompanies(formattedCompanies);
    }
  };


  // Handle input change for text fields
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any,
    fieldKey: keyof LocationFormData
  ) => {

    let value: any = "";
    switch (fieldKey) {
      case "country":
        setSelectedCountry(e);
        value = e?.value || "";
        break;
      case "postal_code":
        value = formatPostalCode(e.target.value);
        break;
      case "number":
        value = formatHouseNumber(e.target.value);
        break;
      case "street":
        value = formatStreet(e.target.value);
        break;
      case "company_id":
        console.log("triggered company_id")
        let ids =  JSON.parse(e.target.value);
        value = [ids];
        console.log(value , "asd")
        // console.log("Array of company IDs:", value);
        // value = [e.value];
        setCompanies(e);
        break;
      default:
        value = e.target.value;
        break;
    }

    setFormData((prevData) => ({
      ...prevData,
      [fieldKey]: value,
    }));

    // Clear the error for the updated field
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldKey]: value ? "" : prevErrors[fieldKey], // Remove the error if value is not empty
    }));

  };

  console.log(formData);

  const handleFileUploadResponse = (uploadResponse: any, index: number) => {
    console.log(uploadResponse.response.data.fileId);
    setFileDetails({
      fileId: uploadResponse.response.data.fileId,
      fileName: uploadResponse.response.data.fileName,
      filePath: uploadResponse.response.data.fileUrl,
    });
    const fileUrl = uploadResponse.response["file_url"];
    setFormData((prevData) => ({
      ...prevData,
      location_pic: uploadResponse.response.data.fileId || "",
    }));
  };

  const handleFileDeleteResponse = (deleteResponse: any, index: number) => {
    console.log(deleteResponse);
    setFileDetails({
      fileId: 0,
      fileName: "",
      filePath: "",
    });
    setFormData((prevData) => ({
      ...prevData,
      location_pic: "",
    }));
  };

  const handleRadioClick = (index: number, companyId: number) => {
    if (selectedIndex === index) {
      // Reset selection
      setSelectedIndex(-1);
      setSelectedCompanies([]);
      // handleInputChange([], "company_id");
    } else {
      setSelectedIndex(index);
      setSelectedCompanies([companyId]);
      // handleInputChange([companyId], "company_id");
    }
  };


  const validateForm = () => {
    let errors: Record<string, string> = {};
    // Loop through the form fields to check for validation
    locationinputFields.forEach((field) => {
      if (field.isMandatory && !formData[field.name]) {
        console.log(!formData[field.name]);
        errors[field.name] = `${t(field.label)} is required`;
      }
    });

    return errors;
  };

  // Handle form submit (with validation)
  const handleSubmit = async (e: React.FormEvent) => {
    console.log(formData);
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return; // Stop the form submission if there are errors
    }
    try {
      let data = {
        data: {
          formData,
        },
      };
      const response = await ApiCall.service(
        LOCATIONS,
        "POST",
        formData,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.statusCode === 201) {
        CustomNotify({
          type: "success",
          message: t(response.message) || t(response.msg),
        });
        setFormData(initialFormData);
        setFileDetails(intialFileData);
        setTimeout(() => {
          navigate("/locations-manage");
        }, 1000);
      } else if (response.statusCode === 422 || response.statusCode === 500) {
        const errorData = response.data; // Assuming the error data comes in response.data
        let updatedErrors: Record<string, string> = {};

        // Iterate through the error object and set errors for each field
        Object.keys(errorData).forEach((field) => {
          updatedErrors[field] = errorData[field][0]; // Assign the first error message
        });

        // Update form errors state
        setFormErrors(updatedErrors);

        CustomNotify({
          type: "error",
          message: t(response.message) || t(response.msg),
        });
      }
    } catch (error) {
      console.error("Error saving locations:", error);
    }
  };

  return (
    <>
      <Header
        headerName={t("Add location")}
        headerClassName="myAccountCardTitle"
      />

      <div className="search-bar">
        <div className="row">
          <BackLink backLink="/locations-manage" />
        </div>
      </div>
      <div className="createpagesBg">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div
              className={`col-6 position-relative mb-3 uploadWithBorder ${fileDetails.filePath
                  ? "locationImageUploaded"
                  : "locationImageUpload"
                }`}
            >
              <div style={{ width: "fit-content", position: "relative" }}>
                <FileUpload
                  id="locations"
                  name="location_picture"
                  file_type={10}
                  isMandatory={false}
                  edit={false}
                  label={t("Upload")}
                  fileId={fileDetails.fileId}
                  fileName={fileDetails.fileName}
                  filePath={fileDetails.filePath}
                  multiple={false}
                  uploadPath="locations"
                  formats=".pdf,.docx,.doc,.jpg,.jpeg"
                  maxFileSize={10 * 1024 * 1024}
                  returnFileUploadErrors={(errorInfo) => {
                    // Handle file upload errors
                  }}
                  fileUploadResponse={handleFileUploadResponse}
                  deleteResponse={handleFileDeleteResponse}
                />
                {fileDetails.filePath ? (
                  <div>
                    <img
                      src={
                        fileDetails.filePath.startsWith("https")
                          ? fileDetails.filePath
                          : `${FILE_URL}/${fileDetails.filePath}`
                      }
                      alt={t("Uploaded")}
                      style={{
                        backgroundColor: "var(--color-white)",
                        borderRadius: "50%",
                        boxShadow:
                          "rgb(133, 113, 185) 0.052vw 0vw 0.521vw 0.156vw",
                        width: "10vw",
                        height: "10vw",
                        objectFit: "cover",
                      }}
                      width="50%"
                      height="50%"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row">
            {locationinputFields.map((field, index) =>
              field.type === "select" ? (
                <div className="col-4 multiSelectWrapper">
                  <SelectWithSearch
                    key={index}
                    search={true}
                    options={countries}
                    value={selectedCountry}
                    onChange={(e) => handleInputChange(e, field.name)}
                    placeHolder={field.placeholder}
                    isMulti={false}
                    className="country-select"
                    isTranslate={false}
                    isMandatory={field.isMandatory}
                    fieldLabel={t("Country")}
                    title={t("Country")}
                    error={formErrors[field.name]}
                  />
                </div>
              ) : (
                <div className={`${field.columnSize} mb-3`}>
                  <InputTextFieldWithLabel
                    key={index}
                    type="text"
                    placeholder={t(field.placeholder)}
                    label={t(field.label)}
                    value={formData[field.name]}
                    name={field.name}
                    id={field.id}
                    handleChange={(e) =>
                      handleInputChange(e, field.name as keyof LocationFormData)
                    }
                    isMandatory={field.isMandatory}
                    error={formErrors[field.name]}
                    isTop={true}
                    className="ps-2"
                  />
                </div>
              )
            )}
            <span>
              {t("To which company would you like to add this location:")}
            </span>
            <div className="d-flex flex-wrap">
              {relatedCompanies.map((icon, index) => (
                <div
                  key={index}

                  className={`radioButtonContainer ${
                    selectedIndex === index ? "border-class" : ""
                  } d-inline-block me-3`}
                  onClick={() => handleRadioClick(index, icon.value)}
                >
                <RadioField
                  name="addLocation"
                  value={icon.value}
                  id={`company-${index}`}
                  ischecked={selectedIndex === index}
                  disabled={false}
                  handleChange={(e) => handleInputChange(e, "company_id")}
                  label={
                    <div data-bs-toggle="tooltip" title={icon.label}>
                      <Image
                        src={icon.url}
                        imageWidth="3vw"
                        imageHeight="3vw"
                        imageBorderRadius="50%"
                      />
                      <span>{t(icon.label)}</span>
                    </div>
                  }
                  containerClassName="d-inline-flex"
                />
              </div>
            ))}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <Button
                type="submit"
                className="searchBtn float-end px-3"
                title={t("Save location")}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default translate(AddLocation);
