import React, { ChangeEvent, useState } from "react";
import Icon from "../../Icon";
import { t } from "../CentralDataMangement/translation/Translation";
import BackLink from "../common/atoms/BackLink";
import Button from "../common/atoms/Button";
import InputTextAreaField from "../common/atoms/InputTextAreaField";
import Title from "../common/atoms/Title";
import Header from "../common/layout/Header";
import FileUpload from "../common/molecules/FileUpload";
import { ApiCall } from "../../services/ApiServices";
import CustomNotify from "../common/atoms/CustomNotify";
import { useSelector } from "react-redux";
import { selectAuth } from "../../features/auth/AuthSlice";

const ReportIssue = () => {

  interface fileData {
    fileId: number | null;
    filePath: string;
    fileName: string;
    fileAbsoluteUrl: string;
  }

  const intialFileData: fileData = {
    fileId: 0,
    filePath: "",
    fileName: "",
    fileAbsoluteUrl: "",
  };

  const [description, setDescription] = useState("");
  const [fileDetails, setFileDetails] = useState<fileData>(intialFileData);
  const [loading, setLoading] = useState(false); // Optional: to track loading state
  const currentUser = useSelector(selectAuth);


  const handleFileUploadResponse = (uploadResponse: any, index: number) => {
    setFileDetails({
      fileId: uploadResponse.response.data.fileId,
      fileName: uploadResponse.response.data.fileName,
      filePath: uploadResponse.response.data.filePath,
      fileAbsoluteUrl: uploadResponse.response.data.fileUrl,
    });
  };

  const handleFileDeleteResponse = (deleteResponse: any, index: number) => {
    setFileDetails({
      fileId: null,
      fileName: "",
      filePath: "",
      fileAbsoluteUrl: "",
    });
  };

  const handleSubmit = async () => {
    if (!description.trim()) {
      CustomNotify({ message: t("Please provide a description of the issue."), type: "warning", autoClose: 3500 });
      return;
    }

    try {
      setLoading(true); // Start loading indicator
      const dataObj = {
        "company_id": currentUser.companyId ?? 1,
        "report_issue_category_id":1,
        "issue": description,
        "attachment":fileDetails.fileId,
        "reporter_role_id" : currentUser.roleId ?? 1,        
      };
      // Call the ApiService fileSendService
      const response = await ApiCall.fileSendService(
        "ReportIssues", // endpoint
        "POST", // method
        dataObj, // data object
        true, // loading indicator
        "central-data-management" // microService
      );

      if (response?.status == 200) {
        CustomNotify({message:t("Issue reported successfully!"),type:"success",autoClose:3500});
        setDescription(""); // Reset the form
        setFileDetails(intialFileData); // Clear the uploaded file
      } else {
        CustomNotify({message:t("Failed to report the issue. Please try again."),type:"error",autoClose:3500});
      }
    } catch (error) {
      console.error("Error submitting report:", error);
      CustomNotify({ message: t("An error occurred while reporting the issue."),type:"error",autoClose:3500 });
    } finally {
      setLoading(false); // End loading indicator
    }
  };

  return (
    <>
      <Header
        headerName={t("Report an issue")}
        headerClassName="myAccountCardTitle"
      />

      <div className="search-bar pb-3">
        <div className="row">
          <BackLink backLink="/" />
        </div>
      </div>
      <div className="createpagesBg">
        <div className="row">
          <div className="col-8 mx-auto">
            <Title
              className="myAccountCardTitle colorPrimary"
              title={t("Reporting a technical problem")}
            />
            <div className="mt-3 colorPrimary">
              {t(
                "If a feature or product is not working properly, you can provide feedback so we can improve AbsoluteCORE"
              )}
              .
            </div>
            <div className="mt-3 colorPrimary">
              <span className="poppins-medium">{t("PLEASE NOTE")}: &nbsp;</span>
              {t(
                "This is not live assistance. If you need urgent assistance please email payroll@absoluteyou.be"
              )}
            </div>
            <div
              className="mt-3 rounded-3 position-relative"
              style={{ backgroundColor: "#eeecf3", padding: "0.5vw 0.5vw 0.5vw" }}
            >
              <div className="bg-white rounded-3 reportTextArea">
                <InputTextAreaField
                  placeholder={
                    t(
                      "Give a brief explanation of what happened or what is not working"
                    ) + "."
                  }
                  rows={5}
                  className="p-2 bg-white shadow-none border-0"
                  value={description}
                  handleChange={(e)=> setDescription(e.target.value)}
                />
                <div className="reportIssue col-12" style={{ paddingBottom: "0.5vw", paddingLeft: "1vw" }}>
                  <FileUpload                    
                    id="report_issue"
                    name="Report_issue"
                    label="Upload"
                    file_type={14}
                    isMandatory={false}
                    edit={true}
                    fileId={fileDetails.fileId}
                    fileName={fileDetails.fileName}
                    filePath={fileDetails.filePath}
                    multiple={false}
                    uploadPath="Report_Issues"
                    formats=".pdf,.docx,.doc,.jpg,.jpeg"
                    maxFileSize={10 * 1024 * 1024}
                    returnFileUploadErrors={(errorInfo) => {
                      // Handle file upload errors
                      console.error("File upload error:", errorInfo);
                    }}
                    fileUploadResponse={handleFileUploadResponse}
                    deleteResponse={handleFileDeleteResponse}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 text-end">
                <div
                  className="d-inline-flex align-items-center cursor-pointer secondaryColorHoverEffect sendText"
                  onClick={handleSubmit} // Call handleSubmit when clicked
                >
                  <span>{loading ? t("Sending...") : t("Send")}</span>
                  <span className="ms-1">
                    <Icon
                      name="paperPlaneIcon"
                      isIcon={true}
                      width="0.8vw"
                      height="0.8vw"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportIssue;
