import React, { useEffect, useState } from "react";
interface ToggleButtonProps {
  value?: boolean; // Optional boolean value to set initial state
  onToggle?: (value: boolean) => void; // Optional function to handle toggle
  disabled?: boolean; // Optional boolean to disable the button

}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  value = false,
  onToggle = () => {},
  disabled = false, // Default to false

}) => {  var ToggleButton = require("react-toggle-button");
  const [isActive, setIsActive] = useState(value);
  useEffect(() => {
    setIsActive(value); // Sync internal state with the prop value
  }, [value]);
  const handleToggle = () => {
    if (disabled) return; // Do nothing if disabled
    const newValue = !isActive;
    setIsActive(newValue);
    onToggle(newValue); // Notify parent about the change
  };
  return (
    <ToggleButton
      value={isActive}
      onToggle={handleToggle}
      inactiveLabel=""
      colors={{
        active: {
          base: "#8571b9",
        },
        inactive: {
          base: "#898989",
        },
        activeThumb: {
          base: "#fff",
        },
        inactiveThumb: {
          base: "#fff",
        },
      }}
      inactiveThumbStyle={{
        boxShadow: "none",
      }}
    />
  );
};

export default ToggleButton;
