import React, { useState } from "react";
// import { t } from "../../../common/atoms/Constants";
import Image from "../../../common/atoms/Image";
import userIcon from "../../../../static/images/User.svg";
import { LabelWithInputField } from "../../../common/molecules/LabelWithInputField";
import LabelField from "../../../common/atoms/LabelField";
import ModalPopup from "../../../common/atoms/ModalPopup";
import ToggleButton from "../../../common/atoms/ToggleButton";
import SelectWithSearch from "../../../common/atoms/SelectWithSearch";
import Icon from "../../../../Icon";
import ShiftTiming from "../../../Planning/ShiftTiming";
import TimePicker from "../../../common/atoms/TimePicker";
import Header from "../../../common/layout/Header";
import {
  t,
  translate,
} from "../../../CentralDataMangement/translation/Translation";
import Button from "../../../common/atoms/Button";
// import {t , translate } from "../CentralDataMangement/translation/Translation";

const ProfilePage = () => {
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const inputFieldProps = {
    labelWrapperClassName: "col-4 align-self-center pe-0",
    colClassName: "col-8",
    className: "inputFieldColor",
  };

  const cardTitleProps = {
    className: "myAccountCardTitle colorPrimary mb-3",
  };

  const costCalculatorProps = {
    labelWrapperClassName: "col-7 align-self-center pe-0",
    colClassName: "col-5",
  };
  return (
    <>
      <Header
        headerName={t("My Account")}
        headerClassName="myAccountCardTitle"
      />
      <div className="row equal-cols myProfilePageWrapper scrollBar">
        <div className="col-4">
          <div className="contentBg mb-3">
            <LabelField title={t("Manage my account")} {...cardTitleProps} />
            <div
              className="addCompanyImage d-flex align-items-center justify-content-center cursor-pointer mb-3"
              onClick={() => {}}
            >
              <Image src={userIcon} imageWidth="3vw" imageHeight="3vw" />
            </div>
            <div className="row mb-3">
              <LabelWithInputField
                label={t("Function") + ":"}
                type="text"
                value={""}
                name=""
                id="function"
                {...inputFieldProps}
              />
            </div>
            <div className="row mb-3">
              <LabelWithInputField
                label={t("First name") + ":"}
                type="text"
                value={""}
                name=""
                id="fname"
                {...inputFieldProps}
              />
            </div>
            <div className="row mb-3">
              <LabelWithInputField
                label={t("Last name") + ":"}
                type="text"
                value={""}
                name=""
                id="lname"
                {...inputFieldProps}
              />
            </div>
            <div className="row mb-3">
              <LabelWithInputField
                label={t("Email") + ":"}
                type="email"
                value={""}
                name=""
                id="email"
                {...inputFieldProps}
              />
            </div>
            <div className="row mb-3">
              <LabelWithInputField
                label={t("Date of birth") + ":"}
                type="text"
                value={""}
                name=""
                id="dob"
                {...inputFieldProps}
              />
            </div>
            <div className="row mb-3">
              <LabelWithInputField
                label={t("NSSO no") + ":"}
                type="text"
                value={""}
                name=""
                id="nsso"
                {...inputFieldProps}
              />
            </div>
            <div className="row mb-3">
              <LabelWithInputField
                label={t("Language") + ":"}
                type="text"
                value={""}
                name=""
                id="language"
                {...inputFieldProps}
              />
            </div>
            <div className="row mb-3">
              <LabelWithInputField
                label={t("Street") + ":"}
                type="text"
                value={""}
                name=""
                id="street"
                {...inputFieldProps}
              />
            </div>
            <div className="row mb-3">
              <LabelWithInputField
                label={t("House no") + ":"}
                type="text"
                value={""}
                name=""
                id="houseno"
                {...inputFieldProps}
              />
            </div>
            <div className="row mb-3">
              <LabelWithInputField
                label={t("Postal code") + ":"}
                type="text"
                value={""}
                name=""
                id="postalcode"
                {...inputFieldProps}
              />
            </div>
            <div className="row mb-3">
              <LabelWithInputField
                label={t("Country") + ":"}
                type="text"
                value={""}
                name=""
                id="country"
                {...inputFieldProps}
              />
            </div>
            <div className="row mb-3">
              <LabelWithInputField
                label={t("Mobile no") + ":"}
                type="text"
                value={""}
                name=""
                id="mobileno"
                {...inputFieldProps}
              />
            </div>
          </div>
          <div className="contentBg">
            <LabelField title={t("Manage signature")} {...cardTitleProps} />
            <div className="row">
              <div className="col-12 text-center my-3">
                <Image src={userIcon} imageWidth="3vw" imageHeight="3vw" />
              </div>
              <div className="text-end" onClick={handleOpenModal}>
                <LabelField
                  title={t("Edit")}
                  className="cursor-pointer colorPrimary poppins-semibold"
                />
              </div>
            </div>
            <ModalPopup show={showModal} handleClose={handleCloseModal} buttonText={t("Save")}>
              <div className="text-center">
                <Image src={userIcon} />
              </div>
            </ModalPopup>
          </div>
        </div>
        <div className="col-4">
          <div className="contentBg">
            <LabelField title={t("Security")} {...cardTitleProps} />
            <div className="row mb-3">
              <LabelWithInputField
                label={t("Change password") + ":"}
                type="password"
                value={""}
                name=""
                id="changePass"
                {...inputFieldProps}
              />
            </div>
            <div className="row mb-5">
              <LabelWithInputField
                label={t("Repeat password") + ":"}
                type="password"
                value={""}
                name=""
                id="repeatPass"
                {...inputFieldProps}
              />
            </div>
            <LabelField title={t("Communication")} {...cardTitleProps} />
            <div className="row mt-4 mb-3">
              <LabelWithInputField
                label={t("Email contracts") + ":"}
                type="text"
                value={""}
                name=""
                id="emailContracts"
                {...inputFieldProps}
              />
            </div>
            <div className="row mb-3">
              <LabelWithInputField
                label={t("Email invoicing") + ":"}
                type="text"
                value={""}
                name=""
                id="emailInvoicing"
                {...inputFieldProps}
              />
            </div>
            <div className="row mb-3">
              <LabelWithInputField
                label={t("Planning contact person") + ":"}
                type="text"
                value={""}
                name=""
                id="planningContact"
                {...inputFieldProps}
              />
            </div>
            <div className="row mb-5">
              <LabelWithInputField
                label={t("Planning contact phone number") + ":"}
                type="text"
                value={""}
                name=""
                id="planningContactPhoneno"
                {...inputFieldProps}
              />
            </div>
            <LabelField title={t("Privacy")} {...cardTitleProps} />
            <div className="row mt-4 mb-3">
              <div className="col-7">
                <LabelField
                  title={t("Commmunication via mail") + ":"}
                  className="colorPrimary poppins-medium"
                />
              </div>
              <div className="col-4 align-self-center">
                <ToggleButton />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-7">
                <LabelField
                  title={t("Commmunication via phone") + ":"}
                  className="colorPrimary poppins-medium"
                />
              </div>
              <div className="col-4 align-self-center">
                <ToggleButton />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="mb-3 privacyText">
                  {t(
                    "AbsoluteYOU! collects, stores and protects your personal data in order to offer you a better and more personalised service"
                  ) + ":"}
                </div>
                <div className="mb-3 privacyText">
                  {t(
                    "You can decide at any time how our office may contact you to, for example, deliver your documents, ask your opinion as part of a survey or contact you regarding your file"
                  ) + ":"}
                </div>
                <div className="privacyText">
                  {t(
                    "Simply select the means of communication you prefer above"
                  ) + "."}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="contentBg">
            <LabelField title={t("Cost Calculator")} {...cardTitleProps} />
            <div className="mt-4 mb-3 row">
              <SelectWithSearch
                fieldLabel={t("Choose the employee's status") + ":"}
                title={t("Choose the employee's status") + ":"}
                search={false}
                options={[]}
                value={undefined}
                onChange={() => {}}
                name={""}
                {...costCalculatorProps}
              />
            </div>
            <div className="row mb-3">
              <SelectWithSearch
                fieldLabel={t("Age") + ":"}
                title={t("Age") + ":"}
                search={false}
                options={[]}
                value={undefined}
                onChange={() => {}}
                name={""}
                {...costCalculatorProps}
              />
            </div>
            <div className="row mb-3">
              <SelectWithSearch
                fieldLabel={t("Paritair committee") + ":"}
                title={t("Paritair committee") + ":"}
                search={false}
                options={[]}
                value={undefined}
                onChange={() => {}}
                name={""}
                {...costCalculatorProps}
              />
            </div>
            <div className="row mb-3">
              <SelectWithSearch
                fieldLabel={t("Function") + ":"}
                title={t("Function") + ":"}
                search={false}
                options={[]}
                value={undefined}
                onChange={() => {}}
                name={""}
                {...costCalculatorProps}
              />
            </div>
            <div className="row mb-3 position-relative">
              <LabelWithInputField
                label={t("Baremaloon") + ":"}
                type="text"
                value={""}
                name=""
                id="baremaloon"
                {...costCalculatorProps}
                className="inputFieldColor"
              >
                <div className="position-absolute lockIcon secondaryColorHoverEffect">
                  <Icon
                    isIcon={true}
                    width="1vw"
                    height="1vw"
                    name="lockIcon"
                  />
                </div>
              </LabelWithInputField>
            </div>
            <div className="row mb-3">
              <LabelWithInputField
                label={t("Adjusted hourly wage") + ":"}
                type="text"
                value={""}
                name=""
                id="adjustedHourlyWage"
                {...costCalculatorProps}
                className="inputFieldColor"
              />
            </div>
            <div className="row mb-3 position-relative">
              <LabelWithInputField
                label={t("Coefficient") + ":"}
                type="text"
                value={""}
                name=""
                id="coefficient"
                {...costCalculatorProps}
                className="inputFieldColor"
              >
                <div className="position-absolute lockIcon secondaryColorHoverEffect">
                  <Icon
                    isIcon={true}
                    width="1vw"
                    height="1vw"
                    name="lockIcon"
                  />
                </div>
              </LabelWithInputField>
            </div>
            <div className="row mb-3 position-relative">
              <LabelWithInputField
                label={t("Dimona cost per hour ") + ":"}
                type="text"
                value={""}
                name=""
                id="dimonaCostPerHour"
                {...costCalculatorProps}
                className="inputFieldColor"
              >
                <div className="position-absolute lockIcon secondaryColorHoverEffect">
                  <Icon
                    isIcon={true}
                    width="1vw"
                    height="1vw"
                    name="lockIcon"
                  />
                </div>
              </LabelWithInputField>
            </div>
            <div className="row">
              <LabelField
                title={t("What hours will the employee work") + ":"}
              />
            </div>
            <div className="row">
              <table className="table table-borderless profilePlanningTable">
                <thead>
                  <tr>
                    <th>{t("Shift") + " 1"}</th>
                    <th>{t("Shift") + " 2"}</th>
                    <th>{t("Total")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center timeWrapper justify-content-center">
                        <TimePicker
                          setHourMin={() => {}}
                          type={""}
                          index={0}
                          value={""}
                          hour={""}
                          minute={""}
                          timePickerClassName="d-flex align-items-center justify-content-center me-2"
                        />
                        <TimePicker
                          setHourMin={() => {}}
                          type={""}
                          index={0}
                          value={""}
                          hour={""}
                          minute={""}
                          timePickerClassName="d-flex align-items-center justify-content-center"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center timeWrapper justify-content-center">
                        <TimePicker
                          setHourMin={() => {}}
                          type={""}
                          index={0}
                          value={""}
                          hour={""}
                          minute={""}
                          timePickerClassName="d-flex align-items-center justify-content-center me-2"
                        />
                        <TimePicker
                          setHourMin={() => {}}
                          type={""}
                          index={0}
                          value={""}
                          hour={""}
                          minute={""}
                          timePickerClassName="d-flex align-items-center justify-content-center"
                        />
                      </div>
                    </td>
                    <td className="align-middle">
                      <div className="totalHours text-white poppins-medium">
                        0 : 0
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col-12 d-flex align-items-center mb-3">
                <LabelField
                  title={t("Do you pay for the statutory break")}
                  className="mb-0 me-3 colorPrimary poppins-medium"
                />
                <ToggleButton />
              </div>
            </div>
            <div className="row my-4">
              <LabelField title={t("Calculation")} {...cardTitleProps} />
            </div>
            <div className="row">
              <LabelWithInputField
                label={t("Baremaloon") + ":"}
                type="text"
                value={""}
                name=""
                id="baremaloon"
                {...costCalculatorProps}
                className="inputFieldColor"
              />
            </div>
            <Icon
              isIcon={true}
              width="0.8vw"
              height="0.8vw"
              name="close"
              fillColor="#87abb8"
            />
            <div className="row">
              <LabelWithInputField
                label={t("Coefficient") + ":"}
                type="text"
                value={""}
                name=""
                id="coefficient"
                {...costCalculatorProps}
                className="inputFieldColor"
              />
            </div>
            <Icon
              isIcon={true}
              width="0.8vw"
              height="0.8vw"
              name="add"
              fillColor="#87abb8"
            />
            <div className="row">
              <LabelWithInputField
                label={t("Dimona cost") + ":"}
                type="text"
                value={""}
                name=""
                id="dimonaCost"
                {...costCalculatorProps}
                className="inputFieldColor"
              />
            </div>
            <Icon
              isIcon={true}
              width="1vw"
              height="1vw"
              name="equalIcon"
              fillColor="#87abb8"
            />
            <div className="row">
              <LabelWithInputField
                label={t("Cost per hour") + ":"}
                type="text"
                value={""}
                name=""
                id="costPerHour"
                {...costCalculatorProps}
                className="inputFieldColor"
              />
            </div>
            <Icon
              isIcon={true}
              width="0.8vw"
              height="0.8vw"
              name="close"
              fillColor="#87abb8"
            />
            <div className="row">
              <LabelWithInputField
                label={t("Number of hours") + ":"}
                type="text"
                value={""}
                name=""
                id="costPerHour"
                {...costCalculatorProps}
                className="inputFieldColor"
              />
            </div>
            <Icon
              isIcon={true}
              width="1vw"
              height="1vw"
              name="equalIcon"
              fillColor="#87abb8"
            />
            <div className="row">
              <LabelWithInputField
                label={t("Total cost") + ":"}
                type="text"
                value={""}
                name=""
                id="costPerHour"
                {...costCalculatorProps}
                className="inputFieldPrimary"
              />
            </div>
            <div className="mb-3 privacyText col-7 pe-0">
              {"*" + t("Cost price is excl premiums & excl VAT.")}
            </div>
          </div>
        </div>
        <div className="col-12 my-3">
          <div>
            <Button
              type="submit"
              className="searchBtn px-3 float-end"
              title={t("Save")}
              style={{ width: "fit-content" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default translate(ProfilePage);
