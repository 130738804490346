import React, { useEffect, useState, ChangeEvent } from "react";
import LabelField from "../common/atoms/LabelField";
import { t } from "../common/atoms/translation";
import Header from "../common/layout/Header";
import Address from "../common/molecules/Address";
import EmployeeOthers from "./EmployeeOthers";
import EmployeeProfile from "./EmployeeProfile";
import { Form, useLocation, useNavigate } from "react-router-dom";
import Button from "../common/atoms/Button";
import EmergencyContact from "./EmergencyContact";
import DocumentsUploadEmployee from "../common/molecules/DocumentsUploadEmployee";
import CustomNotify, { NotifyType } from "../common/atoms/CustomNotify";
import BackLink from "../common/atoms/BackLink";
import * as ROUTE from "../../routes/RouteConstants";
import {
  COUNTRY_LIST,
  SETTINGS_LIST,
  SSNR_DETAILS,
  EMPLOYEE_REGISTRATION,
  UPDATE_EMPLOYEE,
  EMPLOYEE_REGISTRATION_WITHOUT_LOGIN,
  REGISTER_COUNTRY_LIST,
  REGISTER_SETTINGS_LIST,
  SSNR_DETAILS_WITHOUT_LOGIN,
} from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import { AxiosCall } from "../../services/AxiosService";
import { isValidIBANNumber } from "./isValidIBANNumber";
import {
  validateEmail,
  validatePassword,
} from "../../services/ValidationService";
import { useSelector } from "react-redux";
import OverFlowWrapper from "../common/OverFlowWrapper";
const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
const ALLOWED_FILE_FORMATS = ["image/png", "image/jpeg", "image/jpg"];

export type HandleChange = <
  T extends keyof EmployeeFormData,
  K extends keyof DefaultEmpLegal // Add generic K for DefaultEmpLegal keys
>(
  field: T,
  subfield?: T extends "default_emp_legal" ? K : keyof EmployeeFormData[T], // Conditional type for subfield
  nestedSubfield?: keyof AddressDetails,
  index?: number // Index is required for array fields
) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;

interface Security {
  password: string;
  confirm_password: string;
}

interface BankAccount {
  type: string;
  iban: any;
  bic: string | null;
  iban_country_code: any;
}

interface AddressDetails {
  name: string | null;
  street: string;
  number: string;
  addition: string | null;
  postal_code: string;
  box: string;
  city: string;
  country: number | null;
  latitude: number;
  longitude: number;
}

interface Address {
  domicile: AddressDetails;
  residence: AddressDetails;
}

interface Dependencies {
  dependent_spouce: number | null;
  dependent_children: number | null;
}

interface EmergencyContactDetails {
  name: string | null;
  contact_number: string | null;
  email: string;
  relation: string;
}

interface UserTerms {
  is_term_condition: boolean;
  is_contract_sign: boolean;
  is_temp_employement: boolean;
  is_gdpr: boolean;
}

export interface DefaultEmpLegal {
  paritaire_commitee_id: number | null;
  employee_type_id: number | null;
  function_id: number | null;
  minWage: string;
  actual_salary: string;
  is_actual_added: number;
  company_id: number | null;
}
interface WorkPermit {
  number: string;
  end_date: string;
}
interface OptionType {
  value: number | string; // Can be bright_id or key
  label: string;
  bright_id?: any;
  key?: string | null;
}
interface CountriesOptionType {
  value: number | string; // Can be bright_id or key
  label: string;
  iso?: string | null;
}

// Main Employee Form Data Interface
export interface EmployeeFormData {
  id: number;
  is_national: boolean;
  nationality: number | null;
  registry_number: string;
  work_permit: WorkPermit;
  title: number;
  first_name: string;
  last_name: string;
  email: string;
  confirm_email: string;
  phone_number: string | null;
  education: number | null;
  security: Security;
  gender: number | null;
  date_of_birth: string;
  place_of_birth: string;
  country_of_birth: number;
  civil_status: number;
  desired_employee_type: number;
  language: number | null;
  bank_account: BankAccount;
  address: Address;
  driving_license: number;
  transport_type: number | null;
  dependencies: Dependencies;
  emergency_contact: EmergencyContactDetails;
  profile_picture_url: string;
  profile_picture_id: number | null;
  profile_picture: any;
  front_pic: any;
  back_pic: any;
  front_pic_name: string | null;
  back_pic_name: string | null;
  work_permit_file_name: string | null;
  work_permit_file: any;
  front_pic_url: string;
  front_pic_id: number | null;
  back_pic_url: string;
  back_pic_id: number | null;
  work_permit_file_id: number | null;
  work_permit_file_url: string;
  user_terms: UserTerms;
  default_emp_legal: DefaultEmpLegal[];
  companies: number[];
  role: string;
  status: string;
  source: string;
  extra_ref: string;
  errors: FormErrors;
  national_registry_number: string;
  sameAddress: boolean;
  loading: boolean;
}

// Define the Option type
type Option = {
  value: number | string;
  label: string;
};

export interface Countries {
  countries: CountriesOptionType[];
}

export interface OptionsData {
  initial: Option[];
  gender: Option[];
  education: Option[];
  civil_status: Option[];
  dependent_spouce: Option[];
  driving_license: Option[];
  languages: Option[];
  employee_type_groups: Option[];
  transporatations: Option[];
  ibancountries: Option[];
}

interface FormErrors {
  [key: string]: string | null;
}

const Registration = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const classname = searchParams.get("type") === "login" ? "mx-5" : "";
  const id = searchParams.get("id");
  const pageTitle = id != null ? "Profile" : "Employee registration";

  // Selected pc value
  const [pcId, setPcId] = useState<number[] | null>([0]);

  // Function to return default values
  const getDefaultFormValues = (): EmployeeFormData => {
    return {
      id: 0,
      is_national: true,
      nationality: 29,
      registry_number: "",
      work_permit: {
        number: "",
        end_date: "",
      },
      title: 0,
      first_name: "",
      last_name: "",
      email: "",
      confirm_email: "",
      phone_number: null,
      education: null,
      security: {
        password: "",
        confirm_password: "",
      },
      gender: null,
      date_of_birth: "",
      place_of_birth: "",
      country_of_birth: 29,
      civil_status: 0,
      desired_employee_type: 0,
      language: 2,

      bank_account: {
        type: "iban",
        iban: null,
        bic: null,
        iban_country_code: "BE",
      },
      address: {
        residence: {
          name: null,
          street: "",
          number: "",
          addition: null,
          postal_code: "",
          box: "",
          city: "",
          country: 29,
          latitude: 0,
          longitude: 0,
        },
        domicile: {
          name: null,
          street: "",
          number: "",
          addition: null,
          postal_code: "",
          box: "",
          city: "",
          country: 29,
          latitude: 0,
          longitude: 0,
        },
      },
      driving_license: 0,
      transport_type: null,
      dependencies: {
        dependent_spouce: null,
        dependent_children: 0,
      },
      emergency_contact: {
        name: null,
        contact_number: null,
        email: "",
        relation: "",
      },
      profile_picture_url: "",
      profile_picture_id: null,
      profile_picture: null,
      front_pic: null,
      back_pic: null,
      work_permit_file: null,
      front_pic_url: "",
      front_pic_id: null,
      back_pic_url: "",
      back_pic_id: null,
      front_pic_name: null,
      back_pic_name: null,
      work_permit_file_name: null,
      work_permit_file_id: null,
      work_permit_file_url: "",
      user_terms: {
        is_term_condition: true,
        is_contract_sign: true,
        is_temp_employement: true,
        is_gdpr: true,
      },
      default_emp_legal: [
        {
          paritaire_commitee_id: null, // Ensure this is initialized
          employee_type_id: null,
          function_id: null,
          minWage: "",
          actual_salary: "",
          is_actual_added: 0,
          company_id: null,
        },
      ],
      companies: [],
      role: "employee",
      status: "active",
      source: "web",
      extra_ref: "",
      errors: {} as FormErrors,
      national_registry_number: "",
      sameAddress: true,
      loading: false,
    };
  };

  const [formData, setFormData] = useState<EmployeeFormData>(
    getDefaultFormValues()
  );
  const [countryOptions, setCountryOptions] = useState<Countries>({
    countries: [],
  });
  const navigator = useNavigate();
  const [optionsData, setOptionsData] = useState<OptionsData>({
    initial: [],
    gender: [],
    education: [],
    civil_status: [],
    dependent_spouce: [],
    driving_license: [],
    languages: [],
    employee_type_groups: [],
    transporatations: [],
    ibancountries: [],
  });
  const user = useSelector((state: any) => state.auth);

  // Fetch options data from country-list, settings-list API
  useEffect(() => {
    const fetchCountryOptions = async () => {
      let result;
      try {
        if (user.token) {
          result = await ApiCall.getService(COUNTRY_LIST, "GET");
        } else {
          result = await ApiCall.getService(REGISTER_COUNTRY_LIST, "GET");
        }
        setCountryOptions({
          countries: result.data || [],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchCompaniesByUserId = async () => {
      let result;
      // try {
      //   result = await ApiCall.getService(GET_USER_COMPANIES, "GET");
      //   // setCountryOptions({
      //   //   companies: result.data || [],
      //   // });
      // } catch (error) {
      //   console.error("Error fetching data:", error);
      // }
    };

    const fetchOptionsData = async () => {
      let result;
      try {
        if (user.token) {
          result = await ApiCall.getService(SETTINGS_LIST, "GET");
        } else {
          result = await ApiCall.getService(REGISTER_SETTINGS_LIST, "GET");
        }

        setOptionsData({
          initial: result?.data?.initial || [],
          gender: result?.data?.gender || [],
          education: result?.data?.education || [],
          civil_status: result?.data?.marital_status || [],
          dependent_spouce: result?.data?.dependent_spouse || [],
          driving_license: result?.data?.driving_license || [],
          languages: result?.data?.languages || [],
          employee_type_groups: result?.data?.employee_type_groups || [],
          transporatations: result?.data?.transporatations || [],
          ibancountries: result?.data?.iban_countries || [],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCountryOptions();
    fetchOptionsData();
    fetchCompaniesByUserId();
  }, []);

  // Validate IBAN
  const validateIban = async (iban: string) => {
    const validationError = isValidIBANNumber(iban);

    // If there's an error, update error state
    if (validationError) {
      setFormErrors("bank_account.iban", validationError);
      return;
    }
    // Clear IBAN error if valid
    setFormErrors("bank_account.iban", null);

    // Fetch BIC if IBAN is valid
    await fetchBicForIban(iban);
  };

  // Fetch BIC for IBAN
  const fetchBicForIban = async (iban: string) => {
    const url = `https://openiban.com/validate/${iban}?getBIC=true&validateBankCode=true`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const ibanData = await response.json();
      if (ibanData.valid) {
        // Set BIC in form state
        setFormData((prevData) => ({
          ...prevData,
          bank_account: {
            ...prevData.bank_account,
            bic: ibanData.bankData.bic,
          },
        }));

        // Clear BIC error
        setFormErrors("bank_account.iban", null);
        setFormErrors("bank_account.bic", null);
      } else {
        // Clear BIC in form state and set error
        setFormData((prevData) => ({
          ...prevData,
          bank_account: {
            ...prevData.bank_account,
            bic: "",
          },
        }));
        setFormErrors(
          "bank_account.bic",
          "There is no BIC for the provided IBAN"
        );
      }
    } catch (error) {
      console.error("Error fetching BIC:", error);
      setFormErrors("bank_account.bic", "Error fetching BIC data");
    }
  };

  const handleSelectChange = (
    selectedOption: Option | null,
    fieldPath: string,
    index?: number // Assuming you need an index for the specific item in an array
  ) => {
    const value = selectedOption ? selectedOption.value : null;

    setFormData((prevFormData) => {
      const fieldNames = fieldPath.split("."); // Split the field path (e.g., 'address.domicile.country')
      let updatedFormData: any = { ...prevFormData }; // Use 'any' as a fallback

      let currentLevel: any = updatedFormData;

      // Iterate through field names, except the last one, to reach the correct level in the form data
      for (let i = 0; i < fieldNames.length - 1; i++) {
        const fieldName = fieldNames[i];

        if (!currentLevel[fieldName]) {
          currentLevel[fieldName] = {}; // Initialize if undefined
        }
        currentLevel = currentLevel[fieldName];
      }

      // Set the new value at the final field level
      currentLevel[fieldNames[fieldNames.length - 1]] = value;

      // Ensure `default_emp_legal` array has all fields initialized
      if (Array.isArray(updatedFormData.default_emp_legal)) {
        updatedFormData.default_emp_legal =
          updatedFormData.default_emp_legal.map((item: any) => ({
            paritaire_commitee_id: item.paritaire_commitee_id ?? 0,
            employee_type_id: item.employee_type_id ?? 0,
            function_id: item.function_id ?? 0,
            minWage: item.minWage ?? "",
            actual_salary: item.actual_salary ?? "",
            is_actual_added: item.is_actual_added ?? 0,
            company_id: item.company_id ?? null,
          }));
      }
      // Update pcId array at the given index
      setPcId((prevPcValue) => {
        const updatedPcValue = [...(prevPcValue || [])]; // Ensure prevPcValue is an array
        if (index !== undefined) {
          updatedPcValue[index] = selectedOption
            ? Number(selectedOption.value)
            : 0; // Update with selected option or reset to 0
        }
        return updatedPcValue;
      });
      return updatedFormData;
    });
  };

  // Function to handle toggle
  const handleToggle =
    (field: keyof EmployeeFormData, subfield?: string) => (value: boolean) => {
      setFormData((prevData) => ({
        ...prevData,
        [field]: subfield
          ? {
              ...(prevData[field] as object),
              [subfield]: value, // Handle nested subfield
            }
          : value, // Handle top-level field
      }));
    };

  // Function to handle address toggle
  const handleAddressToggle = () => {
    setFormData((prevData) => ({
      ...prevData,
      sameAddress: !prevData.sameAddress,
      address: {
        ...prevData.address,
        domicile: !prevData.sameAddress
          ? { ...prevData.address.residence }
          : { ...getDefaultFormValues().address.domicile },
      },
    }));
  };

  const handleChange =
    <
      T extends keyof EmployeeFormData,
      K extends keyof DefaultEmpLegal // Add generic K for DefaultEmpLegal keys
    >(
      field: T,
      subfield?: T extends "default_emp_legal" ? K : keyof EmployeeFormData[T], // Conditional type for subfield
      nestedSubfield?: keyof AddressDetails,
      index?: number
    ) =>
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { type, value, checked } = event.target as HTMLInputElement;
      const inputValue = type === "checkbox" ? checked : value;

      setFormData((prevData) => {
        // Default structure for each object in the 'default_emp_legal' array
        const defaultEmpLegalDefaults: DefaultEmpLegal = {
          paritaire_commitee_id: 0,
          employee_type_id: 0,
          function_id: 0,
          minWage: "",
          actual_salary: "",
          is_actual_added: 0,
          company_id: null,
        };

        // Check if updating 'default_emp_legal' array
        if (field === "default_emp_legal" && index !== undefined) {
          const updatedArray = [...prevData.default_emp_legal]; // Shallow copy the array

          // Ensure the object at the specified index is initialized with default values
          const item = updatedArray[index] || defaultEmpLegalDefaults;
          updatedArray[index] = {
            ...item, // Merge with the existing or default object
            [subfield as keyof DefaultEmpLegal]: inputValue, // Update the specific subfield
          };

          return {
            ...prevData,
            default_emp_legal: updatedArray, // Set the updated array
          };
        }

        // Other cases (like 'address')
        if (field === "address" && subfield && nestedSubfield) {
          return {
            ...prevData,
            address: {
              ...prevData.address,
              [subfield as keyof Address]: {
                ...(prevData.address[
                  subfield as keyof Address
                ] as AddressDetails),
                [nestedSubfield]: inputValue,
              },
            },
          };
        }

        // Updating other fields with subfield
        if (subfield) {
          return {
            ...prevData,
            [field]: {
              ...(prevData[field] as object),
              [subfield]: inputValue,
            },
          };
        }

        // Updating fields without subfield
        return {
          ...prevData,
          [field]: inputValue,
        };
      });

      if (field === "bank_account" && subfield) {
        // Validate IBAN
        validateIban(value);
      }
    };

  // SSN number validation
  const validateSSNNumber = (ssnNumber: string): string | null => {
    // SSN pattern including dots and hyphens
    const ssnPattern = /^[0-9]{2}\.?[0-9]{2}\.?[0-9]{2}-[0-9]{3}\.?[0-9]{2}$/;

    if (!ssnNumber) {
      return "This field is required";
    }

    if (!ssnPattern.test(ssnNumber)) {
      return "This field is invalid";
    }

    return null;
  };

  const url = new URL(window.location.href);
  const updateId = url.pathname.split("/").pop()?.trim();
  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      if (updateId && !isNaN(Number(updateId))) {
        try {
          const result = await ApiCall.getService(
            `${UPDATE_EMPLOYEE}/${updateId}`,
            "GET"
          );
          const originalNumber: string = result.data.registry_number;
          const formattedNumber: string = originalNumber.replace(
            /(\d{2})(\d{2})(\d{2})(\d{3})(\d{2})/,
            "$1.$2.$3-$4.$5"
          );

          setResponseData(result.data, formattedNumber);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        console.error("Invalid updateId:", updateId);
      }
    };
    fetchEmployeeDetails();
  }, []);

  const setResponseData = (data: any, registry_number: string = "") => {
    setFormData((prevFormData) => {
      const existingDefaultEmpLegal = prevFormData.default_emp_legal || [];

      return {
        ...prevFormData,
        id: data.id || prevFormData.id,
        is_national: data.is_national || prevFormData.is_national,
        nationality: data.nationality || prevFormData.nationality,
        registry_number: registry_number || prevFormData.registry_number,
        national_registry_number:
          registry_number || prevFormData.national_registry_number,
        work_permit: {
          number: data.work_permit_number || prevFormData.work_permit.number,
          end_date:
            data.work_permit_end_date || prevFormData.work_permit.end_date,
        },
        title: data.title || prevFormData.title,
        first_name: data.first_name || prevFormData.first_name,
        last_name: data.last_name || prevFormData.last_name,
        email: data.email || prevFormData.email,
        confirm_email: data.email || prevFormData.confirm_email,
        phone_number: data.phone_number || prevFormData.phone_number,
        education: data.education || prevFormData.education,
        security: {
          password: data.security_password || prevFormData.security.password,
          confirm_password:
            data.security_confirm_password ||
            prevFormData.security.confirm_password,
        },
        gender: data.gender || prevFormData.gender,
        date_of_birth: data.date_of_birth || prevFormData.date_of_birth,
        place_of_birth: data.place_of_birth || prevFormData.place_of_birth,
        country_of_birth:
          data.country_of_birth || prevFormData.country_of_birth,
        civil_status: data.civil_status || prevFormData.civil_status,
        desired_employee_type:
          data.desired_employee_type || prevFormData.desired_employee_type,
        language: data.language || prevFormData.language,
        bank_account: {
          type: data.bank_account?.type || prevFormData.bank_account.type,
          iban: data.bank_account?.iban || prevFormData.bank_account.iban,
          bic: data.bank_account?.bic || prevFormData.bank_account.bic,
          iban_country_code:
            data.bank_account?.iban_country_code ||
            prevFormData.bank_account.iban_country_code,
        },
        address: {
          domicile: {
            ...prevFormData.address.domicile,
            name:
              data.address?.domicile?.name ??
              prevFormData.address.domicile.name,
            street:
              data.address?.domicile?.street ??
              prevFormData.address.domicile.street,
            number:
              data.address?.domicile?.number ??
              prevFormData.address.domicile.number,
            addition:
              data.address?.domicile?.addition ??
              prevFormData.address.domicile.addition,
            postal_code:
              data.address?.domicile?.postal_code ??
              prevFormData.address.domicile.postal_code,
            box:
              data.address?.domicile?.box ?? prevFormData.address.domicile.box,
            city:
              data.address?.domicile?.city ??
              prevFormData.address.domicile.city,
            country:
              data.address?.domicile?.country ??
              prevFormData.address.domicile.country,
            latitude:
              data.address?.domicile?.latitude ??
              prevFormData.address.domicile.latitude,
            longitude:
              data.address?.domicile?.longitude ??
              prevFormData.address.domicile.longitude,
          },
          residence: {
            ...prevFormData.address.residence,
            name:
              data.address?.residence?.name ??
              prevFormData.address.residence.name,
            street:
              data.address?.residence?.street ??
              prevFormData.address.residence.street,
            number:
              data.address?.residence?.number ??
              prevFormData.address.residence.number,
            addition:
              data.address?.residence?.addition ??
              prevFormData.address.residence.addition,
            postal_code:
              data.address?.residence?.postal_code ??
              prevFormData.address.residence.postal_code,
            box:
              data.address?.residence?.box ??
              prevFormData.address.residence.box,
            city:
              data.address?.residence?.city ??
              prevFormData.address.residence.city,
            country:
              data.address?.residence?.country ??
              prevFormData.address.residence.country,
            latitude:
              data.address?.residence?.latitude ??
              prevFormData.address.residence.latitude,
            longitude:
              data.address?.residence?.longitude ??
              prevFormData.address.residence.longitude,
          },
        },
        driving_license: data.driving_license || prevFormData.driving_license,
        transport_type: data.transport_type || prevFormData.transport_type,
        dependencies: {
          dependent_spouce:
            data.dependencies?.dependent_spouce ||
            prevFormData.dependencies.dependent_spouce,
          dependent_children:
            data.dependencies?.dependent_children ||
            prevFormData.dependencies.dependent_children,
        },
        emergency_contact: {
          ...prevFormData.emergency_contact,
          name:
            data.emergency_contact?.name ?? prevFormData.emergency_contact.name,
          contact_number:
            data.emergency_contact?.contact_number ??
            prevFormData.emergency_contact.contact_number,
          email:
            data.emergency_contact?.email ??
            prevFormData.emergency_contact.email,
          relation:
            data.emergency_contact?.relation ??
            prevFormData.emergency_contact.relation,
        },
        profile_picture_url:
          data.profile_picture_url || prevFormData.profile_picture_url,
        profile_picture_id:
          data.profile_picture_id || prevFormData.profile_picture_id,
        front_pic_url: data.front_pic_url || prevFormData.front_pic_url,
        front_pic_id: data.front_pic_id || prevFormData.front_pic_id,
        back_pic_url: data.back_pic_url || prevFormData.back_pic_url,
        back_pic_id: data.back_pic_id || prevFormData.back_pic_id,
        work_permit_file_id:
          data.work_permit_file_id || prevFormData.work_permit_file_id,
        work_permit_file_url:
          data.work_permit_file_url || prevFormData.work_permit_file_url,
        work_permit_file_name:
          data.work_permit_file_name || prevFormData.work_permit_file_name,
        front_pic_name: data.front_pic_name || prevFormData.front_pic_name,
        back_pic_name: data.back_pic_name || prevFormData.back_pic_name,
        user_terms: {
          ...prevFormData.user_terms,
          is_term_condition:
            data.user_terms?.is_term_condition ??
            prevFormData.user_terms.is_term_condition,
          is_contract_sign:
            data.user_terms?.is_contract_sign ??
            prevFormData.user_terms.is_contract_sign,
          is_temp_employement:
            data.user_terms?.is_temp_employement ??
            prevFormData.user_terms.is_temp_employement,
          is_gdpr: data.user_terms?.is_gdpr ?? prevFormData.user_terms.is_gdpr,
        },
        default_emp_legal:
          (data.default_emp_legal ?? []).map(
            (item: DefaultEmpLegal, index: number) => ({
              paritaire_commitee_id:
                item.paritaire_commitee_id ??
                existingDefaultEmpLegal[index]?.paritaire_commitee_id,
              employee_type_id:
                item.employee_type_id ??
                existingDefaultEmpLegal[index]?.employee_type_id,
              function_id:
                item.function_id ?? existingDefaultEmpLegal[index]?.function_id,
              minWage: item.minWage ?? existingDefaultEmpLegal[index]?.minWage,
              actual_salary:
                item.actual_salary ??
                existingDefaultEmpLegal[index]?.actual_salary,
              is_actual_added:
                item.is_actual_added ??
                existingDefaultEmpLegal[index]?.is_actual_added,
              company_id:
                item.company_id ?? existingDefaultEmpLegal[index]?.company_id,
            })
          ) || existingDefaultEmpLegal, // fallback to existing if data is empty
        companies: data.companies || prevFormData.companies,
        role: data.role || prevFormData.role,
        // status: data.status || prevFormData.status,
        status: prevFormData.status,
        source: data.source || prevFormData.source,
        extra_ref: data.extra_ref || prevFormData.extra_ref,
      };
    });
  };

  //SSN Validation
  const handleVerifySSN = async () => {
    const ssnr = formData.registry_number;
    // Validate the raw SSN number
    const ssnError = validateSSNNumber(ssnr);
    if (ssnError) {
      setFormErrors("registry_number", ssnError);
      return;
    }

    // API call with the cleaned SSN number
    try {
      let response: any;
      const url = `${SSNR_DETAILS}/${ssnr}`;
      const url_with_token = `${SSNR_DETAILS_WITHOUT_LOGIN}/${ssnr}`;
      if (user.token) {
        response = await ApiCall.getService(
          url,
          "GET",
          "central-data-management"
        );
      } else {
        response = await ApiCall.getService(
          url_with_token,
          "GET",
          "central-data-management"
        );
      }
      if (response.status === 200) {
        setFormErrors("registry_number", null);
        const data = response.data;
        const originalNumber: string = data.registry_number;
        const formattedNumber: string = originalNumber.replace(
          /(\d{2})(\d{2})(\d{2})(\d{3})(\d{2})/,
          "$1.$2.$3-$4.$5"
        );
        setResponseData(data, formattedNumber);
      } else {
        setFormErrors("registry_number", response.message);
      }
    } catch (error) {
      setFormErrors("registry_number", "Failed to verify SSN number");
    }
  };
  //Validation Messages
  const setFormErrors = (name: string, error: string | null) => {
    setFormData((prevData) => ({
      ...prevData,
      errors: {
        ...prevData.errors,
        [name]: error || null, // Convert null to empty string
      },
    }));
  };

  const uploadFile = async (
    file: File,
    name: string,
    fileType: number,
    path: string
  ) => {
    const fileData = new FormData();
    fileData.append("file", file);
    fileData.append("name", name);
    fileData.append("file_type", fileType.toString());

    try {
      const response = await AxiosCall.fileUploadService(fileData, path);
      return response;
    } catch (error) {
      console.error("Error uploading file:", error);
      return null;
    }
  };

  const handleFileUpload = async (
    file: File,
    name: string,
    fileType: number,
    path: string,
    fieldName: string
  ) => {
    if (name === "profile_picture")
      setFormData((prev) => ({ ...prev, loading: true }));
    const data = await uploadFile(file, file.name, fileType, path);

    if (data) {
      const { fileId: id, fileUrl: url } = data.data;
      setFormData((prevData) => ({
        ...prevData,
        [fieldName + "_url"]: url,
        [fieldName + "_id"]: id,
        errors: {
          ...prevData.errors,
          [name]: "", // Clear the error if file upload succeeds
        },
      }));
    }
    if (name === "profile_picture")
      setFormData((prev) => ({ ...prev, loading: false }));
  };

  //Validations

  const formValidation = (): FormErrors => {
    const errors: any = {};
    const fields: any = {
      string: [
        "first_name",
        "last_name",
        "email",
        "confirm_email",
        "phone_number",
        "date_of_birth",
        "place_of_birth",
        "front_pic_url",
        "back_pic_url",
      ],
      number: [
        "nationality",
        "title",
        "education",
        "gender",
        "country_of_birth",
        "civil_status",
        "desired_employee_type",
        "language",
        "driving_license",
        "transport_type",
      ],
      address: ["street", "city", "postal_code", "country", "number"],
    };

    const validateFields = (
      fieldNames: (keyof FormErrors)[],
      type: "string" | "number"
    ) => {
      fieldNames.forEach((field) => {
        const value = formData[field as keyof typeof formData];
        if (!value) {
          errors[field] = `This field is required`;
        } else if (type === "number" && isNaN(Number(value))) {
          errors[field] = `Enter a valid number.`;
        } else if (type === "string" && field === "phone_number") {
          const trimmedValue = value?.trim();
          if (!trimmedValue || trimmedValue === "" || trimmedValue === null) {
            errors[field] = `This field is required.`;
          } else if (!/^[\+0-9\s\/\-\(\)]{6,50}$/.test(trimmedValue)) {
            errors[field] = `Please enter a valid input`;
          }
        }
      });
    };
    const validateNestedObject = (
      obj: Record<string, any>,
      prefix: string,
      requiredFields: string[],
      customValidation?: (obj: Record<string, any>) => void,
      conditionalRequiredFields?: {
        condition: (formData: Record<string, any>) => boolean;
        fields: string[];
      }
    ) => {
      requiredFields.forEach((field) => {
        // Check for undefined or null, but allow valid falsy values like 0
        if (obj[field] === undefined || obj[field] === null) {
          errors[`${prefix}.${field}`] = `This field is required`;
        }
      });
    
      if (
        conditionalRequiredFields &&
        !conditionalRequiredFields.condition(formData)
      ) {
        conditionalRequiredFields.fields.forEach((field) => {
          // Check for undefined or null, but allow valid falsy values like 0
          if (obj[field] === undefined || obj[field] === null) {
            errors[`${prefix}.${field}`] = `This field is required`;
          }
        });
      }
    
      if (customValidation) {
        customValidation(obj);
      }
    };
    
    

    const validateAddress = (
      address: Record<string, any>,
      prefix: string,
      requiredFields: string[]
    ) => {
      requiredFields.forEach((field) => {
        if (!address[field]) {
          errors[`${prefix}.${field}`] = `This field is required`;
        }
      });
    };
    // // File validation
    // const validateFile = (file: File | null, fieldName: string) => {
    //   if (file) {
    //     if (!ALLOWED_FILE_FORMATS.includes(file.type)) {
    //       errors[fieldName] =
    //         "Invalid file format, please upload only .png, .jpeg, or .jpg files.";
    //     } else if (file.size > MAX_FILE_SIZE) {
    //       errors[fieldName] = "File size is greater than 2MB.";
    //     }
    //   }
    // };

    // // Call file validation for specific fields
    // validateFile(formData.profile_picture, "profile_picture");

    validateFields(fields.string, "string");
    validateFields(fields.number, "number");

    validateNestedObject(formData.bank_account, "bank_account", [
      "iban",
      "bic",
      "iban_country_code",
    ]);

    validateNestedObject(formData.dependencies, "dependencies", [
      "dependent_spouce",
      "dependent_children",
    ]);

    validateNestedObject(
      formData.security,
      "security",
      [], // password and confirm_password are conditionally required
      (security) => {
        const passwordError = validatePassword(formData.security.password);
        const confirm_passwordError = validatePassword(
          formData.security.confirm_password
        );

        if (
          passwordError &&
          confirm_passwordError &&
          passwordError !== "This field is required" &&
          confirm_passwordError !== "This field is required"
        ) {
          errors["security.password"] = passwordError;
          errors["security.confirm_password"] = confirm_passwordError;
        }

        if (security.password !== security.confirm_password) {
          errors["security.confirm_password"] = "Passwords do not match.";
        }
      },
      {
        condition: (formData) => formData.id !== 0,
        fields: ["password", "confirm_password"],
      }
    );

    validateNestedObject(formData.emergency_contact, "emergency_contact", [
      "name",
      "contact_number",
    ]);
    validateNestedObject(formData.user_terms, "user_terms", [
      "is_term_condition",
      "is_contract_sign",
      "is_temp_employement",
      "is_gdpr",
    ]);
    // Validate addresses
    validateAddress(formData.address.domicile, "address.domicile", [
      "street",
      "city",
      "postal_code",
      "country",
      "number",
    ]);
    validateAddress(formData.address.residence, "address.residence", [
      "street",
      "city",
      "postal_code",
      "country",
      "number",
    ]);

    // Validate default_emp_legal array
    if (formData.default_emp_legal.length === 0) {
      errors.default_emp_legal =
        "At least one Function & hourly wage detail is required";
    } else {
      // setFormErrors("default_emp_legal", null);
      formData.default_emp_legal.forEach((legal, index) => {
        if (!legal.paritaire_commitee_id) {
          errors[`default_emp_legal.${index}.paritaire_commitee_id`] =
            "This field is required";
        }
        if (!legal.employee_type_id) {
          errors[`default_emp_legal.${index}.employee_type_id`] =
            "This field is required";
        }
        if (!legal.function_id) {
          errors[`default_emp_legal.${index}.function_id`] =
            "This field is required";
        }
        if (!legal.minWage) {
          errors[`default_emp_legal.${index}.minWage`] =
            "This field is required";
        }
        const actualSalary = parseFloat(
          formData.default_emp_legal?.[index]?.actual_salary.replace(",", "")
        );
        const minWage = parseFloat(
          formData.default_emp_legal?.[index]?.minWage.replace(",", "")
        );

        if (!isNaN(actualSalary) && !isNaN(minWage) && actualSalary < minWage) {
          errors[`default_emp_legal.${index}.actual_salary`] =
            "Actual salary must be greater than minimum wage";
        }
      });
    }

    // Additional validation
    if (formData.is_national) {
      if (!formData.registry_number) {
        if (formData.nationality === 29)
          errors.registry_number = "This field is required";
        else errors.registry_number = null;
        errors["work_permit.number"] = null;
        errors["work_permit.end_date"] = null;
        errors["work_permit.work_permit_file_url"] = null;
      }
      if (formData.registry_number !== formData.national_registry_number) {
        errors.registry_number = "Invalid SSN. Please enter verified SSN.";
      }
    } else {
      // Non-EU Citizen: Validate Work Permit fields
      if (!formData.work_permit.number) {
        errors["work_permit.number"] = "This field is required";
      }
      if (!formData.work_permit.end_date) {
        errors["work_permit.end_date"] = "This field is required";
      }
      if (!formData.work_permit_file_url) {
        errors["work_permit_file_url"] = "This field is required";
      }
      if (formData.title === 0) {
        // Assuming 'title' is for Residence Title
        errors.title = "This field is required";
      }
    }
    const validateEmailFields = () => {
      errors.email = validateEmail(formData.email);
      errors.confirm_email = validateEmail(formData.confirm_email);
      // Remove keys with null or falsy values
      Object.keys(errors).forEach((key) => {
        if (errors[key] == null) {
          // Check for null or undefined values
          delete errors[key]; // Remove the key from the object
        }
      });

      if (
        formData.email.toLowerCase() !== formData.confirm_email.toLowerCase()
      ) {
        errors.confirm_email = "Emails do not match.";
      }
    };

    validateEmailFields();
    return errors;
  };

  const handleSubmit = async () => {
    console.log("formData", formData);
    if (formData.sameAddress) {
      formData.address.domicile = formData.address.residence;
    }
    let errors = formValidation();
    // Remove keys with null or falsy values
    Object.keys(errors).forEach((key) => {
      if (errors[key] == null) {
        // Check for null or undefined values
        delete errors[key]; // Remove the key from the object
      }
    });

    if (Object.keys(errors).length > 0) {
      // Update state with validation errors
      setFormData((prev) => ({
        ...prev,
        errors,
      }));

      return; // Exit if there are validation errors
    } else {
      // Create a copy of formData
      const updatedFormData = {
        ...formData,
        email: formData.email.toLowerCase(),
        confirm_email: formData.confirm_email.toLowerCase(),
        default_emp_legal: formData.default_emp_legal.map((item) => ({
          ...item,
          actual_salary: item.actual_salary ? item.actual_salary : item.minWage,
        })),
      };

      if (formData.id !== 0) {
        // Update employee API call
        try {
          const response = await ApiCall.service(
            `${UPDATE_EMPLOYEE}/${updateId}`,
            "PUT",
            updatedFormData
          );
          if (response.status === 200) {
            let notificationType: NotifyType = "success";
            CustomNotify({
              type: notificationType,
              message: t(response.message),
            });
            navigator(ROUTE.EMPLOYEE_OVERVIEW);
          } else if (response.status === 422) {
            // Handle validation errors
            const errorMessages = response.errors;
            let notificationType: NotifyType = "error";
            let errorMessage = Object.values(errorMessages).join(", "); // Combine all error messages
            CustomNotify({
              type: notificationType,
              message: t(errorMessage),
            });
            return;
          } else {
            let notificationType: NotifyType = "error";
            CustomNotify({
              type: notificationType,
              message: t(response.message),
            });
          }
        } catch (error) {
          console.error("Error updating employee:", error);
        }
      } else {
        // Create employee API call

        let response;
        try {
          if (user.token) {
            response = await ApiCall.service(
              EMPLOYEE_REGISTRATION,
              "POST",
              updatedFormData
            );
          } else {
            response = await ApiCall.service(
              EMPLOYEE_REGISTRATION_WITHOUT_LOGIN,
              "POST",
              updatedFormData
            );
          }

          if (response.status === 200) {
            let notificationType: NotifyType = "success";
            CustomNotify({
              type: notificationType,
              message: t(response.message),
            });
            navigator(ROUTE.EMPLOYEE_OVERVIEW);
          } else if (response.status === 422) {
            const errorMessages = Object.values(response.errors);

            // Loop through each error message
            errorMessages.forEach((message) => {
              let notificationType: NotifyType = "error"; // Set the notification type as 'error'

              let errorMessage: string;

              if (typeof message === "object" && message !== null) {
                errorMessage = Object.values(message).join(", ");
              } else if (typeof message === "string") {
                errorMessage = message;
              } else {
                errorMessage = ""; // Fallback if message is null, undefined, or unhandled type
              }

              // Call the notification function if errorMessage is not empty
              if (errorMessage) {
                CustomNotify({
                  type: notificationType,
                  message: t(errorMessage), // Pass the translated message
                });
              }
            });

            // let notificationType: NotifyType = "error";
            // let errorMessage = Object.values(errorMessages).join(", "); // Combine all error messages
            // CustomNotify({
            //   type: notificationType,
            //   message: t(errorMessage),
            // });
            return;
          } else {
            let notificationType: NotifyType = "error";
            CustomNotify({
              type: notificationType,
              message: t(response.message),
            });
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    }
  };

  return (
    <div className={classname}>
      <Header headerName={t(pageTitle)} headerClassName="myAccountCardTitle" />
      <div className="search-bar pb-2">
        <BackLink backLink="/" />
        <div className="row">
          <div className="mb-3">
            <LabelField
              title={t("Employee registration") + ":"}
              customStyle={{ fontSize: "1.2vw" }}
              className="pageTitle"
            />
          </div>
        </div>
      </div>
      <div className="managepagesBg mb-4">
        <OverFlowWrapper className="employeeRegistrationHeight container-fluid ps-0">
          <div className="row">
            <div className="col-12">
              <div className="mb-3">
                <LabelField title={t("Profile")} className="tab-title" />
              </div>
            </div>
          </div>
          <EmployeeProfile
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            handleToggle={handleToggle}
            optionsData={optionsData}
            countryOptions={countryOptions}
            handleSelectChange={handleSelectChange}
            handleVerifySSN={handleVerifySSN}
            handleFileUpload={handleFileUpload}
          />

          <div className="row pb-3 py-4">
            <div className="col-4">
              <DocumentsUploadEmployee
                documentTitle="Front ID:"
                name="front_pic"
                handleFileUpload={handleFileUpload}
                fileType={2}
                pathParam={"front"}
                isMandatory={true}
                formData={formData}
                pic_name={formData.front_pic_name}
                setFormData={setFormData}
                error={formData?.errors?.front_pic_url ?? undefined}
              />
            </div>
            <div className="col-4">
              <DocumentsUploadEmployee
                documentTitle="Back ID:"
                name="back_pic"
                handleFileUpload={handleFileUpload}
                fileType={3}
                pathParam={"back"}
                isMandatory={true}
                formData={formData}
                setFormData={setFormData}
                pic_name={formData.back_pic_name}
                error={formData?.errors?.back_pic_url ?? undefined}
              />
            </div>
          </div>
          <Address
            formData={formData}
            handleChange={handleChange}
            handleToggle={handleAddressToggle}
            countryOptions={countryOptions}
            optionsData={optionsData}
            handleSelectChange={handleSelectChange}
          />
          <EmergencyContact formData={formData} handleChange={handleChange} />
          <EmployeeOthers
            formData={formData}
            pcId={pcId}
            setFormData={setFormData}
            handleChange={handleChange}
            handleToggle={handleToggle}
            optionsData={optionsData}
            handleSelectChange={handleSelectChange}
          />

          <div className="pb-5 text-center">
            {formData.id !== 0 ? (
              <Button
                handleClick={handleSubmit}
                title={t("Update")}
                className="modalSaveBtn"
              />
            ) : (
              <Button
                handleClick={handleSubmit}
                title={t("Register")}
                className="modalSaveBtn"
              />
            )}
          </div>
        </OverFlowWrapper>
      </div>
    </div>
  );
};
export default Registration;
