import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

// Define the interface for the employee data
interface Employee {
    id: number;
    first_name: string;
    last_name: string;
    is_fav: boolean;
    profile_picture_url: string | null;
    employee_type: string | null;
    employee_type_id: number | null;
    employee_type_category: string | null;
    employee_type_category_id: number | null;
    paritaire_commitee: string | null;
    paritaire_commitee_id: number | null;
    functional_salary: number | null;
    function_id: number | null;
}

// Define the initial state interface
interface EmployeeState {
    employees: Employee[];
}

// Initial state with an empty employee array
const initialState: EmployeeState = {
    employees: [],
};

// Create a slice for employee data
const employeeSlice = createSlice({
    name: 'employees',
    initialState,
    reducers: {
        // Reducer to add employee data
        setEmployeeData: (state, action: PayloadAction<Employee[]>) => {
            state.employees = action.payload; // Set the employee data
        },
        // Reducer to clear employee data
        clearEmployeeData: (state) => {
            state.employees = []; // Reset the employee data to an empty array
        }
    }
});

// Export the actions
export const { setEmployeeData, clearEmployeeData } = employeeSlice.actions;

// Selector to get the employee data from the state
export const selectCompanyEmployees = (state: RootState) => state.employees.employees;

// Export the reducer
export default employeeSlice.reducer;
