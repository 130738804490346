import axios, { AxiosRequestConfig } from "axios";
import * as ENDPOINTS from "../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE, REACT_APP_CDM_SERVICE } from "../Constants";

async function fileUploadService(formData: any, path: string, microserviceURL: string = "") {
  const headersarray: any = headers();

  const microService: string = microserviceURL !== null && microserviceURL !== "" && microserviceURL !== undefined ? microserviceURL : `${REACT_APP_CDM_SERVICE}`;
  console.log(`${microService}/${ENDPOINTS.FILE_UPLOAD}/${path}`, "path");
  return await axios
    .post(
      `${microService}/${ENDPOINTS.FILE_UPLOAD}/${path}`,
      formData,
      headersarray
    )
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log("Error service : ", error);
    });
}



async function fileDeleteService(path: string, id: number, microserviceURL: string = "") {
  const headersarray: any = headers();
  const microService: string = microserviceURL !== null && microserviceURL !== "" && microserviceURL !== undefined ? microserviceURL : `${REACT_APP_CDM_SERVICE}`;

  return await axios
    .post(
      `${microService}/${ENDPOINTS.FILE_DELETE}`,
      { path, id },
      headersarray
    )
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log("Error service : ", error);
    });
}

/**
 * Send uploaded file data to any backend microservice
 *
 * @param baseUrl: any
 * @param endPoint: string
 * @param formData: any
 *
 */
async function sendFileService(baseUrl: any, endPoint: string, formData: any) {

  const headersarray: any = headers();

  return await axios
    .post(
      `${baseUrl}/${endPoint}`,
      formData,
      headersarray
    )
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log("Error service : ", error);
    });
}

/*
 *Getting headers for the Ajax
 *@param data =data to the API
 *@param httpmethod=METHOD
 *returns headers for the Ajax
 */
const headers = async (): Promise<AxiosRequestConfig> => {
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
  };
  return config;
};

export const AxiosCall = {
  fileUploadService,
  fileDeleteService,
  sendFileService,
};
