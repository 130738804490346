import React from "react";
import { BsPrinter } from "react-icons/bs";
import Button from "../atoms/Button";
import CommonServices from "../../../services/common/CommonServices";

interface PrintButtonProps {
    fileUrl: string;
}

const PrintFile: React.FC<PrintButtonProps> = ({ fileUrl }) => {
    const handlePrint = () => {
        CommonServices.printFile(fileUrl);
    }
    return (
        <Button
            handleClick={handlePrint}
            className="icon-btn btn btn-link text-white mx-1 p-1 fs-6"
            title={<BsPrinter />}
        />
    );
};

export default PrintFile;