import React, { MouseEventHandler } from "react";
import Image from "../../common/atoms/Image";
import defaultCompanyIcon from "../../../static/images/emptyCompany.png";
import Icon from "../../../Icon";
import { t } from "../translation/Translation";
import LabelField from "../../common/atoms/LabelField";

interface locationCardDetails {
  isComapny?: boolean;
  companyName?: string;
  handleEditClick?: MouseEventHandler<HTMLButtonElement>;
  handleManageLocation?: MouseEventHandler<HTMLButtonElement>;
  handleManageProject?: MouseEventHandler<HTMLButtonElement>;
  handleManageAdministrator?: MouseEventHandler<HTMLButtonElement>;
  isProject?: boolean;
  projectName?: string;
  isLocationManager?: boolean;
  locationManager?: string;
}

const LocationCard: React.FC<locationCardDetails> = ({
  companyName,
  handleEditClick,
  handleManageLocation,
  handleManageProject,
  handleManageAdministrator,
  isComapny,
  isProject,
  projectName,
  isLocationManager,
  locationManager,
}) => {
  return (
    <div className="employeeDetailsCardPlanning">
      <div className="row">
        <div className="col-9 align-self-center">
          <Image src={defaultCompanyIcon} imageWidth="3vw" imageHeight="3vw" />
          <LabelField
            title={[
              isComapny ? companyName : "",
              isProject ? projectName : "",
              isLocationManager ? locationManager : "",
            ]
              .filter(Boolean)
              .join(" ")}
          />
        </div>
        <div className="col-3 align-self-center">
          <div
            className="d-grid"
            style={{ gridTemplateColumns: "repeat(3, 1fr)"}}
          >
            <div
              className="actionWrapper mb-1 text-center"
              title={t("Edit")}
              onClick={() => handleEditClick}
            >
              <Icon isIcon={true} width="1vw" height="1vw" name="EditIcon" />
            </div>
            <div
              className="actionWrapper mb-1 text-center"
              title={t("Manage location")}
              onClick={() => handleManageLocation}
            >
              <Icon
                isIcon={true}
                width="1vw"
                height="1.2vw"
                name="Managelocation"
              />
            </div>
            {isProject && (
              <div
                className="actionWrapper text-center"
                title={t("Manage project")}
                onClick={() => handleManageProject}
              >
                <Icon
                  isIcon={true}
                  width="1.3vw"
                  height="1.5vw"
                  name="ManageProject"
                />
              </div>
            )}
            {isLocationManager && (
              <div
                className="actionWrapper text-center ms-1"
                title={t("Manage location manager")}
                onClick={() => handleManageAdministrator}
              >
                <Icon
                  isIcon={true}
                  width="1.3vw"
                  height="1.5vw"
                  name="ManageAdmin"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationCard;
