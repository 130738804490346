import React, { useEffect } from "react";
import ReactCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CalendarIcon from "../atoms/CalendarIcon";
import { useSelector, useDispatch } from "react-redux";
import { formatDate, formatDateTime } from '../utlis/dateHelper'; // Adjust the import path as needed
import {
  selectContractType,
  selectDates,
  setWeekNumber,
  setDates,
  addDate,
  updateEmployeeSchedule,
  selectEmployees,
  selectLocationId,
  clearAllSchedules
} from "../../../features/planning/planningSlice";
import {
  startOfWeek,
  endOfWeek,
  getWeek,
  isWithinInterval,
  format // Import format from date-fns
} from "date-fns";
import { generateSchedule } from "../../Planning/Validations/PlanningHooks";

interface PlanningCalendarProps {
  isReactCalendar?: boolean;
  iscalendarSeperator?: boolean;
  customClassName?: string;

}

const PlanningCalendar: React.FC<PlanningCalendarProps> = ({
  isReactCalendar,
  iscalendarSeperator,
  customClassName,
}) => {
  const contractType = useSelector(selectContractType);
  const dates = useSelector(selectDates);
  const locationId = useSelector(selectLocationId);
  const selectedEmployees = useSelector(selectEmployees);
  // const schedule = useSchedule(dates, locationId);
  const dispatch = useDispatch();




  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    if (!Array.isArray(dates) || dates.length === 0) {
      return "";
    }

    const startDate = new Date(dates[0]);
    let dateDate = new Date(dates[1] || startDate); // If end date doesn't exist, use start date for single-day selection

    if (contractType === "week") {
      // Calculate the start and end of the week based on the selected start date
      const weekStart = startOfWeek(startDate, { weekStartsOn: 1 });
      const weekEnd = endOfWeek(startDate, { weekStartsOn: 1 });

      // Check if the current tile's date falls within the calculated week range
      if (isWithinInterval(date, { start: weekStart, end: weekEnd })) {
        return "date-selected"; // Add the class for week highlighting
      }
    }
    if (contractType === "day") {
      if (formatDateTime(date) === formatDateTime(startDate)) {
        return "date-selected"; // Add the class for week highlighting
      }
    }

    // For other cases, do not highlight
    return "";
  };


  // Set the value based on contract type
  const calendarValue = Array.isArray(dates) ? dates[0] : dates;

  function onChange(value: any, event: React.MouseEvent<HTMLButtonElement>) {
    if (!value) {
      return;
    }
    console.log(value);
    if (Array.isArray(value)) {
      if (value.length === 2 && value[0] instanceof Date && value[1] instanceof Date) {
        // Handle date range selection
        const startDate = formatDate(value[0].toISOString()); // Format start date
        const endDate = formatDate(value[1].toISOString()); // Format end date

        dispatch(setDates([startDate, endDate]));
        dispatch(setWeekNumber({ weekNumber: getWeek(value[0], { weekStartsOn: 1 }) }));
      } else {
        // Handle multiple single date selections (not a range)
        dispatch(setDates(value.map((date) => date instanceof Date ? formatDate(date.toISOString()) : "")));
      }
    } else if (value instanceof Date) {
      // Handle single date selection
      const formattedDate = formatDate(value.toISOString()); // Subtract one day for the start date
      dispatch(addDate(formattedDate));
      if (contractType === "day") {
        dispatch(setWeekNumber({ weekNumber: getWeek(value, { weekStartsOn: 1 }) }));
      } else if (contractType === "week") {
        let start = startOfWeek(value, { weekStartsOn: 1 }).toISOString();
        let end = endOfWeek(value, { weekStartsOn: 1 }).toISOString();

        // Dispatch the start and end dates for the week
        dispatch(setDates([formatDate(start), formatDate(end)]));

        // Generate and dispatch the schedule
        let schedule = generateSchedule([formatDate(start), formatDate(end)], locationId);
        dispatch(setWeekNumber({ weekNumber: getWeek(value, { weekStartsOn: 1 }), schedule: schedule }));

      }

    }
  }


  function onClickWeekNumber(weekNumber: number, date: Date, event: React.MouseEvent<HTMLButtonElement>) {
    if (!(date instanceof Date)) {
      return;
    }

    // Calculate the start and end dates of the week
    const start = startOfWeek(date, { weekStartsOn: 1 }).toISOString();
    const end = endOfWeek(date, { weekStartsOn: 1 }).toISOString();

    if (contractType === "week") {
      // Dispatch the start and end dates for the week
      dispatch(setDates([formatDate(start), formatDate(end)]));
      let schedule = generateSchedule([formatDate(start), formatDate(end)], locationId);
      dispatch(setWeekNumber({ weekNumber: weekNumber, schedule: schedule }));

    }

    if (contractType === "day") {
      // Dispatch only the start date if contract type is "day"
      dispatch(setDates([formatDate(start)]));
      dispatch(setWeekNumber({ weekNumber: getWeek(new Date(start), { weekStartsOn: 1 }) }));
    }
  }



  return (
    <div className={`calendarWrapper ${customClassName}`}>
      <div>
        {
          iscalendarSeperator
          &&
          <CalendarIcon isCalendarIcon={true} />
        }

        {isReactCalendar && (
          <ReactCalendar
            onChange={(value, event) => onChange(value, event)}
            value={calendarValue}
            className={`mx-auto`}
            minDate={new Date()}
            showWeekNumbers={true}
            onClickWeekNumber={(weekNumber, date, event) =>
              contractType === "week"
                ? onClickWeekNumber(weekNumber, date, event)
                : undefined
            } tileClassName={tileClassName}
          />
        )}
      </div>
    </div>
  );
};

export default PlanningCalendar;
