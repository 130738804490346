import { Option } from "../../../services/ValidationService";

const genders: Option[] = [
  { value: 1, label: "Male" },
  { value: 2, label: "Female" },
];

export function getGenderById(id: number): Option | undefined {
  return genders.find((gender) => gender.value === id);
}

const withHoldingTax: Option[] = [{ value: 3, label: "18.725%" }];

export function getWithHoldingTaxForSeasonalWorker(){
    return { value: 3, label: "18.725%" }
}

export function formatDate(date: Date | null): string {
  // Ensure date is a valid Date object
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return '';
  }

  // Extract day, month, and year
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = String(date.getFullYear()); // Full 4-digit year

  // Format as dd/mm/yyyy
  return `${day}/${month}/${year}`;
}

export function processMobileNumber(mobile:any) {
  // Check if the mobile number contains any alphabetic characters
  if (/[a-zA-Z]/.test(mobile)) {
      return null; // If it contains alphabetic characters, return null
  }

  // Remove all characters except digits and +
  mobile = mobile.replace(/[^0-9+]/g, '');

  // If the mobile number starts with +, return it as is
  if (mobile.startsWith('+')) {
      return mobile;
  }

  // If it does not start with +, prepend +32
  return '+32' + mobile;
}


