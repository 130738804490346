import React, { useContext, useEffect } from "react";
import LinkCoeffEmpContext from "./LinkCoeffEmp/LinkCoeffEmpContext";
import CoeffcientValuesFirstPart from "./CoeffcientValuesFirstPart";
import EmployeeTypeSecondPart from "./EmployeeTypeSecondPart";
import { helpers } from "./LinkCoeffEmpHelper";
import MultiSelect from "./SelectComponent";
import {
  t,
  translate,
} from "../../../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../../../services/ApiServices";
import {
  FETCH_ALL_EMP_COEFF_VALUES,
  SAVE_PC_LINKING_DATA,
} from "../../../../routes/ApiEndpoints";
import Header from "../../../common/layout/Header";
import Button from "../../../common/atoms/Button";
import LabelField from "../../../common/atoms/LabelField";
import BackLink from "../../../common/atoms/BackLink";
import "./linkCoefficient.css";

var SERVER_SIDE_RENDERING = 1;

const LinkCoeffEmpComponent = (props) => {
  const { state, updateStateChanges } = useContext(LinkCoeffEmpContext);
  const { inputRef, isOverflow } = state;
  useEffect(() => {
    SERVER_SIDE_RENDERING
      ? fetchEmpCoeffValueTypesData()
      : (SERVER_SIDE_RENDERING += 1);
  }, []);

  const fetchEmpCoeffValueTypesData = async (
    url = `${FETCH_ALL_EMP_COEFF_VALUES}`,
    type = 0,
    pcid = props.pcid
  ) => {
    try {
      const response = await ApiCall.service(url, "POST", { pcid: pcid });

      if (response.success) {
        if (type === 1) {
          return response;
        } else {
          assignDataToStateVariables(response.data); // Otherwise, assign data to state
        }
      } else {
        console.error("API call failed:", response.message);
        return null;
      }
    } catch (error) {
      console.error("Error in fetchEmpCoeffValueTypesData:", error);
      return null;
    }
  };

  const assignDataToStateVariables = (data) => {
    const {
      employeeTypes,
      coefficientTypes,
      valueTypes,
      pcLinkedValueData,
      pcArray,
    } = data;
    updateStateChanges({
      employeeTypeArray: employeeTypes,
      coefficientTypeArray: coefficientTypes,
      valueTypeArray: valueTypes,
      pclinkingValueobj: pcLinkedValueData || {},
      pcArray: [{ value: false, label: t("--- Select ---") }, ...pcArray],
      selectedPc: parseInt(props.pcid),
    });
  };

  const handleSubmit = async () => {
    if (proceedToSubmit()) {
      await ApiCall.service(SAVE_PC_LINKING_DATA, "POST", postdata()).then(
        (response) => {
          if (response.success) window.location.reload();
        }
      );
    }
  };

  const proceedToSubmit = () => {
    let proceed = true;
    if (!state.selectedPc) {
      proceed = false;
      updateStateChanges({ pcWarning: true });
      return;
    }
    const { status, regExpressionStatus } =
      helpers.checkCoefficientsFilledOrNot(
        state.coefficientTypeArray,
        state.employeeTypeArray,
        state.pclinkingValueobj
      );
    console.log(status, regExpressionStatus, "check to proceed");
    if (state.selectedPc && (!status || !regExpressionStatus)) {
      proceed = false;
      updateStateChanges({
        emptyDataWarning: !status,
        regExpWarning: !regExpressionStatus,
      });
    }
    if (state.lowHighValidation.length) proceed = false;
    if (state.valueErrorArray.length) proceed = false;
    if (state.defaultValueError.length) proceed = false;
    return proceed;
  };

  const postdata = () => {
    return {
      pclinkingValueobj: state.pclinkingValueobj,
      selectedPc: state.selectedPc,
    };
  };

  const onSelect = async (e) => {
    removeWarningClass();
    const url = `${FETCH_ALL_EMP_COEFF_VALUES}`;
    const response = await fetchEmpCoeffValueTypesData(url, 1, e.value);

    if (response) {
      const {
        employeeTypes,
        coefficientTypes,
        valueTypes,
        pcLinkedValueData,
        pcArray,
      } = response.data;
      updateStateChanges({
        employeeTypeArray: employeeTypes,
        selectedPc: e.value,
        pcWarning: false,
        emptyDataWarning: false,
        regExpWarning: false,
        pclinkingValueobj: pcLinkedValueData,
        lowHighValidation: [],
        valueErrorArray: [],
        defaultValueError: [],
      });
    } else {
      console.error("Failed to fetch data");
    }
  };

  const removeWarningClass = () => {
    state.lowHighValidation.map((val) => {
      let lowRef = inputRef.current[`${val}_1`];
      let highRef = inputRef.current[`${val}_3`];
      lowRef.classList.remove("warning");
      highRef.classList.remove("warning");
    });
  };

  const addMultiSelectTag = () => {
    return (
      <>
        <LabelField title={t("Select paritair comite")} />
        <MultiSelect
          options={state.pcArray}
          standards={state.pcArray.filter(
            (val) => val.value === state.selectedPc
          )}
          disabled={parseInt(props.pcid) && state.selectedPc ? true : false}
          handleChange={onSelect}
          isMulti={false}
          placeholder={t("Select paritair comite")}
        />
      </>
    );
  };

  if (SERVER_SIDE_RENDERING)
    return (
      <>
        <Header
          headerName={t("Link coefficients to employee types")}
          headerClassName="myAccountCardTitle"
        />
        <div className="search-bar pb-4">
          <div className="row mb-2">
            <BackLink backLink="/config/settings" />
          </div>
          <div className="row">
            <div className="col-4">
              {addMultiSelectTag()}
              {state.pcWarning && (
                <div className="text-danger">{t("Choose paritair comite")}</div>
              )}
            </div>
            <div className="col-8 align-self-center pt-1">
              {state.lowHighValidation !== undefined &&
                state.lowHighValidation.length > 0 && (
                  <div className="text-danger">
                    {t(
                      "Please change the highlighted low and high values, low value should be less than high value Low < High."
                    )}
                  </div>
                )}
              {state.emptyDataWarning && (
                <div className="text-danger">
                  {t(`Please fill all coefficient fields.`)}
                </div>
              )}
              {state.valueErrorArray.length > 0 && (
                <div className="text-danger">
                  {t(`Value should be in between 0 to 10.`)}
                </div>
              )}
              {state.defaultValueError.length > 0 && (
                <div className="text-danger">
                  {t(`Default value should be in between low and high values.`)}
                </div>
              )}
              {state.regExpWarning === true && (
                <div
                  className="col-md-10 my-2 text-danger text-left error_text"
                  id="reg-expression-div"
                >
                  {t(`Please enter proper values.`)}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="bg-white"
          style={{
            paddingLeft: "2.8vw",
            paddingRight: "2.8vw",
            paddingBottom: "2vw",
            borderBottomLeftRadius: "2vw",
            borderBottomRightRadius:"2vw",
            position:"relative",
            top:"-0.5vw"
          }}
        >
          <div className="row defaultCoefficientHeight scrollBar">
            <div className="col-lg-4 px-0 firstPart col-md-7 col-fixed">
              <CoeffcientValuesFirstPart />
            </div>
            <div className="col-lg-8 ps-0 col-scrollable col-md-5">
            <EmployeeTypeSecondPart />
            </div>
          </div>
        </div>

        <div className="row pt-4">
          <div className="col-md-12">
            <div className="row">
              <div className="col-6 ms-auto">
                <Button
                  handleClick={() => handleSubmit()}
                  title={t("Save")}
                  type="button"
                  className="searchBtn float-end"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  else return <></>;
};

export default translate(LinkCoeffEmpComponent);
