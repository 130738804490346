import React, { useEffect, useState } from "react";
import { t } from "../common/atoms/translation";
import LabelField from "../common/atoms/LabelField";
import { LabelWithInputField } from "../common/molecules/LabelWithInputField";
import ToggleButton from "../common/atoms/ToggleButton";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import CheckBoxField from "../common/atoms/CheckBoxField";
import { icons } from "../Dashboard/WelcomeDashboard";
import Image from "../common/atoms/Image";
import { EmployeeFormData, OptionsData, HandleChange } from "./Registration";
import defaultCompanyIcon from "../../static/images/emptyCompany.png";
import Button from "../common/atoms/Button";
import { ApiCall } from "../../services/ApiServices";
import {
  GET_EMPLOYEE_TYPES_BY_PC,
  GET_FUNCTIONS_BY_PC,
  GET_USER_COMPANIES,
  PC_LIST,
} from "../../routes/ApiEndpoints";
import CustomNotify, { NotifyType } from "../common/atoms/CustomNotify";
import { useSelector } from "react-redux";

type OptionType = {
  value: string | number;
  label: string;
};

interface InputField {
  employee_type_id: number | null;
  pc: number | null;
  function: number | null;
  minWage: string;
  actual_salary: string;
  company_id: number | null;
}
interface FunctionOptionType {
  label: string;
  value: number;
  function_id?: number;
  function_name?: string;
  salary?: string;
}

interface EmployeeOthersProps {
  formData: EmployeeFormData;
  pcId: number[] | null;
  setFormData: React.Dispatch<React.SetStateAction<EmployeeFormData>>;
  handleChange: HandleChange;
  handleToggle: (
    field: keyof EmployeeFormData,
    subfield: string
  ) => (value: boolean) => void;
  optionsData: OptionsData;
  handleSelectChange: (
    selectedOption: OptionType,
    fieldPath: string,
    index?: number
  ) => void;
}
const EmployeeOthers: React.FC<EmployeeOthersProps> = ({
  formData,
  pcId,
  setFormData,
  handleChange,
  handleToggle,
  optionsData,
  handleSelectChange,
}) => {
  const [checkedItems, setCheckedItems] = useState<Set<number>>(new Set());
  //for PC
  // For PC options
  const [pcOptions, setPCOptions] = useState<OptionType[]>([]);
  const [fuctionsOptions, setFuctionsOptions] = useState<
    FunctionOptionType[][]
  >([]);
  const [employeeTypeOptions, setEmployeeTypeOptions] = useState<
    OptionType[][]
  >([]);
  // For Fetching Companies Based on User Id ( Employer Id )
  const [companyData, setCompanyData] = useState<{
    companies: any[];
  }>({
    companies: [],
  });

  const userId = useSelector((state: any) => state.auth.userId);
  async function fetchCompanies() {
    try {
      const url = `${GET_USER_COMPANIES}/${userId}`;
      const response = await ApiCall.getService(url, "GET");
      if (response.status === 200) {
        if (companyData.companies.length === 0) {
          setCompanyData((prevState) => ({
            ...prevState,
            companies: response.data,
          }));
        }
      }
    } catch (error) {
      console.log("Error fetching companies:", error);
    }
  }

  useEffect(() => {
    fetchCompanies();
  }, []);

  // Fetch PC options
  useEffect(() => {
    const fetchPCOptions = async () => {
      try {
        const result = await ApiCall.getService(
          PC_LIST,
          "GET",
          "central-data-management",
          false
        );
        const options = result.data.map((item: any) => ({
          label: item.label,
          value: item.value,
        }));
        setPCOptions(options);
      } catch (error) {
        console.error("Error fetching PC options:", error);
      }
    };

    fetchPCOptions();
  }, []);

  const fetchOptions = async (index: number, selectedPcId: number) => {
    try {
      const functionsResult = await ApiCall.getService(
        `${GET_FUNCTIONS_BY_PC}/${selectedPcId}`,
        "GET",
        "central-data-management",
        false
      );

      setFuctionsOptions((prevOptions) => {
        const newOptions = [...prevOptions];
        newOptions[index] = functionsResult.data.functions;
        return newOptions;
      });

      const employeeTypesResult = await ApiCall.getService(
        `${GET_EMPLOYEE_TYPES_BY_PC}/${selectedPcId}`,
        "GET",
        "central-data-management",
        false
      );

      setEmployeeTypeOptions((prevOptions) => {
        const newOptions = [...prevOptions];
        newOptions[index] = employeeTypesResult.data.employee_types;
        return newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };


  // Function to fetch options for a specific index array of pc
  // const fetchOptions = async (index: number) => {
  //   const selectedPcItem = formData.default_emp_legal[index];

  //   if (selectedPcItem && selectedPcItem.paritaire_commitee_id) {
  //     const selectedPcId = selectedPcItem.paritaire_commitee_id;

  //     try {
  //       const functionsResult = await ApiCall.getService(
  //         `${GET_FUNCTIONS_BY_PC}/${selectedPcId}`,
  //         "GET",
  //         "central-data-management",
  //         false
  //       );

  //       setFuctionsOptions((prevOptions) => {
  //         const newOptions = [...prevOptions];
  //         newOptions[index] = functionsResult.data.functions;
  //         return newOptions;
  //       });

  //       const employeeTypesResult = await ApiCall.getService(
  //         `${GET_EMPLOYEE_TYPES_BY_PC}/${selectedPcId}`,
  //         "GET",
  //         "central-data-management",
  //         false
  //       );

  //       setEmployeeTypeOptions((prevOptions) => {
  //         const newOptions = [...prevOptions];
  //         newOptions[index] = employeeTypesResult.data.employee_types;
  //         return newOptions;
  //       });
  //     } catch (error) {
  //       console.error("Error fetching options:", error);
  //     }
  //   } else {
  //     console.warn(`No paritaire_commitee_id found for index ${index}`);
  //   }
  // };

  // // Track which paritaire_commitee_ids have changed
  // useEffect(() => {
  //   formData.default_emp_legal.forEach((item, index) => {
  //     // Only trigger when the specific paritaire_commitee_id changes
  //     if (item.paritaire_commitee_id) {
  //       fetchOptions(index);
  //     }
  //   });
  // }, [formData.default_emp_legal.map(item => item.paritaire_commitee_id).join(',')]);

  // Fetch functions and employee types when input fields change
  // useEffect(() => {
  //   const fetchOptions = async (index: number) => {
  //     const selectedPcItem = formData.default_emp_legal[index];

  //     // Check if the item and paritaire_commitee_id exist
  //     if (selectedPcItem && selectedPcItem.paritaire_commitee_id) {
  //       const selectedPcId = selectedPcItem.paritaire_commitee_id;
  //       try {
  //         const functionsResult = await ApiCall.getService(
  //           `${GET_FUNCTIONS_BY_PC}/${selectedPcId}`,
  //           "GET",
  //           "central-data-management",
  //           false
  //         );

  //         // Ensure that the data exists and is an array
  //         setFuctionsOptions((prevOptions) => {
  //           const newOptions = [...prevOptions];
  //           newOptions[index] = functionsResult.data.functions; // Assuming functionsResult.data.functions is an array
  //           return newOptions;
  //         });

  //         const employeeTypesResult = await ApiCall.getService(
  //           `${GET_EMPLOYEE_TYPES_BY_PC}/${selectedPcId}`,
  //           "GET",
  //           "central-data-management",
  //           false
  //         );

  //         // Ensure that the data exists and is an array
  //         setEmployeeTypeOptions((prevOptions) => {
  //           const newOptions = [...prevOptions];
  //           newOptions[index] = employeeTypesResult.data.employee_types; // Assuming employeeTypesResult.data.employee_types is an array
  //           return newOptions;
  //         });
  //       } catch (error) {
  //         console.error("Error fetching options:", error);
  //       }
  //     } else {
  //       console.warn(`No paritaire_commitee_id found for index ${index}`);
  //     }
  //   };
  //   // Check if formData.default_emp_legal is an array and has items

  // if (formData.id !== 0) {
  //   if (
  //     Array.isArray(formData.default_emp_legal) &&
  //     formData.default_emp_legal.length > 0
  //   ) {
  //     formData.default_emp_legal.forEach((item, index) => {
  //       // Fetch options for each valid item
  //       fetchOptions(index);
  //     });

  //     // Update input fields
  //     formData.default_emp_legal.forEach((item, index) => {
  //       setInputFields((prev) => {
  //         // Ensure prev exists for the current index
  //         if (!prev[index]) return prev;

  //         // Return new input fields array with updated values
  //         return prev.map((field, i) =>
  //           i === index
  //             ? {
  //                 ...field,
  //                 pc: item.paritaire_commitee_id,
  //                 function: item.function_id,
  //                 employee_type_id: item.employee_type_id,
  //                 minWage: item.minWage,
  //                 actual_salary: item.actual_salary,
  // company_id: item.company_id,
  //               }
  //             : field
  //         );
  //       });
  //       console.log("Updated input fields:", formData.default_emp_legal);
  //       formData.default_emp_legal.forEach((item, index) => {
  //         handleAddField();
  //       });
  //     });
  //   }
  // }

  // }, [formData.default_emp_legal.length]);

  const handleFunctionChange = (
    index: number,
    fieldPath: string,
    selectedOption: FunctionOptionType | null
  ) => {
    setFormData((prevFormData) => {
      const updatedEmpLegal = prevFormData.default_emp_legal.map(
        (item: any, i: number) => {
          if (i === index) {
            return {
              ...item,
              [fieldPath]: selectedOption ? selectedOption.value : null,
              minWage: selectedOption ? selectedOption.salary || "" : "",
            };
          }
          return item;
        }
      );

      return {
        ...prevFormData,
        default_emp_legal: updatedEmpLegal,
      };
    });
  };

  const handleCheckBoxClick = (index: number) => {
    setCheckedItems((prev) => {
      const updated = new Set(prev);
      if (updated.has(index)) {
        updated.delete(index);
      } else {
        updated.add(index);
      }
      return updated;
    });
  };

  const handleCompanyChange = (companyId: number, isChecked: boolean) => {
    setFormData((prevData) => {
      const updatedCompanies = isChecked
        ? [...prevData.companies, companyId]
        : prevData.companies.filter((id) => id !== companyId);
      return {
        ...prevData,
        companies: updatedCompanies,
      };
    });
  };

  const [inputFields, setInputFields] = useState<InputField[]>([
    {
      employee_type_id: 0,
      pc: 0,
      function: 0,
      minWage: "",
      actual_salary: "",
      company_id: null,
    },
  ]);
  // useEffect(() => {
  //   fetchOptions();
  // }, [formData.default_emp_legal]);

  const setFormErrors = (name: string, isValid: boolean) => {
    setFormData((prevData) => {
      const updatedErrors = { ...prevData.errors };
      // If the field is not valid, set the error
      if (!isValid) {
        updatedErrors[name] = "This field is required";
      } else {
        // If the field is valid, clear the error
        delete updatedErrors[name];
      }
      return {
        ...prevData,
        errors: updatedErrors,
      };
    });
  };

  const handleAddField = () => {
    // Add a new field to the inputFields array
    const isValid = validateFields();
    if (isValid) {
      setInputFields([
        ...inputFields,
        {
          employee_type_id: 0,
          pc: 0,
          function: 0,
          minWage: "",
          actual_salary: "",
          company_id: null,
        },
      ]);

      // Calculate the new index for default_emp_legal
      const newIndex = inputFields.length;

      // Add a new entry to the default_emp_legal array in formData
      setFormData((prevFormData) => ({
        ...prevFormData,
        default_emp_legal: [
          ...prevFormData.default_emp_legal.slice(0, newIndex),
          {
            paritaire_commitee_id: 0,
            employee_type_id: 0,
            function_id: 0,
            minWage: "",
            actual_salary: "",
            is_actual_added: 0,
            company_id: null,
          },
          ...prevFormData.default_emp_legal.slice(newIndex),
        ],
      }));
    }
  };
  const validateFields = () => {
    const lastIndex = inputFields.length - 1;
    const lastField = formData.default_emp_legal[lastIndex];
    const isValid =
      lastField?.paritaire_commitee_id &&
      lastField?.employee_type_id &&
      lastField?.function_id &&
      lastField?.minWage;

      if (!isValid) {
        setFormErrors(
          `default_emp_legal.${lastIndex}.paritaire_commitee_id`,
          !!lastField?.paritaire_commitee_id
        );
        setFormErrors(
          `default_emp_legal.${lastIndex}.employee_type_id`,
          !!lastField?.employee_type_id
        );
        setFormErrors(
          `default_emp_legal.${lastIndex}.function_id`,
          !!lastField?.function_id
        );
        setFormErrors(
          `default_emp_legal.${lastIndex}.minWage`,
          !!lastField?.minWage
        );
      }
      

    return isValid;
  };

  const handleRemoveField = (index: number) => {
    setInputFields((prevFields) => prevFields.filter((_, i) => i !== index));

    setFormData((prevFormData) => {
      const updatedEmpLegal = prevFormData.default_emp_legal.filter(
        (_: any, i: number) => i !== index
      );

      Object.keys(prevFormData.errors).forEach((key) => {
        if (key.startsWith(`default_emp_legal.`)) {
          const newIndex = key.split(".")[1];
          if (Number(newIndex) > index) {
            const newKey = `default_emp_legal.${Number(newIndex) - 1}`;
            prevFormData.errors[newKey] = prevFormData.errors[key];
            delete prevFormData.errors[key];
          } else if (Number(newIndex) === index) {
            delete prevFormData.errors[key];
          }
        }
      });

      return {
        ...prevFormData,
        default_emp_legal: updatedEmpLegal,
      };
    });

    // Remove function options and employee types for the removed index
    setFuctionsOptions((prevOptions) =>
      prevOptions.filter((_, i) => i !== index)
    );
    setEmployeeTypeOptions((prevOptions) =>
      prevOptions.filter((_, i) => i !== index)
    );
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-12 mb-3">
          <LabelField
            title={t("To which company would you like to add this employee")}
            className="tab-title"
          />
        </div>
        <div className="col-12 mb-3">
          {companyData?.companies?.map((company, index) => (
            <div
              key={company?.id}
              className={`checkBoxContainer ${
                checkedItems.has(index) ? "border-class" : ""
              } d-inline-block`}
              onClick={() => handleCheckBoxClick(company?.id)}
            >
              <CheckBoxField
                name="addemployee"
                value="0"
                id={`company-${company?.id}`}
                lineHeight="25px"
                className="checkBoxLabel"
                checkBoxWrapper="addEmployeeToCompany me-4 pe-3"
                wrapperClass="d-inline-flex"
                isChildren
                onChangeHandler={(event) =>
                  handleCompanyChange(company?.id, event.target.checked)
                }
              >
                <div data-bs-toggle="tooltip" title={company?.name}>
                  <Image
                    src={company?.profile_picture_url ?? defaultCompanyIcon}
                    imageWidth="3vw"
                    imageHeight="3vw"
                    imageBorderRadius="50%"
                  />
                </div>
              </CheckBoxField>
            </div>
          ))}
        </div>

        <div className="col-12">
          <LabelField
            title={t("Function & hourly wage")}
            className="tab-title"
          />
        </div>
        {formData?.errors?.default_emp_legal && (
          <span className="text-danger">
            {formData?.errors?.default_emp_legal}
          </span>
        )}
      </div>
      <div className="row">
        {inputFields.map((field, index) => (
          <div key={index} className="row">
            <div className="col-4 mb-3">
              <div className="row multiSelectWrapper">
                <SelectWithSearch
                  fieldLabel="Paritair committee:"
                  title="Paritair committee"
                  name="paritaire_commitee_id"
                  placeHolder="Select"
                  isMandatory
                  search
                  options={pcOptions || []} // Pass the options here
                  value={
                    pcOptions.find(
                      (option) =>
                        option.value ===
                        formData.default_emp_legal[index]?.paritaire_commitee_id
                    ) || null
                  }
                  onChange={(selectedOption: OptionType) => {
                    fetchOptions(index, Number(selectedOption.value));
                    handleSelectChange(
                      selectedOption,
                      `default_emp_legal.${index}.paritaire_commitee_id`,
                      index
                    );
                  }}
                  error={
                    formData.errors[
                      `default_emp_legal.${index}.paritaire_commitee_id`
                    ] || ""
                  }
                  isTranslate
                  labelWrapperClassName="col-4"
                  colClassName="col-8"
                />
              </div>
            </div>
            <div className="col-4 mb-3">
              <div className="row multiSelectWrapper">
                <SelectWithSearch
                  fieldLabel="Function:"
                  title="Function"
                  name="function_id"
                  placeHolder="Select"
                  isMandatory
                  search
                  options={(fuctionsOptions[index] || []).sort(
                    (a: FunctionOptionType, b: FunctionOptionType) =>
                      a.label.localeCompare(b.label)
                  )}
                  // options={fuctionsOptions[index] || []}
                  value={
                    (fuctionsOptions[index] || []).find(
                      (option: FunctionOptionType) =>
                        option.value ===
                        formData.default_emp_legal[index]?.function_id
                    ) || null
                  }
                  onChange={(selectedOption: FunctionOptionType | null) => {
                    handleFunctionChange(index, "function_id", selectedOption);
                  }}
                  isTranslate
                  error={
                    formData.errors[`default_emp_legal.${index}.function_id`] ||
                    ""
                  }
                  labelWrapperClassName="col-4"
                  colClassName="col-8"
                />
              </div>
            </div>
            <div className="col-4 mb-3">
              <div className="row multiSelectWrapper">
                <SelectWithSearch
                  fieldLabel="Employee type:"
                  title="Employee type"
                  name="employee_type_id"
                  placeHolder="Select"
                  isMandatory
                  search
                  options={(employeeTypeOptions[index] || []).sort(
                    (a: OptionType, b: OptionType) =>
                      a.label.localeCompare(b.label)
                  )}
                  value={
                    (employeeTypeOptions[index] || []).find(
                      (option: OptionType) =>
                        option.value ===
                        formData.default_emp_legal[index]?.employee_type_id
                    ) || null
                  }
                  onChange={(selectedOption: OptionType) => {
                    handleSelectChange(
                      selectedOption,
                      `default_emp_legal.${index}.employee_type_id` // Correct dynamic field path
                    );
                  }}
                  isMulti={false}
                  className="select-field"
                  error={
                    formData.errors[
                      `default_emp_legal.${index}.employee_type_id`
                    ] || ""
                  }
                  isTranslate
                  labelWrapperClassName="col-4 align-self-center"
                  colClassName="col-8"
                />
              </div>
            </div>
            <div className="col-4 mb-3">
              <div className="row">
                <LabelWithInputField
                  label="Minimum wage:"
                  className="inputFieldColor"
                  colClassName="col-8"
                  type="text"
                  value={formData.default_emp_legal[index]?.minWage || ""} // Access the value from the array
                  name="minWage"
                  error={
                    formData.errors[`default_emp_legal.${index}.function_id`] ||
                    ""
                  }
                  id="minWage"
                  isDisabled={true}
                  labelWrapperClassName="col-4 align-self-center"
                >
                  <span className="colorPrimary helpText">
                    *If flexi excl holiday pay
                  </span>
                </LabelWithInputField>
              </div>
            </div>
            <div className="col-4 mb-3">
              <div className="row">
                <LabelWithInputField
                  label="Actual wage:"
                  className="inputFieldColor"
                  colClassName="col-8"
                  type="text"
                  value={formData.default_emp_legal[index]?.actual_salary || ""} // Access the value from the array
                  handleChange={handleChange(
                    "default_emp_legal",
                    "actual_salary",
                    undefined,
                    index
                  )}
                  name="actual_salary"
                  error={
                    formData.errors[
                      `default_emp_legal.${index}.actual_salary`
                    ] || ""
                  }
                  id="actual_salary"
                  labelWrapperClassName="col-4 align-self-center"
                >
                  <span className="colorPrimary helpText">
                    *If flexi excl holiday pay
                  </span>
                </LabelWithInputField>
              </div>
            </div>
            {inputFields.length !== 1 ? (
              <div className="col-4 mb-3 py-3 text-center">
                <Button
                  title="X"
                  className="modalSaveBtn"
                  handleClick={() => handleRemoveField(index)}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
        <div className="col-12 mb-3 py-3 text-center">
          <Button
            title="+ Add another PC"
            className="modalSaveBtn"
            disabled={inputFields.length >= 5}
            handleClick={handleAddField}
          />
        </div>

        <div className="col-12 d-flex align-items-center mb-3">
          <LabelField
            title={t("I agree to the GDPR data")}
            className="mb-0 me-3 colorPrimary poppins-medium"
            isMandatory
          />
          <ToggleButton
            value={formData.user_terms.is_gdpr}
            onToggle={handleToggle("user_terms", "is_gdpr")}
          />
          {formData.errors["user_terms.is_gdpr"] && (
            <div className="text-danger">
              {formData.errors["user_terms.is_gdpr"]}
            </div>
          )}
        </div>
        <div className="col-12 d-flex align-items-center mb-3">
          <LabelField
            title={t(
              "The temporary employment agency and the candidate temporary worker confirm the intention to enter into one or more employment contract(s) for temporary work.Letter of intent"
            )}
            className="mb-0 me-3 colorPrimary poppins-medium"
            isMandatory
          />
          <ToggleButton
            value={formData.user_terms.is_temp_employement}
            onToggle={handleToggle("user_terms", "is_temp_employement")}
          />
          {formData.errors["user_terms.is_temp_employement"] && (
            <div className="text-danger">
              {formData.errors["user_terms.is_temp_employement"]}
            </div>
          )}
        </div>
        <div className="col-12 d-flex align-items-center mb-3">
          <LabelField
            title={t("I agree to digitally sign contracts")}
            className="mb-0 me-3 colorPrimary poppins-medium"
            isMandatory
          />
          <ToggleButton
            value={formData.user_terms.is_contract_sign}
            onToggle={handleToggle("user_terms", "is_contract_sign")}
          />
          {formData.errors["user_terms.is_contract_sign"] && (
            <div className="text-danger">
              {formData.errors["user_terms.is_contract_sign"]}
            </div>
          )}
        </div>
        <div className="col-12 d-flex align-items-center mb-3">
          <LabelField
            title={t("I agree to the Terms and Conditions")}
            className="mb-0 me-3 colorPrimary poppins-medium"
            isMandatory
          />
          <ToggleButton
            value={formData.user_terms.is_term_condition}
            onToggle={handleToggle("user_terms", "is_term_condition")}
          />
          {formData.errors["user_terms.is_term_condition"] && (
            <div className="text-danger">
              {formData.errors["user_terms.is_term_condition"]}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default EmployeeOthers;
